<template>
    <b-row>
        <b-col>
            <div v-if="request_loading" class="alert alert-info">
            Loading...
            </div>
            <iq-card v-if="!request_loading">
                <template v-slot:headerTitle>
                <h5 class="card-title text-primary">
                    <strong>REQUEST REPAIR & MAINTENANCE</strong>
                </h5>
                </template>
                <template v-slot:headerAction>
                <b-button block variant="primary" v-b-modal.add-init @click="showInitModal = !showInitModal" v-if="!readOnly">
                    <i class="fa fa-plus"></i> &nbsp; Add Data
                </b-button>
                <!-- Modal -->
                <b-modal v-model="showInitModal" id="add-init" size="xl" title="ADD REPAIR & MAINTENANCE" header-bg-variant="light" header-text-variant="dark" footer-bg-variant="light" footer-text-variant="dark">
                    <b-row class="mb-4">
                    <b-col md="4" class="text-right">
                        <b-form-input type="text" placeholder="Search fleets..." v-model="fleet_params.search" @input="fetchFleets"></b-form-input>
                    </b-col>
                    </b-row>
                    <div v-if="loading_fleet" class="text-center my-5">
                    <b-spinner type="grow" label="Spinning"></b-spinner>
                    </div>
                    <template v-else>
                    <b-row v-if="fleets?.length > 0">
                        <b-col md="3" class="text-center py-5 border-top" v-for="(fleet, i) in fleets" :key="`fleet-${i}`">
                        <div class="custom-control custom-radio image-checkbox">
                            <input :id="`ck-fl-${i}`" type="radio" class="custom-control-input" :value="fleet.id" v-model="checkedFleets" />
                            <label class="custom-control-label" :for="`ck-fl-${i}`">
                            <img :src="
                                fleet.vehicleType.icon.url
                                | checkPhoto(
                                    require('@src/assets/images/fleet/PusherBarge.png')
                                )
                            " alt="#" class="img-fluid" />
                            </label>
                            <small>
                            <strong>{{ fleet.name }}</strong>
                            </small>
                        </div>
                        </b-col>
                    </b-row>
                    <div v-else class="text-center my-5">
                        <p class="mb-0 text-muted">No fleets data found.</p>
                    </div>
                    </template>
                    <template #modal-footer="{ cancel }">
                    <b-row>
                        <b-col md="12" class="text-center">
                        <b-button variant="none" class="iq-bg-danger text-right" @click="cancel(); checkedFleets = null">Cancel</b-button>
                        <b-button type="submit" variant="primary" @click="nextAfterSelectVehicle" class="ml-2 text-right" :disabled="nextLoading">
                            <span
                            v-if="nextLoading"
                            class="spinner-border spinner-border-sm"
                            />

                            <span v-else>Next <i class="fa fa-arrow-right"></i></span>
                        </b-button>
                        </b-col>
                    </b-row>
                    </template>
                </b-modal>
                </template>
                <template v-slot:body>
                <table class="table table-striped table-sm">
                    <thead>
                    <tr>
                        <th class="text-center">NO</th>
                        <th class="text-left">FLEET&nbsp;NAME</th>
                        <th class="text-center">LOCATION</th>
                        <th class="text-center">TANGGAL</th>
                        <th class="text-center">TOTAL REPAIR</th>
                        <th class="text-center">CLASSIFICATION</th>
                        <th class="text-center">OFFICE/PIC</th>
                        <th class="text-center">STATUS</th>
                        <th class="text-center">ACTION</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(state, index) in list_table_requested?.data" :key="'request-' + index">
                        <td class="text-center">{{ index + 1 }}</td>
                        <td class="text-left">{{ state?.fleet }}</td>
                        <td class="text-center">{{ state?.location }}</td>
                        <td class="text-center">{{ moment(state?.accident_at).format('DD MMMM YYYY') }}</td>
                        <td class="text-center">{{ state?.total_repair }}</td>
                        <td class="text-center">
                        <span v-for="(classification, classIndex) in state.classification" :key="classIndex">
                            <b-badge :variant="`${classification.name === 'Minor' ? 'success' : classification.name === 'Medium' ? 'info' : 'danger'} d-block`" 
                            class="mb-1">
                                {{ classification?.name }} : {{ classification?.total }} Repair
                            </b-badge>
                        </span>
                        </td>
                        <td class="text-center">{{ state?.site }}<br>
                        <small>PIC : <strong>{{ state?.pic }}</strong>
                        </small>
                        </td>
                        <td class="text-center">
                        <b-badge :variant="`${state?.status === 'new' ? 'info' : state.status === 'review' ? 'primary' : 'danger' } d-block`">{{ state?.status }}</b-badge>
                        </td>
                        <td class="text-center">
                        <b-dropdown size="lg" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                            <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                            </span>
                            </template>
                            <b-dropdown-item href="#" v-b-toggle.review_sr v-if="state.status === 'new' || state.status === 'progress'">
                            <div @click="() => getReviewData(state.id)" v-if="!readOnly">
                                <i class="fa fa-eye mr-2"></i>Review
                            </div>
                            <!-- <i class="fa fa-eye mr-2"></i>Review -->
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-toggle.approve_sr v-if="state.status === 'review'"> 
                                <div v-if="!readOnly" @click="() => getSingleApproveRepair(state.id)">
                                    <i class="fa fa-check mr-2"></i>Approve
                                </div>
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-toggle.update_sr v-if="state.status === 'new' || state.status === 'progress'"  @click="() => getSingleUpdatedServiceAndRepair(state.id)">
                                <div v-if="!readOnly" @click="() => getSingleApproveRepair(state.id)">
                                    <i class="fa fa-pencil-square mr-2"></i>Update Data
                                </div>
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-toggle.add-job v-if="!readOnly"  @click="() => {getSingleServiceAndRepair(state.id); is_job_data_equipment = true; checkedFleets = state.fleet_id; getComponentStruct()}">
                            <i class="fa fa-plus mr-2"></i>Add Job
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-toggle.detail_sr  @click="() => getSingleServiceAndRepair(state.id)">
                            <i class="fa fa-info-circle mr-2"></i>Detail
                            </b-dropdown-item>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item href="#" v-if="!readOnly && !state?.request_delete" variant="warning"  @click="() => onRequestDeleteServiceRepair(state)">
                            <i class="fa fa-remove mr-2"></i>Request Delete R & M
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-modal.reject-modal v-if="!readOnly && permissionDelete(state?.request_delete)"  variant="warning"  @click="() => selected_reject_id = state.id">
                            <i class="fa fa-remove mr-2"></i>Reject R & M
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-if="!readOnly && permissionDelete(state?.request_delete)" variant="danger" @click="() => deleteServiceRepair(state)">
                            <i class="fa fa-trash mr-2"></i>Delete R & M
                            </b-dropdown-item>
                        </b-dropdown>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="mt-3" v-if="list_table_requested?.data?.length > 0">
                    <b-pagination
                        v-model="list_table_requested.meta.page"
                        :total-rows="list_table_requested.meta.total_item"
                        :per-page="list_table_requested.meta.per_page"
                        aria-controls="my-table"
                        @change="getListTableServiceAndRepair"
                        align="center"
                        first-number
                    />
                </div>
                <!-- SLIDE CREATE R & M DATA -->
                <b-sidebar  v-model="showInitModalNext" id="add-sr" :title="'ADD REPAIR & MAINTENANCE -' + fleetNameChecked" width="100%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
                    <template #default="{hide}">
                    <form action="#" method="post" @submit="setServiceAndRepair">
                    <b-row class="m-3">
                        <!-- <b-col cols="12">
                            <fieldset class="form-group border p-3">
                                <legend class="w-auto px-2 text-primary">Engine Information</legend>
                                <table class="table mb-0 table-striped">
                                <tbody>
                                    <tr>
                                    <th>Main Engine 1 - Yanmar 12LAK</th>
                                    <td class="text-right">Actual RH : <strong>2.800 RH</strong>
                                    </td>
                                    </tr>
                                    <tr>
                                    <th>Main Engine 1 - Yanmar 12LAK</th>
                                    <td class="text-right">Actual RH : <strong>2.800 RH</strong>
                                    </td>
                                    </tr>
                                    <tr>
                                    <th>Auxuauxiliary Engine 1 - Cummin KW32</th>
                                    <td class="text-right">Actual RH : <strong>1.800 RH</strong>
                                    </td>
                                    </tr>
                                    <tr>
                                    <th>Auxuauxiliary Engine 1 - Cummin KW32</th>
                                    <td class="text-right">Actual RH : <strong>1.800 RH</strong>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                            </fieldset>
                        </b-col> -->
                        <b-col cols="12">
                        <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Service & Repair Form</legend>
                            <b-row class="mt-2">
                                <b-col md="4" v-if="form_repair_service.data.type_of_job === 'equipment_init'">
                                    <v-jstree :data="treeInitMaintenance" show-checkbox allow-batch whole-row @item-click="itemClickInitComponent">
                                        <template slot-scope="_">
                                            <div style="display: inherit; width: 200px">
                                            <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
                                            {{_.model.text}}
                                            </div>
                                        </template>
                                    </v-jstree>
                                </b-col>
                                <b-col :md="form_repair_service.data.type_of_job === 'custom' ? '12' : '8'">
                                    <b-row class="mt-3">
                                        <b-col md="12">
                                            <b-alert :show="true" variant="primary">
                                            <div class="custom-control custom-control-inline">
                                                <label>
                                                <b>SELECT JOB FROM :</b>
                                                </label>
                                            </div>
                                            <b-form-group>
                                                <b-form-radio-group
                                                    id="type_of_job"
                                                    v-model="form_repair_service.data.type_of_job"
                                                    name="type_of_job"
                                                    required
                                                >
                                                    <b-form-radio value="equipment_init">From Equipment</b-form-radio>
                                                    <b-form-radio value="custom">Custom</b-form-radio>
                                                </b-form-radio-group>
                                            </b-form-group>
                                            </b-alert>
                                        </b-col>
                                        <b-col md="12">
                                            <fieldset class="form-group border p-3">
                                            <legend class="w-auto px-2 text-primary">Detail Information</legend>
                                            <table class="table mb-0 table-striped">
                                                <tbody>
                                                <tr>
                                                    <th width="20%">Lokasi Pekerjaan</th>
                                                    <td width="5%">:</td>
                                                    <td width="25%">
                                                    <b-form-input required type="text" name="location" placeholder="Type Text ..."
                                                    v-model="form_repair_service.data.location"
                                                    ></b-form-input>
                                                    </td>
                                                    <th width="20%">Tanggal</th>
                                                    <td width="5%">:</td>
                                                    <td width="25%">
                                                    <b-form-input type="date" required name="accident_at"
                                                    v-model="form_repair_service.data.accident_at"
                                                    ></b-form-input>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Site & Group</th>
                                                    <td width="5%">:</td>
                                                    <td width="25%">
                                                    <b-form-input type="text" placeholder="Type Text ..." name="site"
                                                    v-model="form_repair_service.data.site"
                                                    ></b-form-input>
                                                    </td>
                                                    <th width="20%">P.I.C</th>
                                                    <td width="5%">:</td>
                                                    <td width="25%">
                                                    <b-form-input type="text" placeholder="Type Text ..." name="pic"
                                                    v-model="form_repair_service.data.pic"
                                                    ></b-form-input>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            </fieldset>
                                            <hr>
                                            <fieldset class="form-group border p-3" v-if="componentIdSelected && !is_form_created">
                                                <legend class="w-auto px-2 text-primary">Selected Equipments</legend>
                                                <b-row v-if="selected_components.length > 0">
                                                    <b-col md="12" class="mb-2" v-for="(component, componentIndex) in selected_components" :key="componentIndex">
                                                        <div class="p-2 pl-4 pr-4 bg-primary d-flex align-items-center justify-content-between gap-4 rounded">
                                                            <span>{{component.maintenanceMaster.equipmentId}} - {{component.maintenanceMaster.equipmentName}}</span>
                                                            <i class="fa fa-trash text-danger" style="cursor: pointer;" @click="() => {
                                                                    removeSelectedComponent(selected_components, component.id);
                                                                    changeStatusIsSelected(component.id)
                                                                }"  />
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </fieldset>
                                            <hr>
                                            <div v-if="componentIdSelected">
                                                <b-row v-if="list_equipment && list_equipment.length === 0">
                                                    <b-col cols="12">
                                                        <b-alert :show="true" variant=" " class="text-white bg-warning">
                                                        <div class="iq-alert-icon">
                                                            <i class="ri-alert-line"></i>
                                                        </div>
                                                        <div class="iq-alert-text">For Information Equipment Not Setup for Fleet <b>{{fleetNameChecked}}</b>, Please Setup First</div>
                                                        </b-alert>
                                                    </b-col>
                                                </b-row>
                                                <b-row v-if="list_equipment.length > 0 && !is_form_created">
                                                    <b-col cols="12">
                                                        <div class="table-responsive">
                                                        <table class="table mb-0 table-striped table-hover">
                                                            <thead class="text-center">
                                                            <tr>
                                                            <td width="3%">
                                                                <input
                                                                    type="checkbox"
                                                                    v-model="all_equipment_selected"
                                                                    :value="all_equipment_selected"
                                                                    :name="all_equipment_selected"
                                                                />
                                                            </td>
                                                            <td>Equipment - Code</td>
                                                            <td>Type - Serial No</td>
                                                            <td>Maker</td>
                                                            <td>Job Index</td>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr v-for="(components, componentIndex) in list_equipment" :key="components.id">
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        :id="list_equipment[componentIndex].id"
                                                                        v-model="list_equipment[componentIndex].is_selected"
                                                                        :value="list_equipment[componentIndex].is_selected"
                                                                        :name="`list_equipment[${componentIndex}].is_selected`"
                                                                        class="text-primary"
                                                                        @change="selectedComponents(components)"
                                                                    />
                                                                </td>
                                                                <td>{{components.maintenanceMaster.equipmentId}} - {{components.maintenanceMaster.equipmentName}}</td>
                                                                <td>{{components.typeNo}} - {{components.serialNo}}</td>
                                                                <td><ul><li>Maker No: {{components.makerNo}}</li><li>Maker: {{components.maker}}</li></ul></td>
                                                                <td class="text-center">
                                                                    <small v-if="components.status.length === 0"><b-badge variant="danger d-block">NOT YET INIT</b-badge></small>
                                                                    <small v-else>
                                                                        <b-badge variant="success d-block" v-for="(stats, statsIndex) in components.status" :key="statsIndex" class="mb-1">{{ stats }}</b-badge>
                                                                    </small>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                        </div>
                                                    </b-col>
                                                    <b-col cols="12">
                                                        <b-button variant="success" block @click="createEquipmentForm" v-if="form_repair_service.data.type_of_job === 'equipment_init' && !is_form_created">
                                                            <i class="fa fa-plus"></i> CREATE FORM
                                                        </b-button>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                            <b-row v-if="!componentIdSelected && form_repair_service.data.type_of_job === 'equipment_init'">
                                                <b-col>
                                                    <b-alert show variant="warning">Please select component first!</b-alert>
                                                </b-col>
                                            </b-row>
                                          
                                            <div v-if="form_repair_service.data.type_of_job === 'custom' || is_form_created">
                                                <fieldset class="form-group border p-3" v-for="(state, index) in form_repair_service.data.contents" :key="`add-` + index" >
                                                <legend class="w-auto px-2 text-primary">Repair #{{ index + 1 }}</legend>
                                                <b-form-row>
                                                    <b-col>
                                                    <b-form-group label="Equipment Name" label-for="equipmentName">
                                                        <b-form-input type="text" name="name" placeholder="Type Text ..." required
                                                        v-model="state.name"
                                                        :disabled="form_repair_service.data.type_of_job === 'equipment_init'"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                    <b-form-group label="Work Item" label-for="repairTitle">
                                                        <b-form-input type="text" name="title" placeholder="Type Text ..." required
                                                        v-model="state.title"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                    <b-form-group label="Type R & M *" label-for="type_repair">
                                                        <b-form-select plain v-model="state.task_type_id" name='task_type_id' :options="optionsTaskType" size="lg" required>
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Type R & M</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                    </b-form-group>
                                                    </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                    <b-col md="2">
                                                    <b-form-group label="Department *" label-for="department" >
                                                        <b-form-select plain v-model="state.department_id" name='department_id' :options="optionsDepartment" size="lg" required>
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Department</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                    </b-form-group>
                                                    </b-col>
                                                    <b-col md="2">
                                                        <b-form-group label="Section *" label-for="section" >
                                                            <b-form-select plain v-model="state.section" name='section' size="lg" required>
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="''">Select Section</b-form-select-option>
                                                                <b-form-select-option :value="'DECK'">Deck</b-form-select-option>
                                                                <b-form-select-option :value="'ENGINE'">Engine</b-form-select-option>
                                                            </template>
                                                            </b-form-select>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                    <b-form-group label="Category *" label-for="category">
                                                        <b-form-select plain v-model="state.category_id" name='category_id' :options="optionsCategory" size="lg" required>
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Category</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                    </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                    <b-form-group label="Classification *" label-for="classification">
                                                        <b-form-select plain v-model="state.classification_id" name='classification_id' :options="optionsClassification" @change="handleCountDaysBasedClassification(state)" size="lg" required>
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Classification</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                    </b-form-group>
                                                    </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                    <b-col>
                                                    <b-form-group label="Cause *" label-for="cause" >
                                                        <b-form-select plain v-model="state.case_id" name='case_id' :options="optionsCause" size="lg" required>
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Cause</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                    </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                    <b-form-group label="Kind of Task *" label-for="kind_of_task" >
                                                        <b-form-select plain v-model="state.kind_task_id" name='kind_task_id' :options="optionsKindTask" size="lg" required>
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Kind of Task</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                    </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                    <b-form-group label="Berita Acara" label-for="attactment_file">
                                                        <b-form-file plain multiple v-model="state.file" name='file' id="attactment_file" accept="image/*,application/*"></b-form-file>
                                                    </b-form-group>
                                                    </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                    <b-col>
                                                    <b-form-group label="Rencana Mulai *" label-for="mulai">
                                                        <b-form-input type="date" name="start_at" id="mulai"  @change="() => {calculateDays(state); handleCountDaysBasedClassification(state)}" required 
                                                        v-model="state.start_at"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                    <b-form-group label="Rencana Selesai *" label-for="selesai">
                                                        <b-form-input type="date" name="" id="selesai" @change="calculateDays(state)"  required
                                                        v-model="state.estimated_finished_at"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                    <b-form-group label="Estimasi Hari" label-for="estimasi_hari">
                                                        <b-button variant="outline-primary" class="mb-1">
                                                        Perkiraan Pekerjaan <b-badge variant="primary" class="ml-2">{{ state?.estimate_total_days }} Hari</b-badge>
                                                        </b-button>
                                                    </b-form-group>
                                                    </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                    <b-col>
                                                    <b-form-group label="Description" label-for="description">
                                                        <b-form-textarea id="description" rows="3" name="" placeholder="Type Text ..."
                                                        v-model="state.description"
                                                        ></b-form-textarea>
                                                    </b-form-group>
                                                    </b-col>
                                                </b-form-row>
                                                <b-row class="mt-2">
                                                    <b-col md="12">
                                                    <b-button variant="danger" class="pull-right" size="sm" 
                                                    @click="removeRepairJob(form_repair_service.data.contents, index)">
                                                        <i class="fa fa-trash "></i> Delete Repair Job
                                                    </b-button>
                                                    </b-col>
                                                </b-row>
                                                </fieldset>
                                                <b-button variant="success" block @click="addRepairJob(form_repair_service.data.contents)">
                                                    <i class="fa fa-plus"></i> ADD JOB REPAIR & MAINTENANCE
                                                </b-button>
                                            </div>
                                        </b-col>
                                        <b-col md="12" class="text-right mt-2" v-if="is_form_created || form_repair_service.data.type_of_job === 'custom'">
                                            <b-card-text>
                                            <small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge>
                                            </small>
                                            </b-card-text>
                                            <b-button type="submit" variant="primary">
                                            <span
                                                v-if="form_repair_service.loading"
                                                class="spinner-border spinner-border-sm"
                                            />
                                            <span v-else><i class="fa fa-save"></i> Save</span>
                                            </b-button>
                                            <b-button type="button" variant="none" class="iq-bg-danger ml-3" @click="resetFormAddServiceAndRepair">
                                            <i class="fa fa-close"></i> Reset Form
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </fieldset>
                        </b-col>
                    </b-row>
                    </form>
                    </template>
                </b-sidebar>

                <!-- SLIDE END -->
                <!-- SLIDE TASK R & M DATA -->
                <b-sidebar v-model="isModalAddJob" id="add-job" :title="`ADD JOB REPAIR & MAINTENANCE - ` + job_data?.fleet" width="100%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
                    <template #default="{hide}">
                    <form action="#" method="post" @submit="setJobPerService">
                        <b-row class="m-3">
                            <b-col cols="12">
                                <fieldset class="form-group border p-3">
                                <legend class="w-auto px-2 text-primary">Service & Repair Form</legend>
                                <b-row>
                                    <b-col md="4" v-if="job_data.type_of_job === 'equipment_init'">
                                        <v-jstree :data="treeInitMaintenance" show-checkbox allow-batch whole-row @item-click="itemClickInitComponent">
                                            <template slot-scope="_">
                                                <div style="display: inherit; width: 200px">
                                                <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
                                                {{_.model.text}}
                                                </div>
                                            </template>
                                        </v-jstree>
                                    </b-col>
                                    <b-col :md="job_data.type_of_job === 'custom' ? '12' : '8'">
                                    <b-row class="mt-3">
                                        <b-col md="12">
                                        <b-alert :show="true" variant="primary">
                                            <div class="custom-control custom-control-inline">
                                            <label>
                                                <b>SELECT JOB FROM :</b>
                                            </label>
                                            </div>
                                            <b-form-group>
                                                <b-form-radio-group
                                                    id="type_of_job"
                                                    v-model="job_data.type_of_job"
                                                    name="type_of_job"
                                                    required
                                                >
                                                    <b-form-radio value="equipment_init">From Equipment</b-form-radio>
                                                    <b-form-radio value="custom">Custom</b-form-radio>
                                                </b-form-radio-group>
                                            </b-form-group>
                                        </b-alert>
                                        </b-col>
                                        <b-col md="12">
                                        <fieldset class="form-group border p-3">
                                            <legend class="w-auto px-2 text-primary">Detail Information</legend>
                                            <table class="table mb-0 table-striped">
                                            <tbody>
                                                <tr>
                                                <th width="20%">Lokasi Pekerjaan</th>
                                                <td width="5%">:</td>
                                                <td width="25%">{{ job_data?.location }}</td>
                                                <th width="20%">Tanggal</th>
                                                <td width="5%">:</td>
                                                <td width="25%">{{ moment(job_data?.accident_at).format('DD MMMM YYYY') }}</td>
                                                </tr>
                                                <tr>
                                                <th width="20%">Office / Site</th>
                                                <td width="5%">:</td>
                                                <td width="25%">{{job_data?.site}}</td>
                                                <th width="20%">PIC Repair</th>
                                                <td width="5%">:</td>
                                                <td width="25%">{{ job_data?.pic }}</td>
                                                </tr>
                                            </tbody>
                                            </table>
                                        </fieldset>
                                        <div v-for="(state, index) in job_data?.existed_data" :key="index">
                                            <b-card no-body>
                                            <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                                <b-button block v-b-toggle="`accordion-` + index" 
                                                :variant="state?.status?.alias === 'new' ? 'info' : 
                                                state?.status?.alias === 'progress' ? 'warning' : 
                                                state?.status?.alias === 'finish' ? 'success' :
                                                state?.status?.alias === 'review' ? 'primary' : 
                                                'danger'" class="text-left">
                                                <i class="fa fa-chevron-circle-down"></i> {{ state?.name }} - {{ state?.title }}  <strong>({{ state?.status?.name }})</strong>
                                                </b-button>
                                            </b-card-header>
                                            <b-collapse :id="'accordion-' + index" :visible="false" accordion="my-accordion" role="tabpanel">
                                                <table class="table table-striped mt-3">
                                                <tbody>
                                                    <tr>
                                                    <th width="20%">Equipment Name</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">{{ state?.name }}</td>
                                                    <th width="20%">Repair Title</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">{{ state?.title }}</td>
                                                    </tr>
                                                    <tr>
                                                    <th width="20%">Type R & M</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">{{ state?.task_type?.name }}</td>
                                                    <th width="20%">Department</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">{{ state?.department?.name }}</td>
                                                    </tr>
                                                    <tr>
                                                    <th width="20%"></th>
                                                    <td width="5%" class="text-center"></td>
                                                    <td width="25%"></td>
                                                    <th width="20%">Section</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">{{ state?.section }}</td>
                                                    </tr>
                                                    <tr>
                                                    <th width="20%">Category</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">{{ state?.category?.name }}</td>
                                                    <th width="20%">Classification</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">{{ state?.classification?.name }} ({{ state?.classification?.interval }} {{ state?.classification?.unit }})</td>
                                                    </tr>
                                                    <tr>
                                                    <th width="20%">Cause</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">{{ state?.case?.name }}</td>
                                                    <th width="20%">Kind Task</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Repair</td>
                                                    </tr>
                                                    <tr>
                                                    <th width="20%">Rencana Mulai</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">{{ moment(state?.start_at).format('DD MMMM YYYY') }}</td>
                                                    <th width="20%">Rencana Selesai (Est)</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">{{ moment(state?.actual_finished_at).format('DD MMMM YYYY') }}</td>
                                                    </tr>
                                                    <tr>
                                                    <td width="20%"><b>Estimate Cost</b></td>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                        {{ formatCurrency(state.cost_estimate) }}
                                                    </td>
                                                    <th width="20%">Estimasi Hari</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">{{ state?.estimate_total_days }} Hari</td>
                                                    </tr>
                                                    <tr>
                                                    <th width="20%">Berita Acara</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td colspan="4">
                                                        <ul class="d-flex p-0 m-0">
                                                            <li class="mr-2" v-for="(file_data, fileIndex) in state?.file" :key="`file-` + fileIndex">
                                                                <img v-if="file_data.extension == 'pdf'" src="../../assets/images/activity/pdf.png" alt="img-image" style="width: 70px; height: 70px;" @click="handleOpenFilePreview(`${apiUrl}/${file_data.file}`, `berita_acara`)" />
                                                                <img v-else :src="`${apiUrl}/${file_data?.file}`" alt="ing" style="width: 100px; height: 70px;" @click="handleOpenFilePreview(`${apiUrl}/${file_data.file}`, `berita_acara`)">
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    </tr>
                                                    <tr>
                                                    <th width="20%">Description</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td class="align-top" colspan="4">
                                                        {{ state?.description }}
                                                    </td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                                <br />
                                                <fieldset class="form-group border p-3" v-if="state?.is_internal">
                                                    <legend class="w-auto px-2">Internal Man Power</legend>
                                                    <table class="table table-striped table-sm" >
                                                        <tbody>
                                                            <tr>
                                                            <th class="text-left">NAMA</th>
                                                            <th class="text-left">JABATAN</th>
                                                            </tr>
                                                            <tr v-for="(entity, entityIndex) in state?.entity_internal" :key="entityIndex">
                                                            <td class="text-left">{{ entity?.internal_name }}</td>
                                                            <td class="text-left">{{ positionName(entity?.internal_position_id) }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </fieldset>
                                                <fieldset class="form-group border p-3" v-if="state?.is_subkon">
                                                    <legend class="w-auto px-2">Sub Kontraktor</legend>
                                                    <table class="table table-striped table-lg" >
                                                    <tbody>
                                                        <tr>
                                                        <th class="pl-3">NAMA KONTRAKTOR</th>
                                                        <th>PIC/EXECUTIVE ENGINEER</th>
                                                        <!-- <th>EMAIL</th>
                                                        <th>PHONE</th> -->
                                                        <th class="text-center">OFFERING</th>
                                                        <th class="text-center">NEGOTIATION</th>
                                                        <th class="text-center">FINAL NEGOTIATION</th>
                                                        <th class="text-center">FILE</th>
                                                        </tr>
                                                        <tr v-for="(entity, entityIndex) in state?.entity_subkon" :key="entityIndex">
                                                        <td class="pl-3" >{{ entity?.subkon_name }}</td>
                                                        <td >{{ entity?.subkon_pic }}</td>
                                                        <!-- <td >{{ entity?.subkon_pic_email }}</td>
                                                        <td >{{ entity?.subkon_pic_phone }}</td> -->
                                                        <td class="text-center" >{{ formatCurrency(entity?.subkon_price_offering) }}</td>
                                                        <td class="text-center" >{{ formatCurrency(entity?.subkon_price_nego) }}</td>
                                                        <td class="text-center" >{{ formatCurrency(entity?.subkon_price_deal) }}</td>
                                                        <td class="text-center text-primary" v-if="entity?.subkon_file?.length > 0">
                                                            <div v-for="(fileSubkon, subkonIndexFile) in entity?.subkon_file" :key="subkonIndexFile"  class="mr-2">
                                                                <i v-if="fileSubkon.extension === 'pdf'" class="fa fa-file-pdf text-primary h4" style="cursor:pointer" @click="handleOpenFilePreview(`${apiUrl}/${fileSubkon.url}`, `${entity?.subkon_name}`)"></i>
                                                                <img v-else :src="`${apiUrl}/${fileSubkon?.url}`" alt="" class="img-fluid" style="width: 70px; height: 70px;" @click="handleOpenFilePreview(`${apiUrl}/${fileSubkon.url}`, `${entity?.subkon_name}`)">
                                                            </div>
                                                        </td>
                                                        </tr>
                                                    </tbody>
                                                    </table>
                                                </fieldset>
                                                <b-row>
                                                    <b-col md="12">
                                                        <ul class="iq-timeline" style="height:100%">
                                                            <li v-for="(activity, aIndex) in state?.form_repair_activity" :key="aIndex">
                                                                <div class="timeline-dots border-success"></div>
                                                                <h6 class="float-left mb-1">{{ activity?.user_name }}</h6>
                                                                <br />
                                                                <small>{{ moment(activity?.date_of_activity).format('DD MMMM YYYY hh:mm') }}</small>
                                                                <div class="d-inline-block w-100">
                                                                    <b-badge :variant="activity?.activity?.activity_flag === 'running' ? 'primary' : activity?.activity?.activity_flag === 'finish' ? 'success' : 'danger'">{{ activity?.activity?.name ?? '-' }}</b-badge> <br /> <p><strong class="text-muted">Notes:</strong> {{ activity?.notes }}</p>
                                                                </div>
                                                                <div class="d-inline-block w-100" v-if="activity?.parsed_file?.length > 0">
                                                                    <ul class="d-flex p-0 m-0">
                                                                    <li class="pb-2" v-for="(file, fileIndex) in activity?.parsed_file" :key="fileIndex">
                                                                        <img v-if="file.extension == 'pdf'" src="../../assets/images/activity/pdf.png" alt="img-image" class="img-fluid" style="width: 70px; height: 70px;" @click="handleOpenFilePreview(`${apiUrl}/${file.url}`, `${activity?.user_name}_activity`)" />
                                                                        <img v-else :src="`${apiUrl}/${file.url}`" alt="image" style="width: 70px; height: 70px;" @click="handleOpenFilePreview(`${apiUrl}/${file.url}`, `${activity?.user_name}_activity`)" />
                                                                    </li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <b-button type="button" variant="primary" @click="getSingleServiceAndRepair(state.form_id, 10)" v-if="state?.form_repair_activity?.length > 0">
                                                                    Load More
                                                                </b-button>
                                                            </li>
                                                        </ul>
                                                        
                                                    </b-col>
                                                </b-row>
                                            </b-collapse>
                                            </b-card>
                                        </div>
                                        <hr>
                                        <fieldset class="form-group border p-3" v-if="componentIdSelected && !is_form_created">
                                            <legend class="w-auto px-2 text-primary">Selected Equipments</legend>
                                            <b-row v-if="selected_components.length > 0">
                                                <b-col md="12" class="mb-2" v-for="(component, componentIndex) in selected_components" :key="componentIndex">
                                                    <div class="p-2 pl-4 pr-4 bg-primary d-flex align-items-center justify-content-between gap-4 rounded">
                                                        <span>{{component.maintenanceMaster.equipmentId}} - {{component.maintenanceMaster.equipmentName}}</span>
                                                        <i class="fa fa-trash text-danger" style="cursor: pointer;" @click="() => {
                                                                removeSelectedComponent(selected_components, component.id);
                                                                changeStatusIsSelected(component.id)
                                                            }"  />
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </fieldset>
                                        <hr>
                                        <div v-if="componentIdSelected">
                                            <b-row v-if="list_equipment && list_equipment.length === 0">
                                                <b-col cols="12">
                                                    <b-alert :show="true" variant=" " class="text-white bg-warning">
                                                    <div class="iq-alert-icon">
                                                        <i class="ri-alert-line"></i>
                                                    </div>
                                                    <div class="iq-alert-text">For Information Equipment Not Setup for Fleet <b>{{fleetNameChecked}}</b>, Please Setup First</div>
                                                    </b-alert>
                                                </b-col>
                                            </b-row>
                                            <b-row v-if="list_equipment.length > 0 && !is_form_created">
                                                <b-col cols="12">
                                                    <div class="table-responsive">
                                                    <table class="table mb-0 table-striped table-hover">
                                                        <thead class="text-center">
                                                        <tr>
                                                        <td width="3%">
                                                            <input
                                                                type="checkbox"
                                                                v-model="all_equipment_selected"
                                                                :value="all_equipment_selected"
                                                                :name="all_equipment_selected"
                                                            />
                                                        </td>
                                                        <td>Equipment - Code</td>
                                                        <td>Type - Serial No</td>
                                                        <td>Maker</td>
                                                        <td>Job Index</td>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr v-for="(components, componentIndex) in list_equipment" :key="components.id">
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    :id="list_equipment[componentIndex].id"
                                                                    v-model="list_equipment[componentIndex].is_selected"
                                                                    :value="list_equipment[componentIndex].is_selected"
                                                                    :name="`list_equipment[${componentIndex}].is_selected`"
                                                                    class="text-primary"
                                                                    @change="selectedComponents(components)"
                                                                />
                                                            </td>
                                                            <td>{{components.maintenanceMaster.equipmentId}} - {{components.maintenanceMaster.equipmentName}}</td>
                                                            <td>{{components.typeNo}} - {{components.serialNo}}</td>
                                                            <td><ul><li>Maker No: {{components.makerNo}}</li><li>Maker: {{components.maker}}</li></ul></td>
                                                            <td class="text-center">
                                                                <small v-if="components.status.length === 0"><b-badge variant="danger d-block">NOT YET INIT</b-badge></small>
                                                                <small v-else>
                                                                    <b-badge variant="success d-block" v-for="(stats, statsIndex) in components.status" :key="statsIndex" class="mb-1">{{ stats }}</b-badge>
                                                                </small>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                </b-col>
                                                <b-col cols="12">
                                                    <b-button variant="success" block @click="createEquipmentForm" v-if="job_data.type_of_job === 'equipment_init' && !is_form_created">
                                                        <i class="fa fa-plus"></i> CREATE FORM
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </div>
                                        <b-row v-if="!componentIdSelected && job_data.type_of_job === 'equipment_init'">
                                            <b-col>
                                                <b-alert show variant="warning">Please select component first!</b-alert>
                                            </b-col>
                                        </b-row>
                                    
                                        <div v-if="job_data.type_of_job === 'custom' || is_form_created">
                                            <fieldset class="form-group border p-3" v-for="(state, index) in job_data?.form_repair" :key="`add-` + index" >
                                            <legend class="w-auto px-2 text-primary">Repair #{{ index + 1 }}</legend>
                                            <b-form-row>
                                                <b-col>
                                                <b-form-group label="Equipment Name" label-for="equipmentName">
                                                    <b-form-input type="text" name="name" placeholder="Type Text ..." required
                                                    v-model="state.name"
                                                    :disabled="job_data.type_of_job === 'equipment_init'"
                                                    ></b-form-input>
                                                </b-form-group>
                                                </b-col>
                                                <b-col>
                                                <b-form-group label="Work Item" label-for="repairTitle">
                                                    <b-form-input type="text" name="title" placeholder="Type Text ..." required
                                                    v-model="state.title"
                                                    ></b-form-input>
                                                </b-form-group>
                                                </b-col>
                                                <b-col>
                                                <b-form-group label="Type R & M *" label-for="type_repair">
                                                    <b-form-select plain v-model="state.task_type_id" name='task_type_id' :options="optionsTaskType" size="lg" required>
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="null">Select Type R & M</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                </b-form-group>
                                                </b-col>
                                            </b-form-row>
                                            <b-form-row>
                                                <b-col md="2">
                                                    <b-form-group label="Department *" label-for="department" >
                                                        <b-form-select plain v-model="state.department_id" name='department_id' :options="optionsDepartment" size="lg" required>
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Department</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                    </b-form-group>
                                                    </b-col>
                                                    <b-col md="2">
                                                        <b-form-group label="Section *" label-for="section" >
                                                            <b-form-select plain v-model="state.section" name='section' size="lg" required>
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="''">Select Section</b-form-select-option>
                                                                <b-form-select-option :value="'DECK'">Deck</b-form-select-option>
                                                                <b-form-select-option :value="'ENGINE'">Engine</b-form-select-option>
                                                            </template>
                                                            </b-form-select>
                                                        </b-form-group>
                                                    </b-col>
                                                <b-col>
                                                <b-form-group label="Category *" label-for="category">
                                                    <b-form-select plain v-model="state.category_id" name='category_id' :options="optionsCategory" size="lg" required>
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="null">Select Category</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                </b-form-group>
                                                </b-col>
                                                <b-col>
                                                <b-form-group label="Classification *" label-for="classification">
                                                    <b-form-select plain v-model="state.classification_id" name='classification_id' :options="optionsClassification" @change="handleCountDaysBasedClassification(state)" size="lg" required>
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="null">Select Classification</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                </b-form-group>
                                                </b-col>
                                            </b-form-row>
                                            <b-form-row>
                                                <b-col>
                                                <b-form-group label="Cause *" label-for="cause" >
                                                    <b-form-select plain v-model="state.case_id" name='case_id' :options="optionsCause" size="lg" required>
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="null">Select Cause</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                </b-form-group>
                                                </b-col>
                                                <b-col>
                                                <b-form-group label="Kind of Task *" label-for="kind_of_task" >
                                                    <b-form-select plain v-model="state.kind_task_id" name='kind_task_id' :options="optionsKindTask" size="lg" required>
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="null">Select Kind of Task</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                </b-form-group>
                                                </b-col>
                                                <b-col>
                                                <b-form-group label="Berita Acara" label-for="attactment_file">
                                                    <b-form-file plain multiple v-model="state.file" name='file' id="attactment_file" accept="image/*,application/*"></b-form-file>
                                                </b-form-group>
                                                </b-col>
                                            </b-form-row>
                                            <b-form-row>
                                                <b-col>
                                                <b-form-group label="Rencana Mulai *" label-for="mulai">
                                                    <b-form-input type="date" name="start_at" id="mulai"  @change="() => {calculateDays(state); handleCountDaysBasedClassification(state)}" required 
                                                    v-model="state.start_at"
                                                    ></b-form-input>
                                                </b-form-group>
                                                </b-col>
                                                <b-col>
                                                <b-form-group label="Rencana Selesai *" label-for="selesai">
                                                    <b-form-input type="date" name="" id="selesai" @change="calculateDays(state)"  required
                                                    v-model="state.estimated_finished_at"
                                                    ></b-form-input>
                                                </b-form-group>
                                                </b-col>
                                                <b-col>
                                                <b-form-group label="Estimasi Hari" label-for="estimasi_hari">
                                                    <b-button variant="outline-primary" class="mb-1">
                                                    Perkiraan Pekerjaan <b-badge variant="primary" class="ml-2">{{ state?.estimate_total_days }} Hari</b-badge>
                                                    </b-button>
                                                </b-form-group>
                                                </b-col>
                                            </b-form-row>
                                            <b-form-row>
                                                <b-col>
                                                <b-form-group label="Description" label-for="description">
                                                    <b-form-textarea id="description" rows="3" name="" placeholder="Type Text ..."
                                                    v-model="state.description"
                                                    ></b-form-textarea>
                                                </b-form-group>
                                                </b-col>
                                            </b-form-row>
                                            <b-row class="mt-2">
                                                <b-col md="12">
                                                <b-button variant="danger" class="pull-right" size="sm" 
                                                @click="removeRepairJob(job_data.form_repair, index)">
                                                    <i class="fa fa-trash "></i> Delete Repair Job
                                                </b-button>
                                                </b-col>
                                            </b-row>
                                            </fieldset>
                                            <b-button variant="success" block @click="addRepairJob(job_data.form_repair)">
                                                <i class="fa fa-plus"></i> ADD JOB REPAIR & MAINTENANCE
                                            </b-button>
                                        </div>
                                        </b-col>
                                        <b-col md="12" class="text-right mt-2" v-if="is_form_created || job_data.type_of_job === 'custom'">
                                            <b-card-text>
                                                <small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge>
                                                </small>
                                            </b-card-text>
                                            <b-button type="submit" variant="primary" :disabled="job_loading">
                                                <span
                                                    v-if="job_loading"
                                                    class="spinner-border spinner-border-sm"
                                                />
                                                <span v-else><i class="fa fa-save"></i> Save</span>
                                            </b-button>
                                            <b-button type="button" variant="none" class="iq-bg-danger ml-3" @click="resetFormAddServiceAndRepair">
                                                <i class="fa fa-close"></i> Reset Form
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                    </b-col>
                                </b-row>
                                </fieldset>
                            </b-col>
                        </b-row>
                    </form>
                    </template>
                </b-sidebar>
                <!-- SLIDE END -->
                <!-- SLIDE REVIEW -->
                <b-sidebar id="review_sr" :title="`REVIEW REPAIR & MAINTENANCE - ` + repair_data?.fleet" width="70%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
                    <template #default="{hide}">
                    <form action="#" method="post" @submit="onSaveReviewRepair">
                        <b-row class="m-3">
                            <b-col md="12">
                            <fieldset class="form-group border p-3">
                                <legend class="w-auto px-2 text-primary">Detail Information</legend>
                                <table class="table mb-0 table-striped">
                                <tbody>
                                    <tr>
                                    <th width="20%">Lokasi Pekerjaan</th>
                                    <td width="5%">:</td>
                                    <td width="25%">{{ repair_data?.location }}</td>
                                    <th width="20%">Tanggal</th>
                                    <td width="5%">:</td>
                                    <td width="25%">{{ moment(repair_data?.accident_at).format('DD MMMM YYYY') }}</td>
                                    </tr>
                                    <tr>
                                    <th width="20%">Office / Site</th>
                                    <td width="5%">:</td>
                                    <td width="25%">{{repair_data?.site}}</td>
                                    <th width="20%">PIC Repair</th>
                                    <td width="5%">:</td>
                                    <td width="25%">{{ repair_data?.pic }}</td>
                                    </tr>
                                </tbody>
                                </table>
                            </fieldset>
                            <div  v-for="(state, index) in repair_data?.form_repair" :key="index">
                                <b-card no-body>
                                <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                    <b-button block v-b-toggle="`accordion-` + index" variant="info" class="text-left">
                                    <i class="fa fa-chevron-circle-down"></i> {{ state?.name }} - {{ state?.title }}
                                    </b-button>
                                </b-card-header>
                                <b-collapse :id="'accordion-' + index" :visible="false" accordion="my-accordion" role="tabpanel">
                                    <table class="table table-striped mt-3">
                                    <tbody>
                                        <tr>
                                        <th width="20%">Equipment Name</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.name }}</td>
                                        <th width="20%">Repair Title</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.title }}</td>
                                        </tr>
                                        <tr>
                                        <th width="20%">Type R & M</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.task_type?.name }}</td>
                                        <th width="20%">Department</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.department?.name }}</td>
                                        </tr>
                                        <tr>
                                        <th width="20%"></th>
                                        <td width="5%" class="text-center"></td>
                                        <td width="25%"></td>
                                        <th width="20%">Section</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.section }}</td>
                                        </tr>
                                        <tr>
                                        <th width="20%">Category</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.category?.name }}</td>
                                        <th width="20%">Classification</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.classification?.name }} ({{ state?.classification?.interval }} {{ state?.classification?.unit }})</td>
                                        </tr>
                                        <tr>
                                        <th width="20%">Cause</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.case?.name }}</td>
                                        <th width="20%">Kind Task</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Repair</td>
                                        </tr>
                                        <tr>
                                        <th width="20%">Rencana Mulai</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ moment(state?.start_at).format('DD MMMM YYYY') }}</td>
                                        <th width="20%">Rencana Selesai (Est)</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ moment(state?.actual_finished_at).format('DD MMMM YYYY') }}</td>
                                        </tr>
                                        <tr>
                                        <td width="20%"><b>Estimate Cost</b></td>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">
                                            <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                            </div>
                                            <b-form-input type="number" v-model="state.cost_estimate" required class="form-control" 
                                                id="cost_estimate" name="cost_estimate" placeholder="Type Number ..." />
                                            </div>
                                            <b-form-text class="text-muted">
                                            {{ formatCurrency(state.cost_estimate) }}
                                            </b-form-text>
                                        </td>
                                        <th width="20%">Estimasi Hari</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.estimate_total_days }} Hari</td>
                                        </tr>
                                        <tr>
                                        <th width="20%">Berita Acara</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">
                                            <ul class="d-flex p-0 m-0">
                                                <li class="mr-2" v-for="(file_data, fileIndex) in state?.file" :key="`file-` + fileIndex">
                                                    <img v-if="file_data.extension == 'pdf'" src="../../assets/images/activity/pdf.png" alt="img-image" style="width: 70px; height: 70px;" @click="handleOpenFilePreview(`${apiUrl}/${file_Data.file}`, `berita_acara`)" />
                                                    <img v-else :src="`${apiUrl}/${file_data?.file}`" alt="ing" style="width: 100px; height: 70px;" @click="handleOpenFilePreview(`${apiUrl}/${file_Data.file}`, `berita_acara`)" />
                                                </li>
                                            </ul>
                                        </td>
                                        </tr>
                                        <tr>
                                        <th width="20%">Description</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td class="align-top" colspan="4">
                                            {{ state?.description }}
                                        </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                    <hr>
                                    <b-form-row>
                                        <b-col>
                                        <b-form-group class="col-md-12">
                                            <b-form-checkbox
                                            v-model="state.is_internal"
                                            name="check-button"
                                            switch
                                            >
                                            <strong>Internal Man Power</strong>
                                            </b-form-checkbox>
                                        </b-form-group>
                                        </b-col>
                                    </b-form-row>
                                    <fieldset class="form-group border p-3" v-if="state?.is_internal">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <table class="table table-borderless">
                                        <tbody>
                                            <tr class="iq-bg-primary">
                                            <th width="45%">NAMA LENGKAP</th>
                                            <th width="45%">JABATAN</th>
                                            <th class="text-center" width="10%">ACTION</th>
                                            </tr>
                                            <tr v-for="(internal, internalIndex) in state?.review_internal" :key="internalIndex">
                                            <td><b-form-input type="text" v-model="internal.internal_name" id="nama_internal_man_power" size="sm" placeholder="Type Text ..."></b-form-input></td>
                                            <td>
                                                <b-form-select plain v-model="internal.internal_position_id" :options="optionsJabatan" size="sm">
                                                <template v-slot:first>
                                                    <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                            </td>
                                            <td class="text-center" >
                                                <b-button variant="danger" @click="removeInternal(index, internalIndex)">
                                                <i class="fa fa-trash pr-0"></i>
                                                </b-button>
                                            </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                        <b-button variant="success" type="button" @click="addInternal(state.id, index)">
                                        <i class="fa fa-users"></i> ADD INTERNAL MAN POWER
                                        </b-button>
                                    </fieldset>
                                    <b-form-row>
                                        <b-col>
                                        <b-form-group class="col-md-12">
                                            <b-form-checkbox
                                            v-model="state.is_subkon"
                                            name="check-button"
                                            switch
                                            >
                                            <strong>Sub Kontraktor</strong>
                                            </b-form-checkbox>
                                        </b-form-group>
                                        </b-col>
                                    </b-form-row>
                                    <fieldset class="form-group border p-3" v-if="state?.is_subkon">
                                        <legend class="w-auto px-2">Sub Kontraktor</legend>
                                        <div v-for="(subkon, subkonIndex) in state?.review_subkon" :key="subkonIndex">
                                        <b-form-row>
                                            <b-col>
                                            <b-form-group label="Nama Kontraktor" label-for="nama_kontraktor">
                                                <b-form-input type="text" v-model="subkon.subkon_name" name="subkon_name" id="subkon_name" placeholder="Type Text ..."></b-form-input>
                                            </b-form-group>
                                            </b-col>
                                            <b-col>
                                            <b-form-group label="PIC / Executive Engineer" label-for="executive_engineer">
                                                <b-form-input type="text" v-model="subkon.subkon_pic" name="subkon_pic" id="subkon_pic" placeholder="Type Text ..."></b-form-input>
                                            </b-form-group>
                                            </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                            <b-col>
                                            <b-form-group label="PIC Email" label-for="nama_kontraktor">
                                                <b-form-input type="text" v-model="subkon.subkon_pic_email" name="subkon_pic_email" id="subkon_pic_email" placeholder="Type Text ..."></b-form-input>
                                            </b-form-group>
                                            </b-col>
                                            <b-col>
                                            <b-form-group label="PIC Phone" label-for="executive_engineer">
                                                <b-form-input type="number" v-model="subkon.subkon_pic_phone" name="subkon_pic_phone" id="subkon_pic_phone" placeholder="Type Text ..."></b-form-input>
                                            </b-form-group>
                                            </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                            <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                                <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" v-model="subkon.subkon_price_offering" name="subkon_price_offering" 
                                                class="form-control" id="subkon_price_offering" placeholder="Type Number ...">
                                                </div>
                                                <b-form-text class="text-muted">
                                                    {{ formatCurrency(Number(subkon.subkon_price_offering)) }}
                                                </b-form-text>
                                            </b-form-group>
                                            </b-col>
                                            <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                                <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" v-model="subkon.subkon_price_nego" 
                                                name="subkon_price_nego" id="subkon_price_nego" placeholder="Type Number ...">
                                                </div>
                                                <b-form-text class="text-muted">
                                                    {{ formatCurrency(Number(subkon.subkon_price_nego)) }}
                                                </b-form-text>
                                            </b-form-group>
                                            </b-col>
                                            <b-col>
                                            <b-form-group label="Deal Harga" label-for="subkon_price_deal">
                                                <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" v-model="subkon.subkon_price_deal" 
                                                name="subkon_price_deal" id="subkon_price_deal" placeholder="Type Number ...">
                                                </div>
                                                <b-form-text class="text-muted">
                                                    {{ formatCurrency(Number(subkon.subkon_price_deal)) }}
                                                </b-form-text>
                                            </b-form-group>
                                            </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                            <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                                <b-form-textarea v-model="subkon.subkon_notes" name="subkon_notes" id="subkon_notes" rows="2"  placeholder="Type Text ..."></b-form-textarea>
                                            </b-form-group>
                                            </b-col>
                                            <b-col>
                                            <b-form-group label="Berita Acara" label-for="subkon_file">
                                                <b-form-file plain multiple v-model="subkon.subkon_file" name='subkon_file' id="subkon_file"></b-form-file>
                                                <small class="text-muted"> File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge>
                                                <br> File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                                </small>
                                            </b-form-group>
                                            </b-col>
                                        </b-form-row>
                                
                                        <div class="d-flex w-100 align-items-end justify-content-end">
                                            <b-button variant="danger" @click="removeSubkon(index, subkonIndex)">
                                                <i class="fa fa-trash pr-0"></i>
                                            </b-button>
                                        </div>
                                    
                                        </div>
                                        <hr>
                                        <b-button variant="success" type="button" @click="addSubkon(state.id, index)">
                                        <i class="fa fa-users"></i> ADD SUB KONTRAKTOR
                                        </b-button>
                                    </fieldset>
                                </b-collapse>
                                </b-card>
                            </div>
                            </b-col>
                            <b-col md="12">
                            <b-button type="submit" variant="primary" class="mt-2 pull-right">
                                <span
                                v-if="review_loading"
                                class="spinner-border spinner-border-sm"
                                />
                                <span v-else>REVIEW & SUBMIT</span>
                            </b-button>
                            </b-col>
                        </b-row>
                    </form>
                    </template>
                </b-sidebar>
                <!-- SLIDE END -->
                <!-- SLIDE APPROVE -->
                <b-sidebar id="approve_sr" :title="`APPROVE REPAIR & MAINTENANCE - ` + approve_sr_data?.fleet" width="70%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
                    <template #default="{hide}">
                    <form action="#" method="put" @submit="onApproveServiceAndRepair">
                        <b-row class="m-3">
                        <b-col md="12">
                            <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Detail Information</legend>
                            <table class="table mb-0 table-striped">
                                <tbody>
                                <tr>
                                    <th width="20%">Lokasi Pekerjaan</th>
                                    <td width="5%">:</td>
                                    <td width="25%">{{ approve_sr_data?.location }}</td>
                                    <th width="20%">Tanggal</th>
                                    <td width="5%">:</td>
                                    <td width="25%">{{ moment(approve_sr_data?.accident_at).format('DD MMMM YYYY') }}</td>
                                </tr>
                                <tr>
                                    <th width="20%">Office / Site</th>
                                    <td width="5%">:</td>
                                    <td width="25%">{{approve_sr_data?.site}}</td>
                                    <th width="20%">PIC Repair</th>
                                    <td width="5%">:</td>
                                    <td width="25%">{{ approve_sr_data?.pic }}</td>
                                </tr>
                                </tbody>
                            </table>
                            </fieldset>
                            <div v-for="(state, index) in approve_sr_data?.form_repair" :key="index">
                            <b-card no-body>
                                <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                <b-button block v-b-toggle="`accordion-` + index" variant="info" class="text-left">
                                    <i class="fa fa-chevron-circle-down"></i> {{ state?.name }} - {{ state?.title }}
                                </b-button>
                                </b-card-header>
                                <b-collapse :id="'accordion-' + index" :visible="false" accordion="my-accordion" role="tabpanel">
                                <table class="table table-striped mt-3">
                                    <tbody>
                                    <tr>
                                        <th width="20%">Equipment Name</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.name  }}</td>
                                        <th width="20%">Repair Title</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.title  }}</td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Type R & M</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.task_type?.name  }}</td>
                                        <th width="20%">Department</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.department?.name  }}</td>
                                    </tr>
                                    <tr>
                                    <th width="20%"></th>
                                    <td width="5%" class="text-center"></td>
                                    <td width="25%"></td>
                                    <th width="20%">Section</th>
                                    <td width="5%" class="text-center">:</td>
                                    <td width="25%">{{ state?.section }}</td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Category</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.category?.name  }}</td>
                                        <th width="20%">Classification</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.classification?.name  }} ({{ state?.classification?.interval }} {{ state?.classification?.unit }})</td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Cause</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.case?.name  }}</td>
                                        <th width="20%">Kind Task</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.kind_task?.name  }}</td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Rencana Mulai</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ moment(state?.start_at).format('YYYY MMM DD') }}</td>
                                        <th width="20%">Rencana Selesai (Est)</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ moment(new Date(state?.estimated_finished_at)).format('YYYY MMM DD') }}</td>
                                    </tr>
                                    <tr>
                                        <td width="20%"><b>Estimate Cost</b></td>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ formatCurrency(state?.cost_estimate) }}</td>
                                        <th width="20%">Estimasi Hari</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.estimate_total_days }} Hari</td>
                                    </tr>
                                    <tr>
                                        <td width="20%"><b>Cost Real</b></td>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                            </div>
                                            <b-form-input type="number" v-model="state.cost_real" required class="form-control" 
                                                id="cost_real" name="cost_real" placeholder="Type Number ..." />
                                        </div>
                                        <b-form-text class="text-muted">
                                            {{ formatCurrency(state.cost_real) }}
                                        </b-form-text>
                                        </td>
                                        <th width="20%"></th>
                                        <td width="5%" class="text-center"></td>
                                        <td width="25%"></td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Berita Acara</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">
                                        <ul class="d-flex p-0 m-0">
                                            <li class="mr-2" v-for="(file_data, fileIndex) in state?.file" :key="`file-` + fileIndex">
                                                <img v-if="file_data.extension == 'pdf'" src="../../assets/images/activity/pdf.png" alt="img-image" style="width: 70px; height: 70px;" @click="handleOpenFilePreview(`${apiUrl}/${file_Data.file}`, `berita_acara`)" />
                                                <img v-else :src="`${apiUrl}/${file_data?.file}`" alt="img" style="width: 100px; height: 70px;" @click="handleOpenFilePreview(`${apiUrl}/${file_Data.file}`, `berita_acara`)" />
                                            </li>
                                        </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Description</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td class="align-top" colspan="4">
                                        {{ state?.description }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <hr>
                                <fieldset class="form-group border p-3" v-if="state?.is_internal">
                                <legend class="w-auto px-2">Internal Man Power</legend>
                                <table class="table table-striped table-sm">
                                    <tbody>
                                    <tr>
                                        <th class="text-left">NAMA</th>
                                        <th class="text-left">JABATAN</th>
                                    </tr>
                                    <tr v-for="(entity, entityIndex) in state?.entity_internal" :key="entityIndex">
                                        <td class="text-left">{{ entity?.internal_name }}</td>
                                        <td class="text-left">{{ positionName(entity?.internal_position_id) }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                </fieldset>
                                <fieldset class="form-group border p-3" v-if="state?.is_subkon">
                                <legend class="w-auto px-2">Sub Kontraktor</legend>
                                <table class="table table-striped table-sm">
                                    <tbody>
                                    <tr>
                                        <th class="pl-3">NAMA KONTRAKTOR</th>
                                        <th>PIC/EXECUTIVE ENGINEER</th>
                                        <!-- <th>EMAIL</th>
                                        <th>PHONE</th> -->
                                        <th class="text-center">OFFERING</th>
                                        <th class="text-center">NEGOTIATION</th>
                                        <th class="text-center">FINAL NEGOTIATION</th>
                                        <th class="text-center">FILE</th>
                                    </tr>
                                    <tr v-for="(entity, entityIndex) in state?.entity_subkon" :key="entityIndex">
                                        <td class="pl-3" >{{ entity?.subkon_name }}</td>
                                        <td >{{ entity?.subkon_pic }}</td>
                                        <!-- <td >{{ entity?.subkon_pic_email }}</td>
                                        <td >{{ entity?.subkon_pic_phone }}</td> -->
                                        <td class="text-center" >{{ formatCurrency(entity?.subkon_price_offering) }}</td>
                                        <td class="text-center" >{{ formatCurrency(entity?.subkon_price_nego) }}</td>
                                        <td class="text-center" >{{ formatCurrency(entity?.subkon_price_deal) }}</td>
                                        <td class="text-center text-primary" v-if="entity?.subkon_file?.length > 0">
                                            <div v-for="(fileSubkon, subkonIndexFile) in entity?.subkon_file" :key="subkonIndexFile"  class="pb-2">
                                                <i v-if="fileSubkon.extension === 'pdf'" class="fa fa-file-pdf text-primary h4" style="cursor:pointer" @click="handleOpenFilePreview(`${apiUrl}/${fileSubkon.url}`, `${entity?.subkon_name}`)"></i>
                                                <img v-else :src="`${apiUrl}/${fileSubkon?.url}`" alt="" class="img-fluid" style="width: 70px; height: 70px;" @click="handleOpenFilePreview(`${apiUrl}/${fileSubkon.url}`, `${entity?.subkon_name}`)" />
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                </fieldset>
                                <b-alert :show="true" variant="success" v-if="state?.status_repair === true || state?.status_repair === null">
                                <div class="iq-alert-icon">
                                    <i class="fa fa-thumbs-up"></i>
                                </div>
                                <div class="iq-alert-text"><b>APPROVED</b></div>
                                </b-alert>
                                <b-alert :show="true" variant="danger" v-if="state?.status_repair === false || state?.status_repair === null">
                                <div class="iq-alert-icon">
                                    <i class="fa fa-close"></i>
                                </div>
                                <div class="iq-alert-text"><b>REJECT</b></div>
                                </b-alert>
                                <b-button type="button" variant="none" class="iq-bg-danger float-right mb-3 ml-1"
                                @click="changeStatusRepair(state, false)"
                                ><i class="fa fa-close"></i> REJECT</b-button>
                                <b-button type="button" variant="success" class="float-right"
                                @click="changeStatusRepair(state, true)"
                                ><i class="fa fa-thumbs-up"></i> APPROVE JOB</b-button>
                                </b-collapse>
                            </b-card>
                            </div>
                        </b-col>
                        <b-col md="12">
                            <b-button type="submit" variant="primary" class="mt-2 pull-right">
                            <span
                                v-if="approve_sr_loading"
                                class="spinner-border spinner-border-sm"
                            />
                            <span v-else><i class="fa fa-save"></i> APPROVE ALL & SUBMIT</span>
                            </b-button>
                        </b-col>
                        </b-row>
                    </form>
                    </template>
                </b-sidebar>
                <!-- SLIDE END -->
                <!-- SLIDE DETAIL -->
                <b-sidebar id="detail_sr" :title="`DETAIL REPAIR & MAINTENANCE - ` + detail_sr_data?.fleet" width="70%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
                    <template #default="{hide}">
                    <b-row class="m-3">
                        <b-col md="12">
                        <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Detail Information</legend>
                            <table class="table mb-0 table-striped">
                                <tbody>
                                <tr>
                                    <th width="20%">Lokasi Pekerjaan</th>
                                    <td width="5%">:</td>
                                    <td width="25%">{{ detail_sr_data?.location }}</td>
                                    <th width="20%">Tanggal</th>
                                    <td width="5%">:</td>
                                    <td width="25%">{{ moment(detail_sr_data?.accident_at).format('DD MMMM YYYY') }}</td>
                                </tr>
                                <tr>
                                    <th width="20%">Office / Site</th>
                                    <td width="5%">:</td>
                                    <td width="25%">{{detail_sr_data?.site}}</td>
                                    <th width="20%">PIC Repair</th>
                                    <td width="5%">:</td>
                                    <td width="25%">{{ detail_sr_data?.pic }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </fieldset>
                        <div v-for="(state, index) in detail_sr_data?.form_repair" :key="index">
                            <b-card no-body>
                                <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                <b-button block v-b-toggle="`accordion-` + index" 
                                    :variant="state?.status?.alias === 'new' ? 'info' : 
                                    state?.status?.alias === 'progress' ? 'warning' : 
                                    state?.status?.alias === 'finish' ? 'success' : 
                                    state?.status?.alias === 'review' ? 'primary' : 
                                    'danger'" class="text-left">
                                        <i class="fa fa-chevron-circle-down"></i> {{ state?.name }} - {{ state?.title }}  <strong>({{ state?.status?.name }})</strong>
                                </b-button>
                                </b-card-header>
                                <b-collapse :id="'accordion-' + index" :visible="false" accordion="my-accordion" role="tabpanel">
                                <div class="d-flex w-100 align-items-center justify-content-end text-danger mt-2">
                                   <b-button variant="danger" @click="onDeleteRepair(state)">
                                     Delete repair <i class="fa fa-trash ml-2"></i>
                                   </b-button >
                                </div>
                                <table class="table table-striped mt-3">
                                    <tbody>
                                    <tr>
                                        <th width="20%">Equipment Name</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.name  }}</td>
                                        <th width="20%">Repair Title</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.title  }}</td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Type R & M</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.task_type?.name  }}</td>
                                        <th width="20%">Department</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.department?.name }}</td>
                                    </tr>
                                    <tr>
                                    <th width="20%"></th>
                                    <td width="5%" class="text-center"></td>
                                    <td width="25%"></td>
                                    <th width="20%">Section</th>
                                    <td width="5%" class="text-center">:</td>
                                    <td width="25%">{{ state?.section }}</td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Category</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.category?.name  }}</td>
                                        <th width="20%">Classification</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.classification?.name  }} ({{ state?.classification?.interval }} {{ state?.classification?.unit }})</td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Cause</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.case?.name  }}</td>
                                        <th width="20%">Kind Task</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.kind_task?.name  }}</td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Rencana Mulai</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ moment(state?.start_at).format('YYYY MMM DD') }}</td>
                                        <th width="20%">Rencana Selesai (Est)</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ moment(new Date(state?.estimated_finished_at)).format('YYYY MMM DD') }}</td>
                                    </tr>
                                    <tr>
                                        <td width="20%"><b>Estimate Cost</b></td>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ formatCurrency(state?.cost_estimate) }}</td>
                                        <th width="20%">Estimasi Hari</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ state?.estimate_total_days }} Hari</td>
                                    </tr>
                                    <tr>
                                        <td width="20%"><b>Cost Real</b></td>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">{{ formatCurrency(state?.cost_real) }}</td>
                                        <th width="20%"></th>
                                        <td width="5%" class="text-center"></td>
                                        <td width="25%"></td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Berita Acara</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">
                                        <ul class=" d-flex p-0 m-0">
                                            <li class="mr-2" v-for="(file_data, fileIndex) in state?.file" :key="`file-` + fileIndex">
                                                <img v-if="file_data.extension == 'pdf'" src="../../assets/images/activity/pdf.png" alt="img-image" style="width: 70px; height: 70px;" @click="handleOpenFilePreview(`${apiUrl}/${file_data.file}`, `berita_acara`)" />
                                                <img v-else :src="`${apiUrl}/${file_data?.file}`" alt="ing" style="width: 100px; height: 70px;" @click="handleOpenFilePreview(`${apiUrl}/${file_data.file}`, `berita_acara`)" />
                                            </li>
                                        </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Description</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td class="align-top" colspan="4">
                                        {{ state?.description }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <br />
                                <fieldset class="form-group border p-3" v-if="state?.is_internal">
                                    <legend class="w-auto px-2">Internal Man Power</legend>
                                    <table class="table table-striped table-sm" >
                                        <tbody>
                                            <tr>
                                            <th class="text-left">NAMA</th>
                                            <th class="text-left">JABATAN</th>
                                            </tr>
                                            <tr v-for="(entity, entityIndex) in state?.entity_internal" :key="entityIndex">
                                            <td class="text-left">{{ entity?.internal_name }}</td>
                                            <td class="text-left">{{ positionName(entity?.internal_position_id) }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </fieldset>
                                <fieldset class="form-group border p-3" v-if="state?.is_subkon">
                                    <legend class="w-auto px-2">Sub Kontraktor</legend>
                                    <table class="table table-striped table-lg" >
                                    <tbody>
                                        <tr>
                                        <th class="pl-3">NAMA KONTRAKTOR</th>
                                        <th>PIC/EXECUTIVE ENGINEER</th>
                                        <!-- <th>EMAIL</th>
                                        <th>PHONE</th> -->
                                        <th class="text-center">OFFERING</th>
                                        <th class="text-center">NEGOTIATION</th>
                                        <th class="text-center">FINAL NEGOTIATION</th>
                                        <th class="text-center">FILE</th>
                                        </tr>
                                        <tr v-for="(entity, entityIndex) in state?.entity_subkon" :key="entityIndex">
                                        <td class="pl-3" >{{ entity?.subkon_name }}</td>
                                        <td >{{ entity?.subkon_pic }}</td>
                                        <!-- <td >{{ entity?.subkon_pic_email }}</td>
                                        <td >{{ entity?.subkon_pic_phone }}</td> -->
                                        <td class="text-center" >{{ formatCurrency(entity?.subkon_price_offering) }}</td>
                                        <td class="text-center" >{{ formatCurrency(entity?.subkon_price_nego) }}</td>
                                        <td class="text-center" >{{ formatCurrency(entity?.subkon_price_deal) }}</td>
                                        <td class="text-center text-primary" v-if="entity?.subkon_file?.length > 0">
                                            <div v-for="(fileSubkon, subkonIndexFile) in entity?.subkon_file" :key="subkonIndexFile"  class="pb-2">
                                                <i v-if="fileSubkon.extension === 'pdf'" class="fa fa-file-pdf text-primary h4" style="cursor:pointer" @click="handleOpenFilePreview(`${apiUrl}/${fileSubkon.url}`, `${entity?.subkon_name}`)"></i>
                                                <img v-else :src="`${apiUrl}/${fileSubkon?.url}`" alt="" style="width: 70px; height: 70px;" @click="handleOpenFilePreview(`${apiUrl}/${fileSubkon.url}`, `${entity?.subkon_name}`)" />
                                            </div>
                                        </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </fieldset>
                                <b-row>
                                    <b-col md="12">
                                        <ul class="iq-timeline" style="height:100%">
                                            <li v-for="(activity, aIndex) in state?.form_repair_activity" :key="aIndex">
                                            <div class="timeline-dots border-success"></div>
                                            <h6 class="float-left mb-1">{{ activity?.user_name }}</h6>
                                            <br />
                                            <small>{{ moment(activity?.date_of_activity).format('DD MMMM YYYY hh:mm') }}</small>
                                            <div class="d-inline-block w-100">
                                                <b-badge :variant="activity?.activity?.activity_flag === 'running' ? 'primary' : activity?.activity?.activity_flag === 'finish' ? 'success' : 'danger'">{{ activity?.activity?.name ?? '-' }}</b-badge> <br /> <p><strong class="text-muted">Notes:</strong> {{ activity?.notes }}</p>
                                            </div>
                                            <div class="d-inline-block w-100" v-if="activity?.parsed_file?.length > 0">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                <li class="pb-2" v-for="(file, fileIndex) in activity?.parsed_file" :key="fileIndex">
                                                    <img v-if="file.extension == 'pdf'" src="../../assets/images/activity/pdf.png" alt="gallary-image"  style="width: 70px; height: 70px;" @click="handleOpenFilePreview(`${apiUrl}/${file.url}`, `${activity?.user_name}_activity`)" />
                                                    <img v-else :src="`${apiUrl}/${file.url}`" alt="image" style="width: 70px; height: 70px;" @click="handleOpenFilePreview(`${apiUrl}/${file.url}`, `${activity?.user_name}_activity`)" />
                                                </li>
                                                </ul>
                                            </div>
                                            </li>
                                            <li>
                                                <b-button type="button" variant="primary" @click="getSingleServiceAndRepair(state.form_id, 10)" v-if="state?.form_repair_activity?.length > 0">
                                                    Load More
                                                </b-button>
                                            </li>
                                        </ul>
                                    </b-col>
                                </b-row>
                                </b-collapse>
                            </b-card>
                            </div>
                        </b-col>
                    </b-row>
                    </template>
                </b-sidebar>
                <!-- SLIDE END -->
                <!-- SLIDE UPDATE DATA -->
                <b-sidebar id="update_sr" :title="`UPDATE REPAIR & MAINTENANCE - ` + update_jobs_data?.fleet" width="70%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
                    <template #default="{hide}">
                    <form action="#" method="post" @submit="updateJobsService">
                        <b-row class="m-3">
                        <b-col cols="12">
                            <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Detail Information</legend>
                            <table class="table mb-0 table-striped">
                                <tbody>
                                <tr>
                                    <th width="20%">Lokasi Pekerjaan</th>
                                    <td width="5%">:</td>
                                    <td width="25%">
                                    <b-form-input v-model="update_jobs_data.location" name="location" type="text" placeholder="Type Text ..."></b-form-input>
                                    </td>
                                    <th width="20%">Tanggal</th>
                                    <td width="5%">:</td>
                                    <td width="25%">
                                    <b-form-input v-model="update_jobs_data.accident_at" name="accident_at"  type="date"></b-form-input>
                                    </td>
                                </tr>
                                <tr>
                                    <th width="20%">Office / Site</th>
                                    <td width="5%">:</td>
                                    <td width="25%">
                                    <b-form-input v-model="update_jobs_data.site" name="site"  type="text" placeholder="Type Text ..."></b-form-input>
                                    </td>
                                    <th width="20%">PIC Repair</th>
                                    <td width="5%">:</td>
                                    <td width="25%">
                                    <b-form-input v-model="update_jobs_data.pic" name="pic"  type="text" placeholder="Type Text ..."></b-form-input>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </fieldset>
                        <div v-for="(state, index) in update_jobs_data?.form_repair" :key="index">
                            <b-card no-body>
                                <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                <b-button block v-b-toggle="`accordion-` + index" variant="info" class="text-left">
                                    <i class="fa fa-chevron-circle-down"></i> {{ state?.name }} - {{ state?.title }}
                                </b-button>
                                </b-card-header>
                                <b-collapse :id="'accordion-' + index" :visible="false" accordion="my-accordion" role="tabpanel">
                                <fieldset class="form-group border p-3">
                                    <legend class="w-auto px-2 text-primary">{{ state?.name }} - {{ state?.title }} </legend>
                                    <b-form-row>
                                    <b-col>
                                        <b-form-group label="Equipment Name" label-for="equipmentName">
                                            <b-form-input disabled type="text" name="name" placeholder="Type Text ..." required v-model="state.name"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group label="Work Item" label-for="repairTitle">
                                        <b-form-input type="text" name="title" placeholder="Type Text ..." required
                                        v-model="state.title"
                                        ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group label="Type R & M *" label-for="type_repair">
                                        <b-form-select plain v-model="state.task_type_id" name='task_type_id' :options="optionsTaskType" size="lg" required>
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">Select Type R & M</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        </b-form-group>
                                    </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                        <b-col md="2">
                                        <b-form-group label="Department *" label-for="department" >
                                            <b-form-select plain v-model="state.department_id" name='department_id' :options="optionsDepartment" size="lg" required>
                                            <template v-slot:first>
                                                <b-form-select-option :value="null">Select Department</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                        </b-form-group>
                                        </b-col>
                                        <b-col md="2">
                                            <b-form-group label="Section *" label-for="section" >
                                                <b-form-select plain v-model="state.section" name='section' size="lg" required>
                                                <template v-slot:first>
                                                    <b-form-select-option :value="''">Select Section</b-form-select-option>
                                                    <b-form-select-option :value="'DECK'">Deck</b-form-select-option>
                                                    <b-form-select-option :value="'ENGINE'">Engine</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                            </b-form-group>
                                        </b-col>
                                    <b-col>
                                        <b-form-group label="Category *" label-for="category">
                                        <b-form-select plain v-model="state.category_id" name='category_id' :options="optionsCategory" size="lg" required>
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">Select Category</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group label="Classification *" label-for="classification">
                                        <b-form-select plain v-model="state.classification_id" name='classification_id' :options="optionsClassification" @change="handleCountDaysBasedClassification(state)" size="lg" required>
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">Select Classification</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        </b-form-group>
                                    </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                    <b-col>
                                        <b-form-group label="Cause *" label-for="cause" >
                                        <b-form-select plain v-model="state.case_id" name='case_id' :options="optionsCause" size="lg" required>
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">Select Cause</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group label="Kind of Task *" label-for="kind_of_task" >
                                        <b-form-select plain v-model="state.kind_task_id" name='kind_task_id' :options="optionsKindTask" size="lg" required>
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">Select Kind of Task</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group label="Berita Acara" label-for="attactment_file">
                                        <b-form-file plain multiple v-model="state.file" name='file' id="attactment_file"></b-form-file>
                                        <b-form-text class="text-primary" style="cursor:pointer;" v-if="state?.file_existed?.length > 0">
                                            <strong>View Existed File</strong>
                                        </b-form-text>
                                        </b-form-group>
                                    </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                    <b-col>
                                        <b-form-group label="Rencana Mulai *" label-for="mulai">
                                        <b-form-input type="date" name="start_at" id="mulai"  @change="() => {calculateDays(state); handleCountDaysBasedClassification(state)}" required 
                                        v-model="state.start_at"
                                        ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group label="Rencana Selesai *" label-for="selesai">
                                        <b-form-input type="date" name="" id="selesai" @change="calculateDays(state)"  required
                                        v-model="state.estimated_finished_at"
                                        ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group label="Estimasi Hari" label-for="estimasi_hari">
                                        <b-button variant="outline-primary" class="mb-1">
                                            Perkiraan Pekerjaan <b-badge variant="primary" class="ml-2">{{ state?.estimate_total_days }} Hari</b-badge>
                                        </b-button>
                                        </b-form-group>
                                    </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                    <b-col>
                                        <b-form-group label="Description" label-for="description">
                                        <b-form-textarea id="description" rows="3" name="" placeholder="Type Text ..."
                                        v-model="state.description"
                                        ></b-form-textarea>
                                        </b-form-group>
                                    </b-col>
                                    </b-form-row>   
                                </fieldset>
                                </b-collapse>
                            </b-card>
                            </div>
                        </b-col>
                        <b-col md="12" class="text-right">
                            <b-card-text>
                            <small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge>
                            </small>
                            </b-card-text>
                            <b-button type="submit" variant="primary" :disabled="update_jobs_loading">
                            <span
                                v-if="update_jobs_loading"
                                class="spinner-border spinner-border-sm"
                            />
                            <span v-else><i class="fa fa-save"></i> Save</span>
                            </b-button>
                            <b-button type="button" variant="none" class="iq-bg-danger ml-3">
                            <i class="fa fa-close"></i> Reset Form
                            </b-button>
                        </b-col>
                        </b-row>
                    </form>
                    </template>
                </b-sidebar>
                <!-- SLIDE END -->
                </template>
            </iq-card>
            <iq-card v-if="!request_loading">
                <template v-slot:headerTitle>
                <h5 class="card-title text-primary">
                    <strong>PROGRESS REPAIR & MAINTENANCE</strong>
                </h5>
                </template>
                <template v-slot:body>
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th class="text-center">NO</th>
                        <th class="text-left">FLEET&nbsp;NAME</th>
                        <th class="text-center">LOCATION</th>
                        <th class="text-center">TANGGAL</th>
                        <th class="text-center">TOTAL R & M</th>
                        <th class="text-center">CLASSIFICATION</th>
                        <th class="text-center">OFFICE/PIC</th>
                        <th class="text-center">ACTION</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(state, index) in list_table_progress?.data" :key="`progress-` + index">
                        <td class="text-center">{{ index + 1 }}</td>
                        <td class="text-left">{{ state?.fleet }}</td>
                        <td class="text-center">{{ state?.location }}</td>
                        <td class="text-center">{{ moment(state?.accident_at).format('DD MMMM YYYY') }}</td>
                        <td class="text-center">{{ state?.total_repair }}</td>
                        <td class="text-center">
                        <span v-for="(classification, classIndex) in state.classification" :key="classIndex">
                            <b-badge :variant="`${classification.name === 'Minor' ? 'success' : classification.name === 'Medium' ? 'info' : 'danger'} d-block`" 
                            class="mb-1">
                                {{ classification.name }} : {{ classification.total }} Repair
                            </b-badge>
                        </span>
                        </td>
                        <td class="text-center">{{ state?.site }}<br>
                        <small>PIC : <strong>{{ state?.pic }}</strong>
                        </small>
                        </td>
                        <td class="text-center">
                        <b-dropdown size="lg" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                            <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                            </span>
                            </template>
                            <b-dropdown-item href="#" v-b-toggle.add-activity v-if="!readOnly" @click="getListRepairService(state.id, state?.fleet)">
                            <i class="fa fa-comments mr-2"></i>Update Activity
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-toggle.add-job v-if="!readOnly"   @click="() => {getSingleServiceAndRepair(state.id); is_job_data_equipment = true; checkedFleets = state.fleet_id; getComponentStruct()}">
                            <i class="fa fa-plus mr-2"></i>Add Job
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-toggle.detail_sr  @click="() => getSingleServiceAndRepair(state.id)">
                            <i class="fa fa-info-circle mr-2"></i>Detail
                            </b-dropdown-item>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item href="#"  v-if="!readOnly" variant="danger" @click="() => deleteServiceRepair(state)">
                            <i class="fa fa-trash mr-2"></i>Delete R & M
                            </b-dropdown-item>
                        </b-dropdown>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="mt-3" v-if="list_table_progress?.data?.length > 0">
                    <b-pagination
                        v-model="list_table_progress.meta.page"
                        :total-rows="list_table_progress.meta.total_item"
                        :per-page="list_table_progress.meta.per_page"
                        aria-controls="my-table"
                        @change="getListProgressTableServiceAndRepair"
                        align="center"
                        first-number
                    />
                </div>
                <!-- SLIDE REVIEW -->
                <b-sidebar id="add-activity" :title="`UPDATE REPAIR & MAINTENANCE TIME LINE (PROGESS & DELAY FACTOR) - ${fleetLabelName}`" width="100%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
                    <template #default="{hide}">
                    <b-row class="m-3">
                        <b-col md="7">
                        <table class="table table-striped mt-3" v-if="activity_repair_data">
                            <tbody>
                            <tr>
                                <th width="20%">Equipment Name</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">{{ activity_repair_data?.name  }}</td>
                                <th width="20%">Repair Title</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">{{ activity_repair_data?.title  }}</td>
                            </tr>
                            <tr>
                                <th width="20%">Type R & M</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">{{ activity_repair_data?.task_type?.name  }}</td>
                                <th width="20%">Department</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">{{ activity_repair_data?.department?.name }}</td>
                            </tr>
                            <tr>
                            <th width="20%"></th>
                            <td width="5%" class="text-center"></td>
                            <td width="25%"></td>
                            <th width="20%">Section</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">{{ state?.section }}</td>
                            </tr>
                            <tr>
                                <th width="20%">Category</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">{{ activity_repair_data?.category?.name  }}</td>
                                <th width="20%">Classification</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">{{ activity_repair_data?.classification?.name  }} ({{ activity_repair_data?.classification?.interval }} {{ activity_repair_data?.classification?.unit }})</td>
                            </tr>
                            <tr>
                                <th width="20%">Cause</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">{{ activity_repair_data?.case?.name  }}</td>
                                <th width="20%">Kind Task</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">{{ activity_repair_data?.kind_task?.name  }}</td>
                            </tr>
                            <tr>
                                <th width="20%">Rencana Mulai</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">{{ moment(activity_repair_data?.start_at).format('DD MMMM YYYY') }}</td>
                                <th width="20%">Rencana Selesai (Est)</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">{{ moment(new Date(activity_repair_data?.estimated_finished_at)).format('DD MMMM YYYY') }}</td>
                            </tr>
                            <tr>
                                <td width="20%"><b>Estimate Cost</b></td>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">{{ formatCurrency(activity_repair_data?.cost_estimate) }}</td>
                                <th width="20%">Estimasi Hari</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">{{ activity_repair_data?.estimate_total_days }} Hari</td>
                            </tr>
                            <tr>
                                <td width="20%"><b>Cost Real</b></td>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">{{ formatCurrency(activity_repair_data?.cost_real) }}</td>
                                <th width="20%"></th>
                                <td width="5%" class="text-center"></td>
                                <td width="25%"></td>
                            </tr>
                            <tr>
                                <th width="20%">Berita Acara</th>
                                <td width="5%" class="text-center">:</td>
                                <td colspan="4">
                                <ul class="d-flex p-0 m-0">
                                    <li class="mr-2" v-for="(file_data, fileIndex) in activity_repair_data?.file" :key="`file-` + fileIndex">
                                        <img v-if="file_data.extension == 'pdf'" src="../../assets/images/activity/pdf.png" alt="img-image" style="width: 70px; height: 70px;" @click="handleOpenFilePreview(`${apiUrl}/${file_data.file}`, `berita_acara`)" />
                                        <img v-else :src="`${apiUrl}/${file_data?.file}`" alt="ing" style="width: 100px; height: 70px;" @click="handleOpenFilePreview(`${apiUrl}/${file_data.file}`, `berita_acara`)"  />
                                    </li>
                                </ul>
                                </td>
                            </tr>
                            <tr>
                                <th width="20%">Description</th>
                                <td width="5%" class="text-center">:</td>
                                <td class="align-top" colspan="4">
                                {{ activity_repair_data?.description }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="d-flex w-100 align-items-center justify-content-center" v-if="!activity_repair_data">
                            <strong class="text-muted" v-if="activity_repair_loading">LOADING...</strong>
                            <strong class="text-muted" v-else>SELECT REPAIR & MAINTENANCE TERLEBIH DAHULU</strong>
                        </div>

                        <fieldset class="form-group border p-3" v-if="activity_repair_data?.is_internal && activity_form.form_repair_id">
                            <legend class="w-auto px-2">Internal Man Power</legend>
                            <table class="table table-striped table-sm" >
                            <tbody>
                                <tr>
                                <th class="text-left">NAMA</th>
                                <th class="text-left">JABATAN</th>
                                </tr>
                                <tr v-for="(entity, entityIndex) in activity_repair_data?.entity_internal" :key="entityIndex">
                                <td class="text-left">{{ entity?.internal_name }}</td>
                                <td class="text-left">{{ positionName(entity?.internal_position_id) }}</td>
                                </tr>
                            </tbody>
                            </table>
                        </fieldset>
                        <fieldset class="form-group border p-3" v-if="activity_repair_data?.is_subkon && activity_form.form_repair_id">
                            <legend class="w-auto px-2">Sub Kontraktor</legend>
                            <table class="table table-striped table-lg">
                            <tbody>
                                <tr>
                                <th class="pl-3">NAMA KONTRAKTOR</th>
                                <th>PIC/EXECUTIVE ENGINEER</th>
                                <!-- <th>EMAIL</th>
                                <th>PHONE</th> -->
                                <th class="text-center">OFFERING</th>
                                <th class="text-center">NEGOTIATION</th>
                                <th class="text-center">FINAL NEGOTIATION</th>
                                <th class="text-center">FILE</th>
                                </tr>
                                <tr v-for="(entity, entityIndex) in activity_repair_data?.entity_subkon" :key="entityIndex">
                                <td class="pl-3" >{{ entity?.subkon_name }}</td>
                                <td >{{ entity?.subkon_pic }}</td>
                                <!-- <td >{{ entity?.subkon_pic_email }}</td>
                                <td >{{ entity?.subkon_pic_phone }}</td> -->
                                <td class="text-center" >{{ formatCurrency(entity?.subkon_price_offering) }}</td>
                                <td class="text-center" >{{ formatCurrency(entity?.subkon_price_nego) }}</td>
                                <td class="text-center" >{{ formatCurrency(entity?.subkon_price_deal) }}</td>
                                <td class="text-center text-primary" v-if="entity?.subkon_file?.length > 0">
                                    <div v-for="(fileSubkon, subkonIndexFile) in entity?.subkon_file" :key="subkonIndexFile"  class="pb-2">
                                        <i v-if="fileSubkon.extension === 'pdf'" class="fa fa-file-pdf text-primary h4" style="cursor:pointer" @click="handleOpenFilePreview(`${apiUrl}/${fileSubkon.url}`, `${entity?.subkon_name}`)"></i>
                                        <img v-else :src="`${apiUrl}/${fileSubkon?.url}`" alt="img" style="width: 70px; height: 70px;" @click="handleOpenFilePreview(`${apiUrl}/${fileSubkon.url}`, `${entity?.subkon_name}`)" />
                                    </div>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </fieldset>
                        </b-col>
                        <b-col md="5">
                        <tab-content-item :active="true" id="power">
                            <tab-nav :tabs="true">
                            <tab-nav-items :active="true" href="#activity" title="Activity" />
                            <tab-nav-items :active="false" href="#logs" title="Logs" />
                            </tab-nav>
                            <div id="myTabContent" class="tab-content">
                            <tab-content-item :active="true" id="activity">
                                <form action="#" method="post" @submit="onSaveActivity">
                                <b-row>
                                    <b-col md="12" class="mb-5">
                                    <b-form-row>
                                        <b-col>
                                        <b-form-group label="Select Repair & Maintenance *" label-for="repair_maintenance">
                                            <b-form-select plain v-model="activity_form.form_repair_id" :options="list_repair_service" required>
                                            <template v-slot:first>
                                                <b-form-select-option :value="null">Select Repair & Maintenance</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                        </b-form-group>
                                        </b-col>
                                        <b-col>
                                        <b-form-group label="Select Activity *" label-for="activity" v-if="activity_form.form_repair_id">
                                            <b-form-select plain v-model="activity_form.activity_id" name="activity_id" :options="optionsActivity" required>
                                            <template v-slot:first>
                                                <b-form-select-option :value="null">Select Activity</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                        </b-form-group>
                                        </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                        <b-col>
                                        <b-form-group label="Tanggal *" label-for="date" v-if="activity_form.form_repair_id">
                                            <b-form-input v-model="activity_form.date_of_activity" name="date_of_activity" type="date" id="start" :min="minDate" required></b-form-input>
                                        </b-form-group>
                                        </b-col>
                                        <b-col>
                                        <b-form-group label="Berita Acara" label-for="attactment_file" v-if="activity_form.form_repair_id">
                                            <b-form-file plain multiple v-model="activity_form.file" name='file' id="attactment_file"></b-form-file>
                                            </b-form-group>
                                        </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                        <b-col>
                                        <b-form-group label="Notes" label-for="notes" v-if="activity_form.form_repair_id">
                                            <b-form-textarea v-model="activity_form.notes" name="notes" id="description" rows="3" placeholder="Type Text ..."></b-form-textarea>
                                        </b-form-group>
                                        </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                        <b-col>
                                        <b-form-group label="Is Finish Job ?" label-for="internal_man_power" v-if="activity_form.form_repair_id">
                                            <b-form-checkbox
                                            v-model="activity_form.is_finished"
                                            name="check-button"
                                            switch
                                            >
                                            </b-form-checkbox>
                                        </b-form-group>
                                        </b-col>
                                        <b-col v-if="activity_form.form_repair_id">
                                        <b-button type="submit" variant="success" class="pull-right" :disabled="!activity_form.form_repair_id || activity_repair_loading" v-if="activity_repair_data?.status?.alias !== 'finish' && activity_repair_data?.status?.alias !== 'reject'" >
                                            <span
                                                v-if="activity_repair_loading"
                                                class="spinner-border spinner-border-sm"
                                            />
                                            <span v-else><i class="fa fa-save"></i>Submit Data</span>
                                        </b-button>
                                        </b-col>
                                    </b-form-row>
                                    </b-col>
                                    <b-col md="12">
                                        <ul class="iq-timeline" style="height:100%">
                                            <li v-for="(activity, aIndex) in activity_repair_data?.activity" :key="aIndex">
                                            <div class="timeline-dots border-success"></div>
                                            <h6 class="float-left mb-1">{{ activity?.user_name }}</h6>
                                            <br />
                                            <small>{{ moment(activity?.date_of_activity).format('DD MMMM YYYY hh:mm A') }}</small>
                                            <div class="d-inline-block w-100">
                                                <b-badge :variant="activity?.activity?.activity_flag === 'running' ? 'primary' : activity?.activity?.activity_flag === 'finish' ? 'success' : 'danger'">{{ activity?.activity?.name ?? '-' }}</b-badge> <br /> <p><strong class="text-muted">Notes:</strong> {{ activity?.notes }}</p>
                                            </div>
                                            <div class="d-inline-block w-100" v-if="activity?.parsed_file?.length > 0">
                                                <ul class="d-flex p-0 m-0">
                                                <li class="pb-2" v-for="(file, fileIndex) in activity?.parsed_file" :key="fileIndex">
                                                    <img v-if="file.extension == 'pdf'" src="../../assets/images/activity/pdf.png" alt="img-image" style="width: 70px; height: 70px;" @click="handleOpenFilePreview(`${apiUrl}/${file.url}`, `${activity?.user_name}_activity`)" />
                                                    <img v-else :src="`${apiUrl}/${file.url}`" alt="image" style="width: 70px; height: 70px;" @click="handleOpenFilePreview(`${apiUrl}/${file.url}`, `${activity?.user_name}_activity`)" />
                                                </li>
                                                </ul>
                                            </div>
                                            </li>
                                            <b-button class="ml-2" type="button" variant="primary" @click="getSingleRepairData(10)" v-if="activity_repair_data?.activity?.length > 0 && activity_form.form_repair_id">
                                                Load More
                                            </b-button>
                                        </ul>
                                    </b-col>
                                </b-row>
                                </form>
                            </tab-content-item>
                            <tab-content-item :active="false" id="logs">
                                <b-row>
                                <b-col md="12">
                                    <ul class="iq-timeline" style="height:100%">
                                    <li v-for="(log, index) in logs" :key="index">
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">{{ log?.name }}</h6>
                                        <br />
                                        <small>{{ moment(log?.date_log).format('DD MMMM YYYY h:mm') }}</small>
                                        <div class="d-inline-block w-100">
                                        <p>{{ log?.log_description }}</p>
                                        </div>
                                    </li>
                                    </ul>
                                </b-col>
                                </b-row>
                            </tab-content-item>
                            </div>
                        </tab-content-item>
                        </b-col>
                    </b-row>
                    </template>
                </b-sidebar>
                <!-- SLIDE END -->
                </template>
            </iq-card>
        </b-col>
        <b-col lg='12' md="12" sm="12">
            <b-modal id="reject-modal" size="l" title="REJECT REPAIR & MAINTENANCE" cancel-title="Close" hide-footer v-model="showRejectModal">
                <form action="#" method="post" @submit="onRejectService">
                <b-row>
                    <b-col md="12">
                    <b-form-group label="Select Reason Reject" label-for="reject_reason">
                        <b-form-select plain v-model="form_reject.reject_id" required name="activity_id" :options="optionsReject">
                        <template v-slot:first>
                            <b-form-select-option :value="null">Select Reason</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group label="Reason" label-for="reason">
                        <b-form-textarea id="reason" v-model="form_reject.remarks" rows="2" placeholder="Type Text ..."></b-form-textarea>
                    </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    
                    <b-col md="4">
                    <b-button type="button" variant="secondary" block @click="resetReject">
                        Cancel
                    </b-button>
                    </b-col>
                    <b-col md="8">
                    <b-button type="submit" variant="primary" block :disabled="reject_loading">
                        <span
                            v-if="reject_loading"
                            class="spinner-border spinner-border-sm"
                        />
                        <span v-else><i class="fa fa-save"></i> Submit</span>
                    </b-button>
                    </b-col>
                </b-row>
                </form>
            </b-modal>
        </b-col>    
    </b-row>
    
</template>

<script>
import { xray } from '../../config/pluginInit'
import _ from 'lodash'
import {
  fleetsActions,
  crewingActions,
  srActions,
  maintenanceActions
} from '@src/Utils/helper'
import { createPopper } from '@popperjs/core'
import moment from 'moment'
import optionsData from '@src/core/mock/data'

export default {
  name: 'TabRequestAndProgress',
  props: {
    query: {
      type: Object,
      default: () => {
        return {
            fleetCategory: [],
            fleet:null,
            dateRange: null,
            status:null
        }
      },
    },
    readOnly: {
        type: Boolean,
    },
    fleetId:{
        type:Number
    },
    filtering:{
        type:Boolean
    }
  },
  data() {
    return {
      // fleet
      ...optionsData,
      apiUrl: `${process.env.VUE_APP_API_BASE_URL}/sr`,
      limitActivity: 10,
      minDate:'',
      tree: [
        {
          'text': 'Acocommodation & Hull',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Tanks',
              'selected': false
            }
          ]
        },
        {
          'text': 'Deck Equipment & Outfitting',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Mooring Equipment',
              'selected': false
            },
            {
              'text': 'Propeller',
              'selected': false
            },
            {
              'text': 'Winch & Windlass',
              'selected': false
            }
          ]
        },
        {
          'text': 'Electrical Components & Parts',
          'opened': true,
          'selected': false
        },
        {
          'text': 'Engine & Other Machinery',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Gear Box',
              'selected': false
            },
            {
              'text': 'Generator # Auxiliary Engine',
              'selected': true
            },
            {
              'text': 'Generator Emergency',
              'selected': false
            }
          ]
        },
        {
          'text': 'Machinery Equipment',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Main Engine',
              'selected': false
            }
          ]
        },
        {
          'text': 'Fire & Safety Equipment',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Safety Equipment',
              'selected': false
            }
          ]
        },
        {
          'text': 'Navigation & Communication Equipment',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Radio EQ',
              'selected': false
            }
          ]
        },
        {
          'text': 'Pipes & Fitting',
          'opened': true,
          'selected': false
        },
        {
          'text': 'Pumps',
          'opened': true,
          'selected': false
        }
      ],
      fleetLabelName:'',
      fleets: [],
      showInitModal: false,
      showInitModalNext: false,
      isModalAddJob: false,
      nextLoading: false,
      is_vehicle_exist: null,
      loading_fleet: false,
      reject_loading: false,
      checkedFleets: null,
      fleetName: null,
      fleetNameChecked: '',
      optionsFleets:[],
      optionsDepartment: [],
      optionsActivity: [],
      optionsCategory:[],
      optionsCause: [],
      optionsClassification: [],
      optionsStatus: [],
      optionsTaskType: [],
      optionsKindTask:[],
      optionsReject:[],
      optionsVehicleType:[],

      logs:null,
      request_loading:false,

      form_repair_service:{
        loading:false,
        data: {
            vehicle_id: null,
            location: '',
            site: '',
            pic: '',
            type_of_job:'custom',
            created_by: JSON.parse(localStorage.getItem('profile')).id,
            updated_by: null,
            accident_at: null,
            user_id: JSON.parse(localStorage.getItem('profile')).id,
            user_name: JSON.parse(localStorage.getItem('profile')).userDetail.name,
            contents: [
                {
                    maintenance_id:null,
                    maintenance_component_setup_id:null,
                    maintenance_component_setup_name:'',
                    status_id:null,
                    name:'',
                    title:'',
                    classification_id:null,
                    category_id:null,
                    department_id:1,
                    section:'',
                    case_id:null,
                    task_type_id:null,
                    kind_task_id:null,
                    start_at:null,
                    actual_finished_at:null,
                    description:null,
                    estimate_total_days:null,
                    file:[],
                }
            ]
        }
      },
      fleet_params: {
        page: 1,
        showAll: true,
        search: ''
      },
      list_table_requested:{
        data:[],
        meta:null
      },
      list_table_progress:{
        data:[],
        meta:null
      },

      repair_data:null,
      review_loading:false,

      job_data:{
        accident_at: null,
        location: '',
        site: '',
        pic: '',
        type_of_job:'custom',
        fleet:'',
        fleet_id:null,
        status:'',
        existed_data:[],
        form_repair: [
            {
                maintenance_id:null,
                maintenance_component_setup_id:null,
                maintenance_component_setup_name:'',
                status_id:null,
                name:'',
                title:'',
                classification_id:null,
                category_id:null,
                department_id:1,
                section:'',
                case_id:null,
                task_type_id:null,
                kind_task_id:null,
                start_at:null,
                actual_finished_at:null,
                description:null,
                estimate_total_days:null,
                file:[],
            }
        ]
      },

      job_loading:false,

      update_jobs_data:{
        accident_at: null,
        location: '',
        site: '',
        pic: '',
        type_of_job:'custom',
        fleet:'',
        fleet_id:null,
        status:'',
        form_repair: []
      },
      update_jobs_loading:null,

      approve_sr_loading:false,
      approve_sr_data:null,

      detail_sr_data:null,

      logs_data:null,
      activity_repair_data:null,
      activity_repair_loading:null,
      activity_form:{
        id:null,
        form_repair_id:null,
        activity_id:null,
        date_of_activity:null,
        notes:'',
        is_finished:false,
        file:[]
      },
      list_repair_service:null,

      showRejectModal:null,
      selected_reject_id:null,
      form_reject: {
        reject_id:null,
        reject_by: JSON.parse(localStorage.getItem('profile')).id,
        remarks:'',
      },

      is_job_data_equipment:false,
      all_equipment_selected:false,
      is_form_created: false,
      vehicleTypeId: null,
      treeInitMaintenance: [],
      componentIdSelected: null,
      list_equipment:[],
      selected_components: [],
      formInitMaintenance: {
        jobCode: '',
        jobClassId: null,
        jobName: '',
        componentId: null,
      },
    }
  },
  watch: {
    'checkedFleets': {
      handler(value) {
        if (value) {
          let nameFleet = this.fleets.find((f) => this.checkedFleets === f.id)
          this.fleetNameChecked = nameFleet.name
          this.vehicleTypeId = nameFleet.vehicleType.id
        }
      },
    },  
    'showInitModal': {
      async handler(value) {
        if (value === false) {
          this.fleet_params.search = ''
        }else { 
         await this.fetchFleets()
        }
      },
      deep: true,
    }, 
    'activity_form.form_repair_id': {
      async handler() {
        this.activity_repair_data = null
        this.activity_repair_loading = true

        await this.getSingleRepairData()

        this.activity_repair_loading = false
      },
      deep: true,
    },
    'filtering': {
      handler(n, o) {
        this.getListTableServiceAndRepair()
        this.getListProgressTableServiceAndRepair()
      },
      immediate: true,
    },
    'form_repair_service.data.type_of_job':{
        handler(value) {
            if (value) {
                this.all_equipment_selected = false
                this.is_form_created = false
                this.componentIdSelected = null
                this.list_equipment = []
                this.selected_components = []
                this.form_repair_service = {
                    loading:false,
                    data: {
                        ...this.form_repair_service.data,
                        created_by: JSON.parse(localStorage.getItem('profile')).id,
                        user_id: JSON.parse(localStorage.getItem('profile')).id,
                        user_name: JSON.parse(localStorage.getItem('profile')).userDetail.name,
                        contents: [
                            {
                                maintenance_id:null,
                                maintenance_component_setup_id:null,
                                maintenance_component_setup_name:'',
                                status_id:null,
                                name:'',
                                title:'',
                                classification_id:null,
                                category_id:null,
                                department_id:1,
                                section:'',
                                case_id:null,
                                task_type_id:null,
                                kind_task_id:null,
                                start_at:null,
                                actual_finished_at:null,
                                description:null,
                                estimate_total_days:null,
                                file:[],
                            }
                        ]
                    }
                }
            }
        }
    },
    'job_data.type_of_job':{
        handler(value) {
            if (value) {
                this.all_equipment_selected = false
                this.is_form_created = false
                this.componentIdSelected = null
                this.list_equipment = []
                this.selected_components = []
                this.job_data = {
                    ...this.job_data,
                    existed_data:[],
                    form_repair: [
                        {
                            maintenance_id:null,
                            maintenance_component_setup_id:null,
                            maintenance_component_setup_name:'',
                            status_id:null,
                            name:'',
                            title:'',
                            classification_id:null,
                            category_id:null,
                            department_id:1,
                            section:'',
                            case_id:null,
                            task_type_id:null,
                            kind_task_id:null,
                            start_at:null,
                            actual_finished_at:null,
                            description:null,
                            estimate_total_days:null,
                            file:[],
                        }
                    ]
                }
            }
        }
    },
    'all_equipment_selected':{
        handler(value){
            if(value === true){
                this.list_equipment = this.list_equipment.map(val => {
                    let isExist = this.selected_components.find(obj => obj.id === val.id)
                    if(!isExist){
                        this.selected_components.push(val)
                    }
                    return {
                        ...val,
                        is_selected: true
                    }
                })
            } else {
                this.list_equipment = this.list_equipment.map(val => {
                    this.removeSelectedComponent(this.selected_components, val.id)
                    return {
                        ...val,
                        is_selected: false
                    }
                })
            }
        }
    }
  },
 
  created() {
    this.fetchFleets = _.debounce(this.fetchFleets, 100)
    this.getListTableServiceAndRepair = _.debounce(this.getListTableServiceAndRepair, 500)
    this.getListProgressTableServiceAndRepair = _.debounce(this.getListProgressTableServiceAndRepair, 500)
  },
  async mounted() {
    xray.index()

    await this.getListTableServiceAndRepair()
    await this.getListProgressTableServiceAndRepair()
    await this.getListVehicleType()
    await this.fetchMasterRejectReason()

    await this.fetchFleets()
    await this.getMasterActivity()
    await this.getMasterCategory()
    await this.getMasterCause()
    await this.getMasterClassification()
    await this.getMasterKindTask()
    await this.getMasterStatus()
    await this.getMasterTaskType()
    await this.getMasterDepartment()
  },
  methods: {
    ...fleetsActions,
    ...srActions,
    ...crewingActions,
    ...maintenanceActions,
    permissionDelete(permitted){
        let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
        .find(obj => obj.menu === 'Apps').child
        .find(obj => obj.menu === 'Technical').child
        .find(obj => obj.menu === 'Service Repair').approval

        if(hasAccess && permitted) {
            return true
        } else {
            return false
        }
    },
    async onRequestDeleteServiceRepair(srData){
        this.$swal({
            title: `Are you sure want to request to delete ${srData?.fleet} R&M?`,
            text: "Yes, i'm sure.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, request it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
            const res = await this.requestDeleteSr({id:srData.id})
            if(res.status === false || res.status === 400 || res.status === 500){
                this.$swal(
                    'Failed!',
                    'Something went wrong.',
                    'error'
                )
                return 
            } 
                await this.getListTableServiceAndRepair()
                await this.getListProgressTableServiceAndRepair()
                this.$swal(
                    'Success!',
                    'Success delete r&m.',
                    'success'
                )
            }
        })
    },
    async deleteServiceRepair(srData){
        this.$swal({
            title: `Are you sure want to delete ${srData?.fleet} R&M?`,
            text: "Yes, i'm sure.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
            const res = await this.deleteService({id:srData.id})
            if(res.status === false || res.status === 400 || res.status === 500){
                this.$swal(
                    'Failed!',
                    'Something went wrong.',
                    'error'
                )
                return 
            } 
                await this.getListTableServiceAndRepair()
                await this.getListProgressTableServiceAndRepair()
                this.$swal(
                    'Success!',
                    'Success delete r&m.',
                    'success'
                )
            }
        })
    },
    async onDeleteRepair(repairData){
        this.$swal({
            title: `Are you sure want to delete ${repairData?.name} Repair Job?`,
            text: "Yes, i'm sure.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
            const res = await this.deleteRepair({id:repairData.id})
            if(res.status === false || res.status === 400 || res.status === 500){
                this.$swal(
                    'Failed!',
                    'Something went wrong.',
                    'error'
                )
                return 
            } 
                await this.getListTableServiceAndRepair()
                await this.getListProgressTableServiceAndRepair()
                this.$swal(
                    'Success!',
                    'Success delete r&m.',
                    'success'
                )
            }
        })
    },
    // -------------------------------- START OF SET REPAIR & SERVICE ------------------------------
    async nextAfterSelectVehicle(){
      if(!this.checkedFleets) return  this.$swal(`Oops!`, `Pilih kapal terlebih dahulu.`, 'warning')

      await this.onCheckVehicleExist()
    },
    async onCheckVehicleExist(){
      this.nextLoading = true
      const res = await this.checkVehicleExist({id: this.checkedFleets})

      if(res?.status === 'error'){
        this.nextLoading = false
        return 
      } else {
        this.is_vehicle_exist = res.data.vehicle_exist

        if(res.data.data.vehicle_exist){
          this.showInitModal = false
          this.isModalAddJob = true
          this.is_job_data_equipment = true

          await this.getSingleServiceAndRepair(res.data.data.form_id)
          
          this.getComponentStruct()
        } else {
          this.showInitModal = false
          this.showInitModalNext = true
          this.is_job_data_equipment = false
          this.getComponentStruct()
        }
        this.nextLoading = false
      }
      this.nextLoading = false
    },
    selectedComponents (component){
        if(component.is_selected === true){
            let isDataExist = this.selected_components.find(obj => obj.id === component.id)
            if(!isDataExist)this.selected_components.push(component)
        } else if(component.is_selected === false) {
            this.removeSelectedComponent(this.selected_components, component.id)
        }
    },
    changeStatusIsSelected(componentId){
        this.list_equipment = this.list_equipment.map(val => {
            if(val.id === componentId){
                val.is_selected = false
            }
            return val
        })
    },
    removeSelectedComponent(arr, idToRemove) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].id === idToRemove) {
                arr.splice(i, 1);
            break; // Stop the loop after the item is removed
            }
        }
    },
    createEquipmentForm(){
        this.is_form_created = false
        let tempArr = []

        if(this.selected_components <= 0) return this.$swal(
            `Select equipment first!`,
            'Equipment is required at least 1 equipment to create form.',
            'error'
        )

        this.selected_components.map(val => {
            tempArr.push({
                maintenance_component_setup_id: val.maintenanceMaster.id,
                maintenance_component_setup_name: val.maintenanceMaster.equipmentName,
                maintenance_id:null,
                status_id:null,
                name:val.maintenanceMaster.equipmentName,
                title:'',
                classification_id:null,
                department_id:1,
                section:'',
                category_id:null,
                case_id:null,
                task_type_id:null,
                kind_task_id:null,
                start_at:null,
                actual_finished_at:null,
                description:null,
                estimate_total_days:null,
                file:[]
            })

        })

        if(this.is_job_data_equipment === true){
            this.job_data.form_repair = tempArr
        } else {
            this.form_repair_service.data.contents = tempArr
        }

        console.log(this.form_repair_service.data)
         
        this.is_form_created = true
    },
    async getComponentStruct () {
      this.treeInitMaintenance = []
      const paramsQ = {
        vehicleTypeId: this.vehicleTypeId ?? 1
      }

      const res = await this.getComponent(paramsQ)

      if (res.status === 'success') {
        if (res.data.maintenanceComponents && res.data.maintenanceComponents.length > 0) {
          const dataTemp = res.data.maintenanceComponents
          dataTemp.forEach((s) => this.treeInitMaintenance.push(this.putStructureObject(s)))
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    putStructureObject (object) {
      let children = []

      if (object.child.length > 0) {
        object.child.forEach((child) =>
          children.push(this.putStructureObject(child))
        )
      }

      if (this.tree.findIndex(val => val.value === object.component.id) === -1) {
        // this.tree.push({id: object.component.id, value: object.component.id, text: object.component.componentName})
      }

      let selected = this.maintenanceDetailData && this.maintenanceDetailData.component ? (this.maintenanceDetailData.component.id === object.component.id) : false
      let disabled = this.maintenanceDetailData

      return {
        id: object.component.id,
        text: object.component.componentName,
        disabled,
        opened: true,
        selected,
        children
      }
    },
    itemClickInitComponent (node) {
      if (node && node.model && node.model.id) {
        this.componentIdSelected = node.model.id
        this.componentSetupCheck()
      }
    },
    async componentSetupCheck () {
      let paramsQ = {
        vehicleIds: [],
      }

      if (this.checkedFleets) {
        paramsQ.vehicleIds.push(this.checkedFleets)
        paramsQ.vehicleIds = paramsQ.vehicleIds.toString()
      }

      const dataNeed = {
        id: this.componentIdSelected,
        params: paramsQ
      }

      const res = await this.checkHasComponentSetup(dataNeed)

      if (res.status === 'success') {
        if (res.data.data && res.data.data.length > 0) {
          if (this.checkedFleets) {
            let tempArr = []
            res.data.data.forEach(el => {
                if (this.checkedFleets === el.vehicle.id && el.componentSetup.length > 0) {
                  el.componentSetup.forEach(componentSetup => {
                    tempArr.push({
                        ...componentSetup,
                        is_selected:false
                    })
                  })
                }
            })
            this.list_equipment = tempArr
          }

          this.list_equipment = this.list_equipment.map(obj => {
            let isDataExist = this.selected_components.find(component => component.id === obj.id)

            if(isDataExist){
                obj.is_selected = true
            } else {
                obj.is_selected = false
            }

            return obj
          })
        }
      } else {
        this.$swal(
          `Oops!`,
          res?.data?.message,
          'error'
        )
      }
    },

    async setServiceAndRepair(e){
        e.preventDefault()
        let method = 'createServiceAndRepair'
        this.form_repair_service.loading = true

        let formData = new FormData()
        formData.append('accident_at', this.form_repair_service.data.accident_at)
        formData.append('created_by', this.form_repair_service.data.created_by)
        formData.append('location', this.form_repair_service.data.location)
        formData.append('pic', this.form_repair_service.data.pic)
        formData.append('site', this.form_repair_service.data.site)
        formData.append('type_of_job', this.form_repair_service.data.type_of_job)
        formData.append('updated_by', this.form_repair_service.data.updated_by)
        formData.append('user_id', this.form_repair_service.data.user_id)
        formData.append('user_name', this.form_repair_service.data.user_name)
        formData.append('vehicle_id', this.checkedFleets)

        this.form_repair_service.data.contents.forEach((item, index) => {
            item.file.forEach((file, fileIndex) => {
                formData.append(`file[${index}]`, file);
            });
   
            for (const property in item) {
                formData.append(
                    `contents[${index}][${property}]`,
                    item[property]
                )
            }
        })

        const res = await this[method](formData)

        if(res.status === 'success'){
            await this.getListTableServiceAndRepair()
            await this.getListProgressTableServiceAndRepair()
            
            this.showInitModalNext = false
            this.form_repair_service.loading = false
            this.checkedFleets = null
            this.vehicleTypeId = null
            this.resetFormAddServiceAndRepair()
        
            this.$swal(`Berhasil!`, `Create Service & Repair Berhasil.`, 'success')
            
        } else {
            this.$swal(`Oops!`, `${res.data.message}`, 'error')
            this.form_repair_service.loading = false
        }
    },

   // -------------------------------- END OF SET REPAIR & SERVICE ------------------------------

    changeStatusRepair(state, value){
      state.status_repair = value
    },
    closeSidebar(id) {
      this.$root.$emit('bv::toggle::collapse', `${id}`)
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },
    departmentName(value){
      let department_name = this.optionsDepartment.find(obj => value === obj.value)

      return department_name?.text ?? "N/A"
    },
    positionName(value){
      let position_name = this.optionsJabatan.find(obj => Number(value) === Number(obj.value))

      return position_name?.text ?? "N/A"
    },

    handleOpenFilePreview: function (url, name) {
        let extension = this.getFileExtension(url)
        console.log(extension)
        console.log(url)
        saveAs(url, name + extension)
    },
    getFileExtension(url){
        const match = url.match(/\.([a-zA-Z0-9]+)(\?|$)/);
        return match ? `.${match[1]}` : null;
    },
    calculateDays(state) {
        if (state.start_at && state.estimated_finished_at) {
            const start = moment(state.start_at);
            const end = moment(state.estimated_finished_at);
            const diff = end.diff(start, 'days');
            state.estimate_total_days = diff + 1;
        } else {
            state.estimate_total_days = 0;
        }
    },
    handleCountDaysBasedClassification(state) {
        if (state.start_at && state.classification_id) {
           let days = this.optionsClassification.find(obj => state.classification_id === obj.value)

            const momentDate = moment(state.start_at, 'YYYY-MM-DD');
            const result = momentDate.add(days.interval - 1 , 'days');
            state.estimated_finished_at = result.format('YYYY-MM-DD');

            const start = moment(state.start_at);
            const end = moment(state.estimated_finished_at);
            const diff = end.diff(start, 'days');
            state.estimate_total_days = diff + 1;
        } else {
            state.estimated_finished_at = null
        }
    },
    async getListTableServiceAndRepair(page){
        this.request_loading = true

        let params = {
            start_date: moment(this.query?.dateRange?.[0]).format('YYYY-MM-DD'),
            end_date: moment(this.query?.dateRange?.[1]).format('YYYY-MM-DD'),
            fleet_id: this.readOnly ? this.fleetId : this.query?.fleet?.value ?? null,
            fleet_category: this.query?.fleetCategory?.length ? this.query?.fleetCategory?.map(val => val?.value) : [],
            status: this.query?.status,
            page: page || 1,
        }

        if(!params.fleet_id) delete params.fleet_id
        if(params?.fleet_category?.length <= 0) delete params.fleet_category
        if(!params?.status) delete params.status

        let res = await this.fetchListTableServiceAndRepair(params)

        if (res.status === "success") {
            this.list_table_requested.data = res?.data?.data
            this.list_table_requested.meta = res.data.meta
            this.request_loading = false
        } else {
            this.list_table_requested = null
            this.request_loading = false
        }
        this.request_loading = false
    }, 

    async getListProgressTableServiceAndRepair(page){
        this.request_loading = true

        let params = {
            start_date: moment(this?.query?.dateRange?.[0]).format('YYYY-MM-DD'),
            end_date: moment(this?.query?.dateRange?.[1]).format('YYYY-MM-DD'),
            fleet_id: this.readOnly ? this.fleetId : this.query?.fleet?.value ?? null,
            fleet_category: this.query?.fleetCategory?.length ? this.query?.fleetCategory?.map(val => val?.value) : [],
            page: page || 1,
        }

        if(!params.fleet_id) delete params.fleet_id
        if(params?.fleet_category?.length <= 0) delete params?.fleet_category

        let res = await this.fetchListProgressTableServiceAndRepair(params)

        if (res.status === "success") {
            this.list_table_progress.data = res.data.data
            this.list_table_progress.meta = res.data.meta
            this.request_loading = false
        } else {
            this.list_table_progress = null
            this.request_loading = false
        }
    }, 

    async fetchFleets() {
      this.loading_fleet = true
      let params = this.$options.filters.cleanObject(this.fleet_params)
      let res = await this.getFleets(params)
      if (!res.status) {
        this.loading_fleet = false
        this.fleets = []
        this.optionsFleets = []
      } else {
        this.loading_fleet = false
        this.fleets = res.data
        res.data.map(val => {
          this.optionsFleets.push({
            text:val.name,
            value:val.id,
          })
        })
      }
    },
    withPopper(dropdownList, component, {
      width
    }) {
      dropdownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [{
          name: 'offset',
          options: {
            offset: [0, -1]
          }
        }, {
          name: 'toggleClass',
          enabled: true,
          phase: 'write',
          fn({
            state
          }) {
            component.$el.classList.toggle('drop-up', state.placement === 'top')
          }
        }]
      })
      return () => popper.destroy()
    },
    itemClick(node) {
      console.log(node.model.text + ' clicked !')
    },
    customItemClick: function(node, item, e) {
      e.stopPropagation()
      var index = node.parentItem.indexOf(item)
      node.parentItem.splice(index, 1)
    },
    customItemClickWithCtrl: function() {
      console.log('click + ctrl')
    },

    async getMasterActivity() {
      let res = await this.fetchMasterActivity()
      if (res.status === 'error') {
        this.optionsActivity = []
      } else {
        res.data.map(val => {
            this.optionsActivity.push({
                text:val.name,
                value:val.id
            })
        })
      }
    },
    async getListVehicleType() {
      let res = await this.fetchListVehicleType()
      if (res.status === 'error') {
        this.optionsVehicleType = []
      } else {
        res.data.data.map(val => {
            this.optionsVehicleType.push({
                text:val.name,
                value:val.id
            })
        })
      }
    },
    async getMasterCategory() {
      let res = await this.fetchMasterCategory()
      if (res.status === 'error') {
        this.optionsCategory = []
      } else {
        res.data.map(val => {
            this.optionsCategory.push({
                text:val.name,
                value:val.id
            })
        })
      }
    },
    async fetchMasterRejectReason() {
      let res = await this.getMasterRejectReason()
      if (res.status === 'error') {
        this.optionsCategory = []
      } else {
        res.data.map(val => {
            this.optionsReject.push({
                text:val.name,
                value:val.id
            })
        })
      }
    },
    async getMasterCause() {
      let res = await this.fetchMasterCause()
      if (res.status === 'error') {
        this.optionsCause = []
      } else {
        res.data.map(val => {
            this.optionsCause.push({
                text:val.name,
                value:val.id
            })
        })
      }
    },
    async getMasterClassification() {
      let res = await this.fetchMasterClassification()
      if (res.status === 'error') {
        this.optionsClassification = []
      } else {
        res.data.map(val => {
            this.optionsClassification.push({
                text:`${val.name} (${val.interval} ${val.unit})`,
                value:val.id,
                interval:val.interval,
            })
        })
      }
    },
    async getMasterKindTask() {
      let res = await this.fetchMasterKindTask()
      if (res.status === 'error') {
        this.optionsKindTask = []
      } else {
        res.data.map(val => {
            this.optionsKindTask.push({
                text:val.name,
                value:val.id
            })
        })
      }
    },
    async getMasterStatus() {
      let res = await this.fetchMasterStatus()
      if (res.status === 'error') {
        this.optionsStatus = []
      } else {
        res.data.map(val => {
            this.optionsStatus.push({
                text:val.name,
                value:val.id
            })
        })
      }
    },
    async getMasterTaskType() {
      let res = await this.fetchMasterTaskType()
      if (res.status === 'error') {
        this.optionsTaskType = []
      } else {
        res.data.map(val => {
            this.optionsTaskType.push({
                text:val.name,
                value:val.id
            })
        })
      }
    },
    async getMasterDepartment() {
      this.optionsDepartment = []
      let res = await this.fetchListDepartment()
      if (!res.data.status) {
        this.optionsDepartment = []
      } else {
        res.data.data.map(val => {
            this.optionsDepartment.push({
                text:val.name,
                value:val.id,
                alias:val.alias
            })
        })
      }
    }, 
    async getLogs(id) {
      let res = await this.fetchLogs(id)
      this.logs = null
      if (res?.status === 'error') {
        this.logs = null
      } else {
        this.logs = res.data
      }
    },

    async getSingleServiceAndRepair(id, limited = 0){
        if(id){
            let limit = 10
            limit += limited
            let params = {
                limit: limit
            }
            let res = await this.fetchSingleServiceAndRepair({id, params})

            if (res.status === 'error') {
                this.repair_data = null
                this.job_data = null
                this.update_jobs_data = null
            } else {
                let tempData = {
                  id:res.data.id,
                  created_at:moment(res.data.created_at).format('YYYY-MM-DD'),
                  accident_at:moment(res.data.accident_at).format('YYYY-MM-DD'),
                  location:res.data.location,
                  pic:res.data.pic,
                  site:res.data.site,
                  status:res.data.status,
                  fleet:res.data.vehicle.name,
                  fleet_id:res.data.vehicle.id,
                  type_of_job:res?.data?.type_of_job,
                  form_repair:[]
                }

                let tempJobData = []
                let tempUpdateJobData = []
                let tempDetailData = []

                res.data.form_repair.forEach((repair, repairIndex) => {
                  let entity_internal = []
                  let entity_subkon = []

                  tempJobData.push(repair)
                  tempUpdateJobData.push({
                    ...repair,
                    start_at: moment(repair.start_at).format('YYYY-MM-DD'),
                    estimated_finished_at: moment(repair.estimated_finished_at).format('YYYY-MM-DD'),
                    file:[],
                    file_existed:repair.file
                  })
                  repair.entity.forEach(val => {
                    if(val.entity_type === 'internal'){
                        entity_internal.push({
                          entity_type:val.entity_type,
                          internal_name: val.internal_name,
                          internal_position_id: val.internal_position_id,
                        })
                      } else {
                        entity_subkon.push({
                          subkon_name:val.subkon_name,
                          subkon_pic:val.subkon_pic,
                          subkon_pic_phone:val.subkon_pic_phone,
                          subkon_pic_email:val.subkon_pic_email,
                          subkon_price_offering:val.subkon_price_offering,
                          subkon_price_nego:val.subkon_price_nego,
                          subkon_price_deal:val.subkon_price_deal,
                          subkon_notes:val.subkon_notes,
                          subkon_file:JSON.parse(val.subkon_file),
                        })
                      }
                  })
                  tempDetailData.push({
                    ...repair,
                    entity_internal: entity_internal,
                    entity_subkon:entity_subkon,
                  })
                  tempData.form_repair.push({
                    ...repair,
                    repair_form_id:repair.id,
                    cost_estimate:repair.cost_estimate,
                    is_internal:false,
                    is_subkon:false,
                    review_internal:[
                      {
                        repair_form_id:repair.id,
                        user_id:JSON.parse(localStorage.getItem('profile')).id,
                        internal_name:'',
                        internal_position_id:'',
                        subkon_name:'',
                        subkon_pic:'',
                        subkon_pic_phone:'',
                        subkon_pic_email:'',
                        subkon_price_offering:'',
                        subkon_price_nego:'',
                        subkon_price_deal:'',
                        subkon_notes:'',
                        subkon_file:[],
                      }
                    ],
                    review_subkon:[
                      {
                        repair_form_id:repair.id,
                        entity_type:'',
                        user_id:JSON.parse(localStorage.getItem('profile')).id,
                        internal_name:'',
                        internal_position_id:'',
                        subkon_name:'',
                        subkon_pic:'',
                        subkon_pic_phone:'',
                        subkon_pic_email:'',
                        subkon_price_offering:'',
                        subkon_price_nego:'',
                        subkon_price_deal:'',
                        subkon_notes:'',
                        subkon_file:[],
                      }
                    ]
                  })
                })
                
                this.repair_data = tempData

                tempDetailData = tempDetailData.map(detail => {
                    if(detail.form_repair_activity.length > 0){
                        detail.form_repair_activity = detail.form_repair_activity.map(val => {
                            let file = JSON.parse(val.file)

                            if(file.length > 0){
                                file = file.map(obj => {
                                const fileExtension = obj.url.split('.').pop().toLowerCase();
                                obj.extension = fileExtension

                                return obj
                            })
                            }

                            val.parsed_file = file
                            return val
                        })
                    }

                    return detail
                })

                this.job_data = {
                  ...tempData,
                  existed_data: tempDetailData,
                  form_repair: [
                    {
                        maintenance_id:null,
                        maintenance_component_setup_id:null,
                        maintenance_component_setup_name:'',
                        status_id:null,
                        name:'',
                        title:'',
                        classification_id:null,
                        category_id:null,
                        department_id:1,
                        section:'',
                        case_id:null,
                        task_type_id:null,
                        kind_task_id:null,
                        start_at:null,
                        actual_finished_at:null,
                        description:null,
                        estimate_total_days:null,
                        file:[],
                      }
                  ]
                }
         

                this.update_jobs_data = {
                  ...tempData,
                  form_repair: tempUpdateJobData
                }
                
                this.detail_sr_data = {
                  ...tempData,
                  form_repair: tempDetailData
                }

                if(this.job_data.existed_data.length > 0){
                    this.job_data.existed_data = this.job_data.existed_data.map(val => {
                        if(val.file.length > 0){
                            val.file.map(obj => {
                                const fileExtension = obj.file.split('.').pop().toLowerCase();
                                obj.extension = fileExtension

                                return obj
                            })
                        }
                        return val
                    })
                }

                if(this.detail_sr_data.form_repair.length > 0){
                    this.detail_sr_data.form_repair = this.detail_sr_data.form_repair.map(val => {
                        if(val.file.length > 0){
                            val.file.map(obj => {
                                const fileExtension = obj.file.split('.').pop().toLowerCase();
                                obj.extension = fileExtension

                                return obj
                            })
                        }
                        return val
                    })
                }
            }
        }
    },

    async getSingleUpdatedServiceAndRepair(id){
      if(id){
            let params = {
              status:1
            }
            let res = await this.fetchSingleServiceAndRepair({id, params})
            if (res.status === 'error') {

                this.update_jobs_data = null
            } else {
                let tempData = {
                  id:res.data.id,
                  created_at:moment(res.data.created_at).format('YYYY-MM-DD'),
                  accident_at:moment(res.data.accident_at).format('YYYY-MM-DD'),
                  location:res.data.location,
                  pic:res.data.pic,
                  site:res.data.site,
                  status:res.data.status,
                  fleet:res.data.vehicle.name,
                  fleet_id:res.data.vehicle.id,
                  type_of_job:res?.data?.type_of_job,
                  form_repair:[]
                }

                let tempUpdateJobData = []

                res.data.form_repair.forEach((repair, repairIndex) => {
                  tempUpdateJobData.push({
                    ...repair,
                    start_at: moment(repair.start_at).format('YYYY-MM-DD'),
                    estimated_finished_at: moment(repair.estimated_finished_at).format('YYYY-MM-DD'),
                    file:[],
                    file_existed:repair.file
                  })
                })
            
                this.update_jobs_data = {
                  ...tempData,
                  form_repair: tempUpdateJobData
                }
            }
        }
    },

    async getReviewData(id){
      if(id){
            let params = {
              status:1
            }
            let res = await this.fetchSingleServiceAndRepair({id, params})
            if (res.status === 'error') {
                this.repair_data = null
            } else {
                let tempData = {
                  id:res.data.id,
                  created_at:moment(res.data.created_at).format('YYYY-MM-DD'),
                  accident_at:moment(res.data.accident_at).format('YYYY-MM-DD'),
                  location:res.data.location,
                  pic:res.data.pic,
                  site:res.data.site,
                  status:res.data.status,
                  fleet:res.data.vehicle.name,
                  fleet_id:res.data.vehicle.id,
                  type_of_job:res?.data?.type_of_job,
                  form_repair:[]
                }
                res.data.form_repair.forEach((repair, repairIndex) => {
                  tempData.form_repair.push({
                    ...repair,
                    repair_form_id:repair.id,
                    cost_estimate:repair.cost_estimate,
                    is_internal:false,
                    is_subkon:false,
                    review_internal:[
                      {
                        repair_form_id:repair.id,
                        user_id:JSON.parse(localStorage.getItem('profile')).id,
                        internal_name:'',
                        internal_position_id:'',
                        subkon_name:'',
                        subkon_pic:'',
                        subkon_pic_phone:'',
                        subkon_pic_email:'',
                        subkon_price_offering:'',
                        subkon_price_nego:'',
                        subkon_price_deal:'',
                        subkon_notes:'',
                        subkon_file:[],
                      }
                    ],
                    review_subkon:[
                      {
                        repair_form_id:repair.id,
                        entity_type:'',
                        user_id:JSON.parse(localStorage.getItem('profile')).id,
                        internal_name:'',
                        internal_position_id:'',
                        subkon_name:'',
                        subkon_pic:'',
                        subkon_pic_phone:'',
                        subkon_pic_email:'',
                        subkon_price_offering:'',
                        subkon_price_nego:'',
                        subkon_price_deal:'',
                        subkon_notes:'',
                        subkon_file:[],
                      }
                    ]
                  })
                })
                
                this.repair_data = tempData


                if(this.repair_data.form_repair.length > 0){
                    this.repair_data.form_repair = this.repair_data.form_repair.map(val => {
                        if(val.file.length > 0){
                            val.file.map(obj => {
                                const fileExtension = obj.file.split('.').pop().toLowerCase();
                                obj.extension = fileExtension

                                return obj
                            })
                        }
                        return val
                    })
                }

                console.log( this.repair_data, "REPAIR DATA")
            }
        }
    },

    // APPROVE
    async getSingleApproveRepair(id){
      if(id){
            let params = {
              status:2
            }

            let res = await this.fetchSingleServiceAndRepair({id, params})
            if (res.status === 'error') {
                this.approve_sr_data = null
            } else {
                let tempData = {
                  id:res.data.id,
                  created_at:moment(res.data.created_at).format('YYYY-MM-DD'),
                  accident_at:moment(res.data.accident_at).format('YYYY-MM-DD'),
                  location:res.data.location,
                  pic:res.data.pic,
                  site:res.data.site,
                  status:res.data.status,
                  fleet:res.data.vehicle.name,
                  fleet_id:res.data.vehicle.id,
                  type_of_job:res?.data?.type_of_job,
                  form_repair:[]
                }

                let tempApproveData = []

                res.data.form_repair.forEach((repair, repairIndex) => {
                  let entity_internal = []
                  let entity_subkon = []

                  repair.entity.forEach(val => {
                    if(val.entity_type === 'internal'){
                        entity_internal.push({
                          entity_type:val.entity_type,
                          internal_name: val.internal_name,
                          internal_position_id: val.internal_position_id,
                        })
                      } else {
                        entity_subkon.push({
                          subkon_name:val.subkon_name,
                          subkon_pic:val.subkon_pic,
                          subkon_pic_phone:val.subkon_pic_phone,
                          subkon_pic_email:val.subkon_pic_email,
                          subkon_price_offering:val.subkon_price_offering,
                          subkon_price_nego:val.subkon_price_nego,
                          subkon_price_deal:val.subkon_price_deal,
                          subkon_notes:val.subkon_notes,
                          subkon_file:JSON.parse(val.subkon_file),
                        })
                      }
                  })
                  tempApproveData.push({
                    ...repair,
                    status_repair:null,
                    entity_internal: entity_internal,
                    entity_subkon:entity_subkon,
                  })
                })
      
                this.approve_sr_data = {
                  ...tempData,
                  form_repair: tempApproveData
                }
            }
        }
    },
    
    // ACTIVITY
    async getListRepairService(id, fleet = ''){

      if(id){
        this.activity_form.form_repair_id = null
        this.activity_form.id = id
        this.fleetLabelName = fleet
        let res = await this.fetchListRepairService(id)

        await this.getLogs(id)
        if (res?.status === 'error') {
            this.list_repair_service = null
        } else {
          this.list_repair_service = res?.data    
        }
      }
    },
    async getSingleRepairData(limit){
        this.limitActivity += Number(limit ?? 0)

        let params = {
            limit: Number(this.limitActivity)
        }
      if(this.activity_form.form_repair_id){
        this.minDate = ''
 
        let res = await this.fetchSingleRepair({id: this.activity_form.form_repair_id, params})
        if (res.status === 'error') {
            this.activity_repair_data = null
        } else {
            let entity_internal = []
            let entity_subkon = []
            
            res.data.entity.forEach(val => {
              if(val.entity_type === 'internal'){
                  entity_internal.push({
                    entity_type:val.entity_type,
                    internal_name: val.internal_name,
                    internal_position_id: val.internal_position_id,
                  })
                } else {
                  entity_subkon.push({
                    subkon_name:val.subkon_name,
                    subkon_pic:val.subkon_pic,
                    subkon_pic_phone:val.subkon_pic_phone,
                    subkon_pic_email:val.subkon_pic_email,
                    subkon_price_offering:val.subkon_price_offering,
                    subkon_price_nego:val.subkon_price_nego,
                    subkon_price_deal:val.subkon_price_deal,
                    subkon_notes:val.subkon_notes,
                    subkon_file:JSON.parse(val.subkon_file),
                  })
                }
            })
            
            this.activity_repair_data = {
              ...res.data,
              entity_internal,
              entity_subkon
            }

            if(this.activity_repair_data.activity.length > 0){
                this.activity_repair_data.activity = this.activity_repair_data.activity.map(val => {
                    let file = JSON.parse(val.file)

                    if(file.length > 0){
                        file = file.map(obj => {
                        const fileExtension = obj.url.split('.').pop().toLowerCase();
                        obj.extension = fileExtension

                        return obj
                    })
                    }

                    val.parsed_file = file
                    return val
                })
            }

            if(this.activity_repair_data.entity_subkon.length > 0){
                this.activity_repair_data.entity_subkon = this.activity_repair_data.entity_subkon.map(val => {
                    if(val.subkon_file.length > 0){
                        val.subkon_file.map(obj => {
                            const fileExtension = obj.url.split('.').pop().toLowerCase();
                            obj.extension = fileExtension

                            return obj
                        })
                    }
                    return val
                })
            }

            if(this.activity_repair_data.file.length > 0){
                this.activity_repair_data.file = this.activity_repair_data.file.map(obj => {
                    const fileExtension = obj.file.split('.').pop().toLowerCase();
                    obj.extension = fileExtension

                    return obj
                })
            }


            console.log(this.activity_repair_data , "ACTIVITY REPAIR DATA")

            if(this.activity_repair_data?.activity?.[0]?.date_of_activity){
                this.minDate = moment(this.activity_repair_data.activity[0].date_of_activity).format('YYYY-MM-DD')
            } else {
                this.minDate = ''
            }
        }
        }
    },

    addRepairJob(form){
        form.push(
            {
                maintenance_id:null,
                maintenance_component_setup_id:null,
                maintenance_component_setup_name:'',
                status_id:null,
                name:'',
                title:'',
                classification_id:null,
                category_id:null,
                department_id:1,
                section:'',
                case_id:null,
                task_type_id:null,
                kind_task_id:null,
                start_at:null,
                actual_finished_at:null,
                description:null,
                estimate_total_days:null,
                file:[],
            }
        )
    },
    removeRepairJob(form, index){
        form.splice(index, 1)
    },
    resetFormAddServiceAndRepair(){
        this.all_equipment_selected = false
        this.is_form_created = false
        this.componentIdSelected = null
        this.list_equipment = []
        this.selected_components = []
        this.form_repair_service = {
            loading:false,
            data: {
                vehicle_id: null,
                location: '',
                site: '',
                pic: '',
                type_of_job:'custom',
                created_by: JSON.parse(localStorage.getItem('profile')).id,
                updated_by: null,
                accident_at: null,
                user_id: JSON.parse(localStorage.getItem('profile')).id,
                user_name: JSON.parse(localStorage.getItem('profile')).userDetail.name,
                contents: [
                    {
                        maintenance_id:null,
                        maintenance_component_setup_id:null,
                        maintenance_component_setup_name:'',
                        status_id:null,
                        name:'',
                        title:'',
                        classification_id:null,
                        category_id:null,
                        department_id:1,
                        section:'',
                        case_id:null,
                        task_type_id:null,
                        kind_task_id:null,
                        start_at:null,
                        actual_finished_at:null,
                        description:null,
                        estimate_total_days:null,
                        file:[],
                    }
                ]
            }
        }
        this.job_data ={
            ...this.job_data,
            existed_data:[],
            form_repair: [
                {
                    maintenance_id:null,
                    maintenance_component_setup_id:null,
                    maintenance_component_setup_name:'',
                    status_id:null,
                    name:'',
                    title:'',
                    classification_id:null,
                    category_id:null,
                    department_id:1,
                    section:'',
                    case_id:null,
                    task_type_id:null,
                    kind_task_id:null,
                    start_at:null,
                    actual_finished_at:null,
                    description:null,
                    estimate_total_days:null,
                    file:[],
                }
            ]
        }
    },
    resetActivityRepair(){
     this.activity_form = {
        id:null,
        form_repair_id:null,
        activity_id:null,
        date_of_activity:null,
        notes:'',
        is_finished:false,
        file:[]
      }
    },
    resetReject(){
     
     this.selected_reject_id = null
     this.form_reject = {
        reject_id:null,
        reject_by: JSON.parse(localStorage.getItem('profile')).id,
        remarks:'',
      }
      this.showRejectModal = false
    },

    addInternal(id, index){
        this.repair_data.form_repair[index].review_internal.push(
            {
              repair_form_id:id,
              entity_type:'',
              user_id:JSON.parse(localStorage.getItem('profile')).id,
              internal_name:'',
              internal_position_id:'',
              subkon_name:'',
              subkon_pic:'',
              subkon_pic_phone:'',
              subkon_pic_email:'',
              subkon_price_offering:'',
              subkon_price_nego:'',
              subkon_price_deal:'',
              subkon_notes:'',
              subkon_file:[],
            }
        )
    },
    removeInternal(index, internalIndex){
      this.repair_data.form_repair[index].review_internal.splice(internalIndex, 1)
    },

    addSubkon(id, index){
      this.repair_data.form_repair[index].review_subkon.push(
            {
              repair_form_id:id,
              entity_type:'',
              user_id:JSON.parse(localStorage.getItem('profile')).id,
              internal_name:'',
              internal_position_id:'',
              subkon_name:'',
              subkon_pic:'',
              subkon_pic_phone:'',
              subkon_pic_email:'',
              subkon_price_offering:'',
              subkon_price_nego:'',
              subkon_price_deal:'',
              subkon_notes:'',
              subkon_file:[],
            }
        )
    },
    removeSubkon(index, subkonIndex){
      this.repair_data.form_repair[index].review_subkon.splice(subkonIndex, 1)
    },

    async onSaveReviewRepair(e){
        e.preventDefault()
        this.review_loading = true
       
        let readyToSave = {
          user_id: JSON.parse(localStorage.getItem('profile')).id,
          user_name: JSON.parse(localStorage.getItem('profile')).userDetail.name,
          content:[],
          review:[]
        }

        if(this.repair_data?.form_repair?.length > 0){
            this.repair_data.form_repair.forEach((repair, index) => {
                let newReview = []
                readyToSave.content.push({
                form_repair_id: repair.id,
                cost_estimate: repair.cost_estimate ?? 0,
                is_internal: repair.is_internal,
                is_subkon: repair.is_subkon,
                review:[]
                })
    
                if(repair.is_internal){
                newReview.push(...repair.review_internal)
                }
                if(repair.is_subkon){
                newReview.push(...repair.review_subkon)
                }
    
                newReview.forEach((review, reviewIndex) => {
                readyToSave.review.push({
                    ...review,
                    review_id:reviewIndex,
                    entity_type: Number(review.subkon_price_deal) > 0 || 
                        Number(review.subkon_price_offering) > 0 || 
                        Number(review.subkon_price_nego) > 0 ? "sub_kontraktor" : "internal"
                })
                
                readyToSave.content[index].review.push({
                    ...review,
                    review_id:reviewIndex
                })
                })
            })
    
            let formData = new FormData()
            formData.append('user_id', readyToSave.user_id)
            formData.append('user_name', readyToSave.user_name)
    
            readyToSave.content.forEach((item, index) => {
                for (const property in item) {
                    if(property !== 'review'){
                        formData.append(
                            `content[${index}][${property}]`,
                            item[property]
                        )
                    }
                }
            })
    
            readyToSave.review.forEach((item, reviewIndex) => {
                    for (const property in item) {
                        formData.append(
                            `review[${reviewIndex}][${property}]`,
                            item[property]
                        )
                    }
                    if(item?.subkon_file?.length > 0){
                    item.subkon_file.forEach((file, fileIndex) => {
                        formData.append(`file[${item.repair_form_id}]`, file);
                    });
                    }
            }) 
    
            const res = await this.setReviewRepair({formData, id:this.repair_data.id})
    
            if(res.status === 'success'){
                this.review_loading = false
                this.closeSidebar('review_sr')
                this.$swal(`Berhasil!`, `Review Repair Berhasil.`, 'success')
                await this.getListTableServiceAndRepair()
                await this.getListProgressTableServiceAndRepair()
    
                this.repair_data = null
            } else {
                this.review_loading = false
                this.$swal(`Oops!`, `${res.data.message}`, 'error')
            }
        } else {
            this.review_loading = false
            this.$swal(`Error!`, `Pekerjaan tidak boleh kosong, Harap sisipkan pekerjaan.`, 'warning')
        }
    },

    async setJobPerService(e){
      e.preventDefault()
      this.job_loading = true

      let formData = new FormData()
      formData.append('user_id', JSON.parse(localStorage.getItem('profile')).id)
      formData.append('user_name', JSON.parse(localStorage.getItem('profile')).userDetail.name)

      this.job_data.form_repair.forEach((item, index) => {
            item.file.forEach((file, fileIndex) => {
                formData.append(`file[${index}]`, file);
            });
   
            for (const property in item) {
                formData.append(
                    `contents[${index}][${property}]`,
                    item[property]
                )
            }
        })

      const res = await this.createJobs({id: this.job_data.id, formData})

      if(res.status === 'success'){
        this.repair_data = null
        await this.getListTableServiceAndRepair()
        await this.getListProgressTableServiceAndRepair()
        this.closeSidebar('add-job')
        this.$swal(`Berhasil!`, `Create new repair berhasil.`, 'success')

        this.checkedFleets = null
        this.vehicleTypeId = null
        this.resetFormAddServiceAndRepair()
        this.job_loading = false
      } else {
        this.job_loading = false
        this.$swal(`Oops!`, `${res.data.message}`, 'error')
      }
      this.job_loading = false
    },
    async updateJobsService(e){
      e.preventDefault()
      this.update_jobs_loading = true

      let formData = new FormData()
      formData.append('user_id', JSON.parse(localStorage.getItem('profile')).id)
      formData.append('user_name', JSON.parse(localStorage.getItem('profile')).userDetail.name)

      formData.append('id', this.update_jobs_data.id)
      formData.append('location', this.update_jobs_data.location)
      formData.append('pic', this.update_jobs_data.pic)
      formData.append('site', this.update_jobs_data.site)
      formData.append('accident_at', this.update_jobs_data.accident_at)

      this.update_jobs_data.form_repair.forEach((item, index) => {
            item.file.forEach((file, fileIndex) => {
                formData.append(`file[${index}]`, file);
            });
   
            for (const property in item) {
                formData.append(
                    `contents[${index}][${property}]`,
                    item[property]
                )
            }
        })

      const res = await this.updateJobs(formData)
      
      if(res.status === 'success'){
          this.closeSidebar('update_sr')
          this.$swal(`Berhasil!`, `Update repair berhasil.`, 'success')
          this.update_jobs_loading = false
          this.repair_data = null
          await this.getListTableServiceAndRepair()
          await this.getListProgressTableServiceAndRepair()
      } else {
          this.update_jobs_loading = false
          this.$swal(`Oops!`, `${res.data.message}`, 'error')
      }

    },

    async onApproveServiceAndRepair(e){
      e.preventDefault()

      this.approve_sr_loading = true
      let invalid = false
      let readyOnSave = {
        user_id: JSON.parse(localStorage.getItem('profile')).id,
        user_name: JSON.parse(localStorage.getItem('profile')).userDetail.name,
        data: []
      }
      this.approve_sr_data.form_repair.map((repair) => {
        if(repair.status_repair === null){
          invalid = true
        }

        readyOnSave.data.push({
          id: repair.id,
          cost_real: repair.cost_real ? Number(repair.cost_real) : 0,
          status:repair.status_repair
        })
      })      

      if (invalid){
        this.approve_sr_loading = false
        return this.$swal(`Oops!`, `Mohon Approve/Reject Repair dahulu.`, 'warning')
      }

      const res = await this.approveSr({id: this.approve_sr_data.id, data: readyOnSave})
      
      if(res.status === 'success'){
          this.closeSidebar('approve_sr')
          this.$swal(`Berhasil!`, `Approve Service & Repair berhasil.`, 'success')
          this.approve_sr_loading = false
          this.repair_data = null
          await this.getListTableServiceAndRepair()
          await this.getListProgressTableServiceAndRepair()
      } else {
          this.approve_sr_loading = false
          this.$swal(`Oops!`, `${res.data.message}`, 'error')
      }
    },

    async onSaveActivity(e){
      e.preventDefault()

      this.activity_repair_loading = true

      // Get the current time
      const inputDate = moment(this.activity_form.date_of_activity, 'YYYY-MM-DD');
      const currentTime = moment();

      // Combine the input date with the current time
      const combinedDateTime = inputDate.set({
        'hour': currentTime.get('hour'),
        'minute': currentTime.get('minute'),
        'second': currentTime.get('second'),
      });

      let formData = new FormData()
      formData.append('user_id', JSON.parse(localStorage.getItem('profile')).id)
      formData.append('user_name', JSON.parse(localStorage.getItem('profile')).userDetail.name)

      formData.append('form_repair_id', this.activity_form.form_repair_id)
      formData.append('activity_id', this.activity_form.activity_id)
      formData.append('date_of_activity', moment(combinedDateTime).format('YYYY-MM-DD HH:mm:ss'))
      formData.append('is_finished', this.activity_form.is_finished)
      formData.append('notes', this.activity_form.notes)

      this.activity_form.file.forEach((file, fileIndex) => {
          formData.append(`file[${fileIndex}]`, file);
      });

      const res = await this.setActivityRepair({id: this.activity_form.id, formData})
      
      if(res.status === 'success'){
          // this.closeSidebar('add-activity')
          this.$swal(`Berhasil!`, `Menambahkan aktivitas berhasil.`, 'success')
          this.activity_repair_loading = false
          await this.getListTableServiceAndRepair()
          await this.getListProgressTableServiceAndRepair()
          if(this.activity_form.is_finished === true){
            location.reload()
          }
          this.resetActivityRepair()
      } else {
          this.activity_repair_loading = false
          this.$swal(`Oops!`, `${res.data.message}`, 'error')
      }

    
    },

    async onRejectService(e){
      e.preventDefault()
      this.reject_loading = true

      let data = {
        ...this.form_reject,
        user_id: JSON.parse(localStorage.getItem('profile')).id,
        user_name: JSON.parse(localStorage.getItem('profile')).userDetail.name,
      }

      const res = await this.setRejectService({id:this.selected_reject_id, data: data })

      if(res.status === 'success'){
          this.reject_loading = false
          await this.getListTableServiceAndRepair()
          await this.getListProgressTableServiceAndRepair()
          this.resetReject()
          this.$swal(`Berhasil!`, `Reject Service berhasil.`, 'success')
      } else {
          this.reject_loading = false
          this.$swal(`Oops!`, `Gagal reject service`, 'error')
      }
    }
  }
}
</script>