var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', [_vm.request_loading ? _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(" Loading... ")]) : _vm._e(), !_vm.request_loading ? _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("REQUEST REPAIR & MAINTENANCE")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        var _vm$fleets;
        return [!_vm.readOnly ? _c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.add-init",
            modifiers: {
              "add-init": true
            }
          }],
          attrs: {
            "block": "",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.showInitModal = !_vm.showInitModal;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v("   Add Data ")]) : _vm._e(), _c('b-modal', {
          attrs: {
            "id": "add-init",
            "size": "xl",
            "title": "ADD REPAIR & MAINTENANCE",
            "header-bg-variant": "light",
            "header-text-variant": "dark",
            "footer-bg-variant": "light",
            "footer-text-variant": "dark"
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn(_ref) {
              var cancel = _ref.cancel;
              return [_c('b-row', [_c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                staticClass: "iq-bg-danger text-right",
                attrs: {
                  "variant": "none"
                },
                on: {
                  "click": function click($event) {
                    cancel();
                    _vm.checkedFleets = null;
                  }
                }
              }, [_vm._v("Cancel")]), _c('b-button', {
                staticClass: "ml-2 text-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary",
                  "disabled": _vm.nextLoading
                },
                on: {
                  "click": _vm.nextAfterSelectVehicle
                }
              }, [_vm.nextLoading ? _c('span', {
                staticClass: "spinner-border spinner-border-sm"
              }) : _c('span', [_vm._v("Next "), _c('i', {
                staticClass: "fa fa-arrow-right"
              })])])], 1)], 1)];
            }
          }], null, false, 4237420944),
          model: {
            value: _vm.showInitModal,
            callback: function callback($$v) {
              _vm.showInitModal = $$v;
            },
            expression: "showInitModal"
          }
        }, [_c('b-row', {
          staticClass: "mb-4"
        }, [_c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Search fleets..."
          },
          on: {
            "input": _vm.fetchFleets
          },
          model: {
            value: _vm.fleet_params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.fleet_params, "search", $$v);
            },
            expression: "fleet_params.search"
          }
        })], 1)], 1), _vm.loading_fleet ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [((_vm$fleets = _vm.fleets) === null || _vm$fleets === void 0 ? void 0 : _vm$fleets.length) > 0 ? _c('b-row', _vm._l(_vm.fleets, function (fleet, i) {
          return _c('b-col', {
            key: "fleet-".concat(i),
            staticClass: "text-center py-5 border-top",
            attrs: {
              "md": "3"
            }
          }, [_c('div', {
            staticClass: "custom-control custom-radio image-checkbox"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.checkedFleets,
              expression: "checkedFleets"
            }],
            staticClass: "custom-control-input",
            attrs: {
              "id": "ck-fl-".concat(i),
              "type": "radio"
            },
            domProps: {
              "value": fleet.id,
              "checked": _vm._q(_vm.checkedFleets, fleet.id)
            },
            on: {
              "change": function change($event) {
                _vm.checkedFleets = fleet.id;
              }
            }
          }), _c('label', {
            staticClass: "custom-control-label",
            attrs: {
              "for": "ck-fl-".concat(i)
            }
          }, [_c('img', {
            staticClass: "img-fluid",
            attrs: {
              "src": _vm._f("checkPhoto")(fleet.vehicleType.icon.url, require('@src/assets/images/fleet/PusherBarge.png')),
              "alt": "#"
            }
          })]), _c('small', [_c('strong', [_vm._v(_vm._s(fleet.name))])])])]);
        }), 1) : _c('div', {
          staticClass: "text-center my-5"
        }, [_c('p', {
          staticClass: "mb-0 text-muted"
        }, [_vm._v("No fleets data found.")])])]], 2)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$list_table_reques, _vm$list_table_reques2, _vm$list_table_reques3, _vm$job_data, _vm$repair_data, _vm$approve_sr_data, _vm$detail_sr_data, _vm$update_jobs_data;
        return [_c('table', {
          staticClass: "table table-striped table-sm"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("FLEET NAME")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("LOCATION")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TANGGAL")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL REPAIR")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("CLASSIFICATION")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("OFFICE/PIC")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("STATUS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', _vm._l((_vm$list_table_reques = _vm.list_table_requested) === null || _vm$list_table_reques === void 0 ? void 0 : _vm$list_table_reques.data, function (state, index) {
          return _c('tr', {
            key: 'request-' + index
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(index + 1))]), _c('td', {
            staticClass: "text-left"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.fleet))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.location))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(_vm.moment(state === null || state === void 0 ? void 0 : state.accident_at).format('DD MMMM YYYY')))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.total_repair))]), _c('td', {
            staticClass: "text-center"
          }, _vm._l(state.classification, function (classification, classIndex) {
            return _c('span', {
              key: classIndex
            }, [_c('b-badge', {
              staticClass: "mb-1",
              attrs: {
                "variant": "".concat(classification.name === 'Minor' ? 'success' : classification.name === 'Medium' ? 'info' : 'danger', " d-block")
              }
            }, [_vm._v(" " + _vm._s(classification === null || classification === void 0 ? void 0 : classification.name) + " : " + _vm._s(classification === null || classification === void 0 ? void 0 : classification.total) + " Repair ")])], 1);
          }), 0), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.site)), _c('br'), _c('small', [_vm._v("PIC : "), _c('strong', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.pic))])])]), _c('td', {
            staticClass: "text-center"
          }, [_c('b-badge', {
            attrs: {
              "variant": "".concat((state === null || state === void 0 ? void 0 : state.status) === 'new' ? 'info' : state.status === 'review' ? 'primary' : 'danger', " d-block")
            }
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.status))])], 1), _c('td', {
            staticClass: "text-center"
          }, [_c('b-dropdown', {
            attrs: {
              "size": "lg",
              "variant": "link iq-bg-primary iq-border-radius-10",
              "toggle-class": "text-decoration-none",
              "no-caret": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function fn() {
                return [_c('span', {
                  staticClass: "dropdown-toggle p-0",
                  attrs: {
                    "id": "dropdownMenuButton5",
                    "data-toggle": "dropdown"
                  }
                }, [_c('i', {
                  staticClass: "fa fa-pencil-square-o m-0 text-primary"
                })])];
              },
              proxy: true
            }], null, true)
          }, [state.status === 'new' || state.status === 'progress' ? _c('b-dropdown-item', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle.review_sr",
              modifiers: {
                "review_sr": true
              }
            }],
            attrs: {
              "href": "#"
            }
          }, [!_vm.readOnly ? _c('div', {
            on: {
              "click": function click() {
                return _vm.getReviewData(state.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-eye mr-2"
          }), _vm._v("Review ")]) : _vm._e()]) : _vm._e(), state.status === 'review' ? _c('b-dropdown-item', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle.approve_sr",
              modifiers: {
                "approve_sr": true
              }
            }],
            attrs: {
              "href": "#"
            }
          }, [!_vm.readOnly ? _c('div', {
            on: {
              "click": function click() {
                return _vm.getSingleApproveRepair(state.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-check mr-2"
          }), _vm._v("Approve ")]) : _vm._e()]) : _vm._e(), state.status === 'new' || state.status === 'progress' ? _c('b-dropdown-item', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle.update_sr",
              modifiers: {
                "update_sr": true
              }
            }],
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click() {
                return _vm.getSingleUpdatedServiceAndRepair(state.id);
              }
            }
          }, [!_vm.readOnly ? _c('div', {
            on: {
              "click": function click() {
                return _vm.getSingleApproveRepair(state.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-pencil-square mr-2"
          }), _vm._v("Update Data ")]) : _vm._e()]) : _vm._e(), !_vm.readOnly ? _c('b-dropdown-item', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle.add-job",
              modifiers: {
                "add-job": true
              }
            }],
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click() {
                _vm.getSingleServiceAndRepair(state.id);
                _vm.is_job_data_equipment = true;
                _vm.checkedFleets = state.fleet_id;
                _vm.getComponentStruct();
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-plus mr-2"
          }), _vm._v("Add Job ")]) : _vm._e(), _c('b-dropdown-item', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle.detail_sr",
              modifiers: {
                "detail_sr": true
              }
            }],
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click() {
                return _vm.getSingleServiceAndRepair(state.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-info-circle mr-2"
          }), _vm._v("Detail ")]), _c('b-dropdown-divider'), !_vm.readOnly && !(state !== null && state !== void 0 && state.request_delete) ? _c('b-dropdown-item', {
            attrs: {
              "href": "#",
              "variant": "warning"
            },
            on: {
              "click": function click() {
                return _vm.onRequestDeleteServiceRepair(state);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-remove mr-2"
          }), _vm._v("Request Delete R & M ")]) : _vm._e(), !_vm.readOnly && _vm.permissionDelete(state === null || state === void 0 ? void 0 : state.request_delete) ? _c('b-dropdown-item', {
            directives: [{
              name: "b-modal",
              rawName: "v-b-modal.reject-modal",
              modifiers: {
                "reject-modal": true
              }
            }],
            attrs: {
              "href": "#",
              "variant": "warning"
            },
            on: {
              "click": function click() {
                return _vm.selected_reject_id = state.id;
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-remove mr-2"
          }), _vm._v("Reject R & M ")]) : _vm._e(), !_vm.readOnly && _vm.permissionDelete(state === null || state === void 0 ? void 0 : state.request_delete) ? _c('b-dropdown-item', {
            attrs: {
              "href": "#",
              "variant": "danger"
            },
            on: {
              "click": function click() {
                return _vm.deleteServiceRepair(state);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash mr-2"
          }), _vm._v("Delete R & M ")]) : _vm._e()], 1)], 1)]);
        }), 0)]), ((_vm$list_table_reques2 = _vm.list_table_requested) === null || _vm$list_table_reques2 === void 0 ? void 0 : (_vm$list_table_reques3 = _vm$list_table_reques2.data) === null || _vm$list_table_reques3 === void 0 ? void 0 : _vm$list_table_reques3.length) > 0 ? _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.list_table_requested.meta.total_item,
            "per-page": _vm.list_table_requested.meta.per_page,
            "aria-controls": "my-table",
            "align": "center",
            "first-number": ""
          },
          on: {
            "change": _vm.getListTableServiceAndRepair
          },
          model: {
            value: _vm.list_table_requested.meta.page,
            callback: function callback($$v) {
              _vm.$set(_vm.list_table_requested.meta, "page", $$v);
            },
            expression: "list_table_requested.meta.page"
          }
        })], 1) : _vm._e(), _c('b-sidebar', {
          attrs: {
            "id": "add-sr",
            "title": 'ADD REPAIR & MAINTENANCE -' + _vm.fleetNameChecked,
            "width": "100%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var hide = _ref2.hide;
              return [_c('form', {
                attrs: {
                  "action": "#",
                  "method": "post"
                },
                on: {
                  "submit": _vm.setServiceAndRepair
                }
              }, [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Service & Repair Form")]), _c('b-row', {
                staticClass: "mt-2"
              }, [_vm.form_repair_service.data.type_of_job === 'equipment_init' ? _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('v-jstree', {
                attrs: {
                  "data": _vm.treeInitMaintenance,
                  "show-checkbox": "",
                  "allow-batch": "",
                  "whole-row": ""
                },
                on: {
                  "item-click": _vm.itemClickInitComponent
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_) {
                    return [_c('div', {
                      staticStyle: {
                        "display": "inherit",
                        "width": "200px"
                      }
                    }, [!_.model.loading ? _c('i', {
                      class: _.vm.themeIconClasses,
                      attrs: {
                        "role": "presentation"
                      }
                    }) : _vm._e(), _vm._v(" " + _vm._s(_.model.text) + " ")])];
                  }
                }], null, true)
              })], 1) : _vm._e(), _c('b-col', {
                attrs: {
                  "md": _vm.form_repair_service.data.type_of_job === 'custom' ? '12' : '8'
                }
              }, [_c('b-row', {
                staticClass: "mt-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "primary"
                }
              }, [_c('div', {
                staticClass: "custom-control custom-control-inline"
              }, [_c('label', [_c('b', [_vm._v("SELECT JOB FROM :")])])]), _c('b-form-group', [_c('b-form-radio-group', {
                attrs: {
                  "id": "type_of_job",
                  "name": "type_of_job",
                  "required": ""
                },
                model: {
                  value: _vm.form_repair_service.data.type_of_job,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form_repair_service.data, "type_of_job", $$v);
                  },
                  expression: "form_repair_service.data.type_of_job"
                }
              }, [_c('b-form-radio', {
                attrs: {
                  "value": "equipment_init"
                }
              }, [_vm._v("From Equipment")]), _c('b-form-radio', {
                attrs: {
                  "value": "custom"
                }
              }, [_vm._v("Custom")])], 1)], 1)], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Detail Information")]), _c('table', {
                staticClass: "table mb-0 table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Lokasi Pekerjaan")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "required": "",
                  "type": "text",
                  "name": "location",
                  "placeholder": "Type Text ..."
                },
                model: {
                  value: _vm.form_repair_service.data.location,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form_repair_service.data, "location", $$v);
                  },
                  expression: "form_repair_service.data.location"
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "required": "",
                  "name": "accident_at"
                },
                model: {
                  value: _vm.form_repair_service.data.accident_at,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form_repair_service.data, "accident_at", $$v);
                  },
                  expression: "form_repair_service.data.accident_at"
                }
              })], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Site & Group")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Type Text ...",
                  "name": "site"
                },
                model: {
                  value: _vm.form_repair_service.data.site,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form_repair_service.data, "site", $$v);
                  },
                  expression: "form_repair_service.data.site"
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("P.I.C")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Type Text ...",
                  "name": "pic"
                },
                model: {
                  value: _vm.form_repair_service.data.pic,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form_repair_service.data, "pic", $$v);
                  },
                  expression: "form_repair_service.data.pic"
                }
              })], 1)])])])]), _c('hr'), _vm.componentIdSelected && !_vm.is_form_created ? _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Selected Equipments")]), _vm.selected_components.length > 0 ? _c('b-row', _vm._l(_vm.selected_components, function (component, componentIndex) {
                return _c('b-col', {
                  key: componentIndex,
                  staticClass: "mb-2",
                  attrs: {
                    "md": "12"
                  }
                }, [_c('div', {
                  staticClass: "p-2 pl-4 pr-4 bg-primary d-flex align-items-center justify-content-between gap-4 rounded"
                }, [_c('span', [_vm._v(_vm._s(component.maintenanceMaster.equipmentId) + " - " + _vm._s(component.maintenanceMaster.equipmentName))]), _c('i', {
                  staticClass: "fa fa-trash text-danger",
                  staticStyle: {
                    "cursor": "pointer"
                  },
                  on: {
                    "click": function click() {
                      _vm.removeSelectedComponent(_vm.selected_components, component.id);
                      _vm.changeStatusIsSelected(component.id);
                    }
                  }
                })])]);
              }), 1) : _vm._e()], 1) : _vm._e(), _c('hr'), _vm.componentIdSelected ? _c('div', [_vm.list_equipment && _vm.list_equipment.length === 0 ? _c('b-row', [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-warning",
                attrs: {
                  "show": true,
                  "variant": " "
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "ri-alert-line"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("For Information Equipment Not Setup for Fleet "), _c('b', [_vm._v(_vm._s(_vm.fleetNameChecked))]), _vm._v(", Please Setup First")])])], 1)], 1) : _vm._e(), _vm.list_equipment.length > 0 && !_vm.is_form_created ? _c('b-row', [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('div', {
                staticClass: "table-responsive"
              }, [_c('table', {
                staticClass: "table mb-0 table-striped table-hover"
              }, [_c('thead', {
                staticClass: "text-center"
              }, [_c('tr', [_c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.all_equipment_selected,
                  expression: "all_equipment_selected"
                }],
                attrs: {
                  "type": "checkbox",
                  "name": _vm.all_equipment_selected
                },
                domProps: {
                  "value": _vm.all_equipment_selected,
                  "checked": Array.isArray(_vm.all_equipment_selected) ? _vm._i(_vm.all_equipment_selected, _vm.all_equipment_selected) > -1 : _vm.all_equipment_selected
                },
                on: {
                  "change": function change($event) {
                    var $$a = _vm.all_equipment_selected,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false;
                    if (Array.isArray($$a)) {
                      var $$v = _vm.all_equipment_selected,
                        $$i = _vm._i($$a, $$v);
                      if ($$el.checked) {
                        $$i < 0 && (_vm.all_equipment_selected = $$a.concat([$$v]));
                      } else {
                        $$i > -1 && (_vm.all_equipment_selected = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                      }
                    } else {
                      _vm.all_equipment_selected = $$c;
                    }
                  }
                }
              })]), _c('td', [_vm._v("Equipment - Code")]), _c('td', [_vm._v("Type - Serial No")]), _c('td', [_vm._v("Maker")]), _c('td', [_vm._v("Job Index")])])]), _c('tbody', _vm._l(_vm.list_equipment, function (components, componentIndex) {
                return _c('tr', {
                  key: components.id
                }, [_c('td', [_c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: _vm.list_equipment[componentIndex].is_selected,
                    expression: "list_equipment[componentIndex].is_selected"
                  }],
                  staticClass: "text-primary",
                  attrs: {
                    "type": "checkbox",
                    "id": _vm.list_equipment[componentIndex].id,
                    "name": "list_equipment[".concat(componentIndex, "].is_selected")
                  },
                  domProps: {
                    "value": _vm.list_equipment[componentIndex].is_selected,
                    "checked": Array.isArray(_vm.list_equipment[componentIndex].is_selected) ? _vm._i(_vm.list_equipment[componentIndex].is_selected, _vm.list_equipment[componentIndex].is_selected) > -1 : _vm.list_equipment[componentIndex].is_selected
                  },
                  on: {
                    "change": [function ($event) {
                      var $$a = _vm.list_equipment[componentIndex].is_selected,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = _vm.list_equipment[componentIndex].is_selected,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(_vm.list_equipment[componentIndex], "is_selected", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(_vm.list_equipment[componentIndex], "is_selected", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(_vm.list_equipment[componentIndex], "is_selected", $$c);
                      }
                    }, function ($event) {
                      return _vm.selectedComponents(components);
                    }]
                  }
                })]), _c('td', [_vm._v(_vm._s(components.maintenanceMaster.equipmentId) + " - " + _vm._s(components.maintenanceMaster.equipmentName))]), _c('td', [_vm._v(_vm._s(components.typeNo) + " - " + _vm._s(components.serialNo))]), _c('td', [_c('ul', [_c('li', [_vm._v("Maker No: " + _vm._s(components.makerNo))]), _c('li', [_vm._v("Maker: " + _vm._s(components.maker))])])]), _c('td', {
                  staticClass: "text-center"
                }, [components.status.length === 0 ? _c('small', [_c('b-badge', {
                  attrs: {
                    "variant": "danger d-block"
                  }
                }, [_vm._v("NOT YET INIT")])], 1) : _c('small', _vm._l(components.status, function (stats, statsIndex) {
                  return _c('b-badge', {
                    key: statsIndex,
                    staticClass: "mb-1",
                    attrs: {
                      "variant": "success d-block"
                    }
                  }, [_vm._v(_vm._s(stats))]);
                }), 1)])]);
              }), 0)])])]), _c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_vm.form_repair_service.data.type_of_job === 'equipment_init' && !_vm.is_form_created ? _c('b-button', {
                attrs: {
                  "variant": "success",
                  "block": ""
                },
                on: {
                  "click": _vm.createEquipmentForm
                }
              }, [_c('i', {
                staticClass: "fa fa-plus"
              }), _vm._v(" CREATE FORM ")]) : _vm._e()], 1)], 1) : _vm._e()], 1) : _vm._e(), !_vm.componentIdSelected && _vm.form_repair_service.data.type_of_job === 'equipment_init' ? _c('b-row', [_c('b-col', [_c('b-alert', {
                attrs: {
                  "show": "",
                  "variant": "warning"
                }
              }, [_vm._v("Please select component first!")])], 1)], 1) : _vm._e(), _vm.form_repair_service.data.type_of_job === 'custom' || _vm.is_form_created ? _c('div', [_vm._l(_vm.form_repair_service.data.contents, function (state, index) {
                return _c('fieldset', {
                  key: "add-" + index,
                  staticClass: "form-group border p-3"
                }, [_c('legend', {
                  staticClass: "w-auto px-2 text-primary"
                }, [_vm._v("Repair #" + _vm._s(index + 1))]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Equipment Name",
                    "label-for": "equipmentName"
                  }
                }, [_c('b-form-input', {
                  attrs: {
                    "type": "text",
                    "name": "name",
                    "placeholder": "Type Text ...",
                    "required": "",
                    "disabled": _vm.form_repair_service.data.type_of_job === 'equipment_init'
                  },
                  model: {
                    value: state.name,
                    callback: function callback($$v) {
                      _vm.$set(state, "name", $$v);
                    },
                    expression: "state.name"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Work Item",
                    "label-for": "repairTitle"
                  }
                }, [_c('b-form-input', {
                  attrs: {
                    "type": "text",
                    "name": "title",
                    "placeholder": "Type Text ...",
                    "required": ""
                  },
                  model: {
                    value: state.title,
                    callback: function callback($$v) {
                      _vm.$set(state, "title", $$v);
                    },
                    expression: "state.title"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Type R & M *",
                    "label-for": "type_repair"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "name": "task_type_id",
                    "options": _vm.optionsTaskType,
                    "size": "lg",
                    "required": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": null
                        }
                      }, [_vm._v("Select Type R & M")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: state.task_type_id,
                    callback: function callback($$v) {
                      _vm.$set(state, "task_type_id", $$v);
                    },
                    expression: "state.task_type_id"
                  }
                })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', {
                  attrs: {
                    "md": "2"
                  }
                }, [_c('b-form-group', {
                  attrs: {
                    "label": "Department *",
                    "label-for": "department"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "name": "department_id",
                    "options": _vm.optionsDepartment,
                    "size": "lg",
                    "required": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": null
                        }
                      }, [_vm._v("Select Department")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: state.department_id,
                    callback: function callback($$v) {
                      _vm.$set(state, "department_id", $$v);
                    },
                    expression: "state.department_id"
                  }
                })], 1)], 1), _c('b-col', {
                  attrs: {
                    "md": "2"
                  }
                }, [_c('b-form-group', {
                  attrs: {
                    "label": "Section *",
                    "label-for": "section"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "name": "section",
                    "size": "lg",
                    "required": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": ''
                        }
                      }, [_vm._v("Select Section")]), _c('b-form-select-option', {
                        attrs: {
                          "value": 'DECK'
                        }
                      }, [_vm._v("Deck")]), _c('b-form-select-option', {
                        attrs: {
                          "value": 'ENGINE'
                        }
                      }, [_vm._v("Engine")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: state.section,
                    callback: function callback($$v) {
                      _vm.$set(state, "section", $$v);
                    },
                    expression: "state.section"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Category *",
                    "label-for": "category"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "name": "category_id",
                    "options": _vm.optionsCategory,
                    "size": "lg",
                    "required": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": null
                        }
                      }, [_vm._v("Select Category")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: state.category_id,
                    callback: function callback($$v) {
                      _vm.$set(state, "category_id", $$v);
                    },
                    expression: "state.category_id"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Classification *",
                    "label-for": "classification"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "name": "classification_id",
                    "options": _vm.optionsClassification,
                    "size": "lg",
                    "required": ""
                  },
                  on: {
                    "change": function change($event) {
                      return _vm.handleCountDaysBasedClassification(state);
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": null
                        }
                      }, [_vm._v("Select Classification")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: state.classification_id,
                    callback: function callback($$v) {
                      _vm.$set(state, "classification_id", $$v);
                    },
                    expression: "state.classification_id"
                  }
                })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Cause *",
                    "label-for": "cause"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "name": "case_id",
                    "options": _vm.optionsCause,
                    "size": "lg",
                    "required": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": null
                        }
                      }, [_vm._v("Select Cause")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: state.case_id,
                    callback: function callback($$v) {
                      _vm.$set(state, "case_id", $$v);
                    },
                    expression: "state.case_id"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Kind of Task *",
                    "label-for": "kind_of_task"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "name": "kind_task_id",
                    "options": _vm.optionsKindTask,
                    "size": "lg",
                    "required": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": null
                        }
                      }, [_vm._v("Select Kind of Task")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: state.kind_task_id,
                    callback: function callback($$v) {
                      _vm.$set(state, "kind_task_id", $$v);
                    },
                    expression: "state.kind_task_id"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Berita Acara",
                    "label-for": "attactment_file"
                  }
                }, [_c('b-form-file', {
                  attrs: {
                    "plain": "",
                    "multiple": "",
                    "name": "file",
                    "id": "attactment_file",
                    "accept": "image/*,application/*"
                  },
                  model: {
                    value: state.file,
                    callback: function callback($$v) {
                      _vm.$set(state, "file", $$v);
                    },
                    expression: "state.file"
                  }
                })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Rencana Mulai *",
                    "label-for": "mulai"
                  }
                }, [_c('b-form-input', {
                  attrs: {
                    "type": "date",
                    "name": "start_at",
                    "id": "mulai",
                    "required": ""
                  },
                  on: {
                    "change": function change() {
                      _vm.calculateDays(state);
                      _vm.handleCountDaysBasedClassification(state);
                    }
                  },
                  model: {
                    value: state.start_at,
                    callback: function callback($$v) {
                      _vm.$set(state, "start_at", $$v);
                    },
                    expression: "state.start_at"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Rencana Selesai *",
                    "label-for": "selesai"
                  }
                }, [_c('b-form-input', {
                  attrs: {
                    "type": "date",
                    "name": "",
                    "id": "selesai",
                    "required": ""
                  },
                  on: {
                    "change": function change($event) {
                      return _vm.calculateDays(state);
                    }
                  },
                  model: {
                    value: state.estimated_finished_at,
                    callback: function callback($$v) {
                      _vm.$set(state, "estimated_finished_at", $$v);
                    },
                    expression: "state.estimated_finished_at"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Estimasi Hari",
                    "label-for": "estimasi_hari"
                  }
                }, [_c('b-button', {
                  staticClass: "mb-1",
                  attrs: {
                    "variant": "outline-primary"
                  }
                }, [_vm._v(" Perkiraan Pekerjaan "), _c('b-badge', {
                  staticClass: "ml-2",
                  attrs: {
                    "variant": "primary"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.estimate_total_days) + " Hari")])], 1)], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Description",
                    "label-for": "description"
                  }
                }, [_c('b-form-textarea', {
                  attrs: {
                    "id": "description",
                    "rows": "3",
                    "name": "",
                    "placeholder": "Type Text ..."
                  },
                  model: {
                    value: state.description,
                    callback: function callback($$v) {
                      _vm.$set(state, "description", $$v);
                    },
                    expression: "state.description"
                  }
                })], 1)], 1)], 1), _c('b-row', {
                  staticClass: "mt-2"
                }, [_c('b-col', {
                  attrs: {
                    "md": "12"
                  }
                }, [_c('b-button', {
                  staticClass: "pull-right",
                  attrs: {
                    "variant": "danger",
                    "size": "sm"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.removeRepairJob(_vm.form_repair_service.data.contents, index);
                    }
                  }
                }, [_c('i', {
                  staticClass: "fa fa-trash"
                }), _vm._v(" Delete Repair Job ")])], 1)], 1)], 1);
              }), _c('b-button', {
                attrs: {
                  "variant": "success",
                  "block": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.addRepairJob(_vm.form_repair_service.data.contents);
                  }
                }
              }, [_c('i', {
                staticClass: "fa fa-plus"
              }), _vm._v(" ADD JOB REPAIR & MAINTENANCE ")])], 2) : _vm._e()], 1), _vm.is_form_created || _vm.form_repair_service.data.type_of_job === 'custom' ? _c('b-col', {
                staticClass: "text-right mt-2",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-card-text', [_c('small', {
                staticClass: "text-muted"
              }, [_vm._v("Sign * : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("Mandatory (Must be Input)")])], 1)]), _c('b-button', {
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_vm.form_repair_service.loading ? _c('span', {
                staticClass: "spinner-border spinner-border-sm"
              }) : _c('span', [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" Save")])]), _c('b-button', {
                staticClass: "iq-bg-danger ml-3",
                attrs: {
                  "type": "button",
                  "variant": "none"
                },
                on: {
                  "click": _vm.resetFormAddServiceAndRepair
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" Reset Form ")])], 1) : _vm._e()], 1)], 1)], 1)], 1)])], 1)], 1)];
            }
          }], null, false, 3118349939),
          model: {
            value: _vm.showInitModalNext,
            callback: function callback($$v) {
              _vm.showInitModalNext = $$v;
            },
            expression: "showInitModalNext"
          }
        }), _c('b-sidebar', {
          attrs: {
            "id": "add-job",
            "title": "ADD JOB REPAIR & MAINTENANCE - " + ((_vm$job_data = _vm.job_data) === null || _vm$job_data === void 0 ? void 0 : _vm$job_data.fleet),
            "width": "100%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var _vm$job_data2, _vm$job_data3, _vm$job_data4, _vm$job_data5, _vm$job_data6, _vm$job_data7;
              var hide = _ref3.hide;
              return [_c('form', {
                attrs: {
                  "action": "#",
                  "method": "post"
                },
                on: {
                  "submit": _vm.setJobPerService
                }
              }, [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Service & Repair Form")]), _c('b-row', [_vm.job_data.type_of_job === 'equipment_init' ? _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('v-jstree', {
                attrs: {
                  "data": _vm.treeInitMaintenance,
                  "show-checkbox": "",
                  "allow-batch": "",
                  "whole-row": ""
                },
                on: {
                  "item-click": _vm.itemClickInitComponent
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_) {
                    return [_c('div', {
                      staticStyle: {
                        "display": "inherit",
                        "width": "200px"
                      }
                    }, [!_.model.loading ? _c('i', {
                      class: _.vm.themeIconClasses,
                      attrs: {
                        "role": "presentation"
                      }
                    }) : _vm._e(), _vm._v(" " + _vm._s(_.model.text) + " ")])];
                  }
                }], null, true)
              })], 1) : _vm._e(), _c('b-col', {
                attrs: {
                  "md": _vm.job_data.type_of_job === 'custom' ? '12' : '8'
                }
              }, [_c('b-row', {
                staticClass: "mt-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "primary"
                }
              }, [_c('div', {
                staticClass: "custom-control custom-control-inline"
              }, [_c('label', [_c('b', [_vm._v("SELECT JOB FROM :")])])]), _c('b-form-group', [_c('b-form-radio-group', {
                attrs: {
                  "id": "type_of_job",
                  "name": "type_of_job",
                  "required": ""
                },
                model: {
                  value: _vm.job_data.type_of_job,
                  callback: function callback($$v) {
                    _vm.$set(_vm.job_data, "type_of_job", $$v);
                  },
                  expression: "job_data.type_of_job"
                }
              }, [_c('b-form-radio', {
                attrs: {
                  "value": "equipment_init"
                }
              }, [_vm._v("From Equipment")]), _c('b-form-radio', {
                attrs: {
                  "value": "custom"
                }
              }, [_vm._v("Custom")])], 1)], 1)], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Detail Information")]), _c('table', {
                staticClass: "table mb-0 table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Lokasi Pekerjaan")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$job_data2 = _vm.job_data) === null || _vm$job_data2 === void 0 ? void 0 : _vm$job_data2.location))]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s(_vm.moment((_vm$job_data3 = _vm.job_data) === null || _vm$job_data3 === void 0 ? void 0 : _vm$job_data3.accident_at).format('DD MMMM YYYY')))])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Office / Site")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$job_data4 = _vm.job_data) === null || _vm$job_data4 === void 0 ? void 0 : _vm$job_data4.site))]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("PIC Repair")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$job_data5 = _vm.job_data) === null || _vm$job_data5 === void 0 ? void 0 : _vm$job_data5.pic))])])])])]), _vm._l((_vm$job_data6 = _vm.job_data) === null || _vm$job_data6 === void 0 ? void 0 : _vm$job_data6.existed_data, function (state, index) {
                var _state$status, _state$status2, _state$status3, _state$status4, _state$status5, _state$task_type, _state$department, _state$category, _state$classification, _state$classification2, _state$classification3, _state$case, _state$form_repair_ac;
                return _c('div', {
                  key: index
                }, [_c('b-card', {
                  attrs: {
                    "no-body": ""
                  }
                }, [_c('b-card-header', {
                  staticClass: "p-1",
                  staticStyle: {
                    "background-color": "white",
                    "border": "none"
                  },
                  attrs: {
                    "header-tag": "header",
                    "role": "tab"
                  }
                }, [_c('b-button', {
                  directives: [{
                    name: "b-toggle",
                    rawName: "v-b-toggle",
                    value: "accordion-" + index,
                    expression: "`accordion-` + index"
                  }],
                  staticClass: "text-left",
                  attrs: {
                    "block": "",
                    "variant": (state === null || state === void 0 ? void 0 : (_state$status = state.status) === null || _state$status === void 0 ? void 0 : _state$status.alias) === 'new' ? 'info' : (state === null || state === void 0 ? void 0 : (_state$status2 = state.status) === null || _state$status2 === void 0 ? void 0 : _state$status2.alias) === 'progress' ? 'warning' : (state === null || state === void 0 ? void 0 : (_state$status3 = state.status) === null || _state$status3 === void 0 ? void 0 : _state$status3.alias) === 'finish' ? 'success' : (state === null || state === void 0 ? void 0 : (_state$status4 = state.status) === null || _state$status4 === void 0 ? void 0 : _state$status4.alias) === 'review' ? 'primary' : 'danger'
                  }
                }, [_c('i', {
                  staticClass: "fa fa-chevron-circle-down"
                }), _vm._v(" " + _vm._s(state === null || state === void 0 ? void 0 : state.name) + " - " + _vm._s(state === null || state === void 0 ? void 0 : state.title) + " "), _c('strong', [_vm._v("(" + _vm._s(state === null || state === void 0 ? void 0 : (_state$status5 = state.status) === null || _state$status5 === void 0 ? void 0 : _state$status5.name) + ")")])])], 1), _c('b-collapse', {
                  attrs: {
                    "id": 'accordion-' + index,
                    "visible": false,
                    "accordion": "my-accordion",
                    "role": "tabpanel"
                  }
                }, [_c('table', {
                  staticClass: "table table-striped mt-3"
                }, [_c('tbody', [_c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Equipment Name")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Repair Title")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.title))])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Type R & M")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$task_type = state.task_type) === null || _state$task_type === void 0 ? void 0 : _state$task_type.name))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Department")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$department = state.department) === null || _state$department === void 0 ? void 0 : _state$department.name))])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Section")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.section))])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Category")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$category = state.category) === null || _state$category === void 0 ? void 0 : _state$category.name))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Classification")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$classification = state.classification) === null || _state$classification === void 0 ? void 0 : _state$classification.name) + " (" + _vm._s(state === null || state === void 0 ? void 0 : (_state$classification2 = state.classification) === null || _state$classification2 === void 0 ? void 0 : _state$classification2.interval) + " " + _vm._s(state === null || state === void 0 ? void 0 : (_state$classification3 = state.classification) === null || _state$classification3 === void 0 ? void 0 : _state$classification3.unit) + ")")])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Cause")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$case = state.case) === null || _state$case === void 0 ? void 0 : _state$case.name))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Kind Task")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v("Repair")])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Rencana Mulai")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(_vm.moment(state === null || state === void 0 ? void 0 : state.start_at).format('DD MMMM YYYY')))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Rencana Selesai (Est)")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(_vm.moment(state === null || state === void 0 ? void 0 : state.actual_finished_at).format('DD MMMM YYYY')))])]), _c('tr', [_c('td', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_c('b', [_vm._v("Estimate Cost")])]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(" " + _vm._s(_vm.formatCurrency(state.cost_estimate)) + " ")]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Estimasi Hari")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.estimate_total_days) + " Hari")])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Berita Acara")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "colspan": "4"
                  }
                }, [_c('ul', {
                  staticClass: "d-flex p-0 m-0"
                }, _vm._l(state === null || state === void 0 ? void 0 : state.file, function (file_data, fileIndex) {
                  return _c('li', {
                    key: "file-" + fileIndex,
                    staticClass: "mr-2"
                  }, [file_data.extension == 'pdf' ? _c('img', {
                    staticStyle: {
                      "width": "70px",
                      "height": "70px"
                    },
                    attrs: {
                      "src": require("../../assets/images/activity/pdf.png"),
                      "alt": "img-image"
                    },
                    on: {
                      "click": function click($event) {
                        return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(file_data.file), "berita_acara");
                      }
                    }
                  }) : _c('img', {
                    staticStyle: {
                      "width": "100px",
                      "height": "70px"
                    },
                    attrs: {
                      "src": "".concat(_vm.apiUrl, "/").concat(file_data === null || file_data === void 0 ? void 0 : file_data.file),
                      "alt": "ing"
                    },
                    on: {
                      "click": function click($event) {
                        return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(file_data.file), "berita_acara");
                      }
                    }
                  })]);
                }), 0)])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Description")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  staticClass: "align-top",
                  attrs: {
                    "colspan": "4"
                  }
                }, [_vm._v(" " + _vm._s(state === null || state === void 0 ? void 0 : state.description) + " ")])])])]), _c('br'), state !== null && state !== void 0 && state.is_internal ? _c('fieldset', {
                  staticClass: "form-group border p-3"
                }, [_c('legend', {
                  staticClass: "w-auto px-2"
                }, [_vm._v("Internal Man Power")]), _c('table', {
                  staticClass: "table table-striped table-sm"
                }, [_c('tbody', [_c('tr', [_c('th', {
                  staticClass: "text-left"
                }, [_vm._v("NAMA")]), _c('th', {
                  staticClass: "text-left"
                }, [_vm._v("JABATAN")])]), _vm._l(state === null || state === void 0 ? void 0 : state.entity_internal, function (entity, entityIndex) {
                  return _c('tr', {
                    key: entityIndex
                  }, [_c('td', {
                    staticClass: "text-left"
                  }, [_vm._v(_vm._s(entity === null || entity === void 0 ? void 0 : entity.internal_name))]), _c('td', {
                    staticClass: "text-left"
                  }, [_vm._v(_vm._s(_vm.positionName(entity === null || entity === void 0 ? void 0 : entity.internal_position_id)))])]);
                })], 2)])]) : _vm._e(), state !== null && state !== void 0 && state.is_subkon ? _c('fieldset', {
                  staticClass: "form-group border p-3"
                }, [_c('legend', {
                  staticClass: "w-auto px-2"
                }, [_vm._v("Sub Kontraktor")]), _c('table', {
                  staticClass: "table table-striped table-lg"
                }, [_c('tbody', [_c('tr', [_c('th', {
                  staticClass: "pl-3"
                }, [_vm._v("NAMA KONTRAKTOR")]), _c('th', [_vm._v("PIC/EXECUTIVE ENGINEER")]), _c('th', {
                  staticClass: "text-center"
                }, [_vm._v("OFFERING")]), _c('th', {
                  staticClass: "text-center"
                }, [_vm._v("NEGOTIATION")]), _c('th', {
                  staticClass: "text-center"
                }, [_vm._v("FINAL NEGOTIATION")]), _c('th', {
                  staticClass: "text-center"
                }, [_vm._v("FILE")])]), _vm._l(state === null || state === void 0 ? void 0 : state.entity_subkon, function (entity, entityIndex) {
                  var _entity$subkon_file;
                  return _c('tr', {
                    key: entityIndex
                  }, [_c('td', {
                    staticClass: "pl-3"
                  }, [_vm._v(_vm._s(entity === null || entity === void 0 ? void 0 : entity.subkon_name))]), _c('td', [_vm._v(_vm._s(entity === null || entity === void 0 ? void 0 : entity.subkon_pic))]), _c('td', {
                    staticClass: "text-center"
                  }, [_vm._v(_vm._s(_vm.formatCurrency(entity === null || entity === void 0 ? void 0 : entity.subkon_price_offering)))]), _c('td', {
                    staticClass: "text-center"
                  }, [_vm._v(_vm._s(_vm.formatCurrency(entity === null || entity === void 0 ? void 0 : entity.subkon_price_nego)))]), _c('td', {
                    staticClass: "text-center"
                  }, [_vm._v(_vm._s(_vm.formatCurrency(entity === null || entity === void 0 ? void 0 : entity.subkon_price_deal)))]), (entity === null || entity === void 0 ? void 0 : (_entity$subkon_file = entity.subkon_file) === null || _entity$subkon_file === void 0 ? void 0 : _entity$subkon_file.length) > 0 ? _c('td', {
                    staticClass: "text-center text-primary"
                  }, _vm._l(entity === null || entity === void 0 ? void 0 : entity.subkon_file, function (fileSubkon, subkonIndexFile) {
                    return _c('div', {
                      key: subkonIndexFile,
                      staticClass: "mr-2"
                    }, [fileSubkon.extension === 'pdf' ? _c('i', {
                      staticClass: "fa fa-file-pdf text-primary h4",
                      staticStyle: {
                        "cursor": "pointer"
                      },
                      on: {
                        "click": function click($event) {
                          return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(fileSubkon.url), "".concat(entity === null || entity === void 0 ? void 0 : entity.subkon_name));
                        }
                      }
                    }) : _c('img', {
                      staticClass: "img-fluid",
                      staticStyle: {
                        "width": "70px",
                        "height": "70px"
                      },
                      attrs: {
                        "src": "".concat(_vm.apiUrl, "/").concat(fileSubkon === null || fileSubkon === void 0 ? void 0 : fileSubkon.url),
                        "alt": ""
                      },
                      on: {
                        "click": function click($event) {
                          return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(fileSubkon.url), "".concat(entity === null || entity === void 0 ? void 0 : entity.subkon_name));
                        }
                      }
                    })]);
                  }), 0) : _vm._e()]);
                })], 2)])]) : _vm._e(), _c('b-row', [_c('b-col', {
                  attrs: {
                    "md": "12"
                  }
                }, [_c('ul', {
                  staticClass: "iq-timeline",
                  staticStyle: {
                    "height": "100%"
                  }
                }, [_vm._l(state === null || state === void 0 ? void 0 : state.form_repair_activity, function (activity, aIndex) {
                  var _activity$activity, _activity$activity2, _activity$activity$na, _activity$activity3, _activity$parsed_file;
                  return _c('li', {
                    key: aIndex
                  }, [_c('div', {
                    staticClass: "timeline-dots border-success"
                  }), _c('h6', {
                    staticClass: "float-left mb-1"
                  }, [_vm._v(_vm._s(activity === null || activity === void 0 ? void 0 : activity.user_name))]), _c('br'), _c('small', [_vm._v(_vm._s(_vm.moment(activity === null || activity === void 0 ? void 0 : activity.date_of_activity).format('DD MMMM YYYY hh:mm')))]), _c('div', {
                    staticClass: "d-inline-block w-100"
                  }, [_c('b-badge', {
                    attrs: {
                      "variant": (activity === null || activity === void 0 ? void 0 : (_activity$activity = activity.activity) === null || _activity$activity === void 0 ? void 0 : _activity$activity.activity_flag) === 'running' ? 'primary' : (activity === null || activity === void 0 ? void 0 : (_activity$activity2 = activity.activity) === null || _activity$activity2 === void 0 ? void 0 : _activity$activity2.activity_flag) === 'finish' ? 'success' : 'danger'
                    }
                  }, [_vm._v(_vm._s((_activity$activity$na = activity === null || activity === void 0 ? void 0 : (_activity$activity3 = activity.activity) === null || _activity$activity3 === void 0 ? void 0 : _activity$activity3.name) !== null && _activity$activity$na !== void 0 ? _activity$activity$na : '-'))]), _vm._v(" "), _c('br'), _vm._v(" "), _c('p', [_c('strong', {
                    staticClass: "text-muted"
                  }, [_vm._v("Notes:")]), _vm._v(" " + _vm._s(activity === null || activity === void 0 ? void 0 : activity.notes))])], 1), (activity === null || activity === void 0 ? void 0 : (_activity$parsed_file = activity.parsed_file) === null || _activity$parsed_file === void 0 ? void 0 : _activity$parsed_file.length) > 0 ? _c('div', {
                    staticClass: "d-inline-block w-100"
                  }, [_c('ul', {
                    staticClass: "d-flex p-0 m-0"
                  }, _vm._l(activity === null || activity === void 0 ? void 0 : activity.parsed_file, function (file, fileIndex) {
                    return _c('li', {
                      key: fileIndex,
                      staticClass: "pb-2"
                    }, [file.extension == 'pdf' ? _c('img', {
                      staticClass: "img-fluid",
                      staticStyle: {
                        "width": "70px",
                        "height": "70px"
                      },
                      attrs: {
                        "src": require("../../assets/images/activity/pdf.png"),
                        "alt": "img-image"
                      },
                      on: {
                        "click": function click($event) {
                          return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(file.url), "".concat(activity === null || activity === void 0 ? void 0 : activity.user_name, "_activity"));
                        }
                      }
                    }) : _c('img', {
                      staticStyle: {
                        "width": "70px",
                        "height": "70px"
                      },
                      attrs: {
                        "src": "".concat(_vm.apiUrl, "/").concat(file.url),
                        "alt": "image"
                      },
                      on: {
                        "click": function click($event) {
                          return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(file.url), "".concat(activity === null || activity === void 0 ? void 0 : activity.user_name, "_activity"));
                        }
                      }
                    })]);
                  }), 0)]) : _vm._e()]);
                }), _c('li', [(state === null || state === void 0 ? void 0 : (_state$form_repair_ac = state.form_repair_activity) === null || _state$form_repair_ac === void 0 ? void 0 : _state$form_repair_ac.length) > 0 ? _c('b-button', {
                  attrs: {
                    "type": "button",
                    "variant": "primary"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.getSingleServiceAndRepair(state.form_id, 10);
                    }
                  }
                }, [_vm._v(" Load More ")]) : _vm._e()], 1)], 2)])], 1)], 1)], 1)], 1);
              }), _c('hr'), _vm.componentIdSelected && !_vm.is_form_created ? _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Selected Equipments")]), _vm.selected_components.length > 0 ? _c('b-row', _vm._l(_vm.selected_components, function (component, componentIndex) {
                return _c('b-col', {
                  key: componentIndex,
                  staticClass: "mb-2",
                  attrs: {
                    "md": "12"
                  }
                }, [_c('div', {
                  staticClass: "p-2 pl-4 pr-4 bg-primary d-flex align-items-center justify-content-between gap-4 rounded"
                }, [_c('span', [_vm._v(_vm._s(component.maintenanceMaster.equipmentId) + " - " + _vm._s(component.maintenanceMaster.equipmentName))]), _c('i', {
                  staticClass: "fa fa-trash text-danger",
                  staticStyle: {
                    "cursor": "pointer"
                  },
                  on: {
                    "click": function click() {
                      _vm.removeSelectedComponent(_vm.selected_components, component.id);
                      _vm.changeStatusIsSelected(component.id);
                    }
                  }
                })])]);
              }), 1) : _vm._e()], 1) : _vm._e(), _c('hr'), _vm.componentIdSelected ? _c('div', [_vm.list_equipment && _vm.list_equipment.length === 0 ? _c('b-row', [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-warning",
                attrs: {
                  "show": true,
                  "variant": " "
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "ri-alert-line"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("For Information Equipment Not Setup for Fleet "), _c('b', [_vm._v(_vm._s(_vm.fleetNameChecked))]), _vm._v(", Please Setup First")])])], 1)], 1) : _vm._e(), _vm.list_equipment.length > 0 && !_vm.is_form_created ? _c('b-row', [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('div', {
                staticClass: "table-responsive"
              }, [_c('table', {
                staticClass: "table mb-0 table-striped table-hover"
              }, [_c('thead', {
                staticClass: "text-center"
              }, [_c('tr', [_c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.all_equipment_selected,
                  expression: "all_equipment_selected"
                }],
                attrs: {
                  "type": "checkbox",
                  "name": _vm.all_equipment_selected
                },
                domProps: {
                  "value": _vm.all_equipment_selected,
                  "checked": Array.isArray(_vm.all_equipment_selected) ? _vm._i(_vm.all_equipment_selected, _vm.all_equipment_selected) > -1 : _vm.all_equipment_selected
                },
                on: {
                  "change": function change($event) {
                    var $$a = _vm.all_equipment_selected,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false;
                    if (Array.isArray($$a)) {
                      var $$v = _vm.all_equipment_selected,
                        $$i = _vm._i($$a, $$v);
                      if ($$el.checked) {
                        $$i < 0 && (_vm.all_equipment_selected = $$a.concat([$$v]));
                      } else {
                        $$i > -1 && (_vm.all_equipment_selected = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                      }
                    } else {
                      _vm.all_equipment_selected = $$c;
                    }
                  }
                }
              })]), _c('td', [_vm._v("Equipment - Code")]), _c('td', [_vm._v("Type - Serial No")]), _c('td', [_vm._v("Maker")]), _c('td', [_vm._v("Job Index")])])]), _c('tbody', _vm._l(_vm.list_equipment, function (components, componentIndex) {
                return _c('tr', {
                  key: components.id
                }, [_c('td', [_c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: _vm.list_equipment[componentIndex].is_selected,
                    expression: "list_equipment[componentIndex].is_selected"
                  }],
                  staticClass: "text-primary",
                  attrs: {
                    "type": "checkbox",
                    "id": _vm.list_equipment[componentIndex].id,
                    "name": "list_equipment[".concat(componentIndex, "].is_selected")
                  },
                  domProps: {
                    "value": _vm.list_equipment[componentIndex].is_selected,
                    "checked": Array.isArray(_vm.list_equipment[componentIndex].is_selected) ? _vm._i(_vm.list_equipment[componentIndex].is_selected, _vm.list_equipment[componentIndex].is_selected) > -1 : _vm.list_equipment[componentIndex].is_selected
                  },
                  on: {
                    "change": [function ($event) {
                      var $$a = _vm.list_equipment[componentIndex].is_selected,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = _vm.list_equipment[componentIndex].is_selected,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(_vm.list_equipment[componentIndex], "is_selected", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(_vm.list_equipment[componentIndex], "is_selected", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(_vm.list_equipment[componentIndex], "is_selected", $$c);
                      }
                    }, function ($event) {
                      return _vm.selectedComponents(components);
                    }]
                  }
                })]), _c('td', [_vm._v(_vm._s(components.maintenanceMaster.equipmentId) + " - " + _vm._s(components.maintenanceMaster.equipmentName))]), _c('td', [_vm._v(_vm._s(components.typeNo) + " - " + _vm._s(components.serialNo))]), _c('td', [_c('ul', [_c('li', [_vm._v("Maker No: " + _vm._s(components.makerNo))]), _c('li', [_vm._v("Maker: " + _vm._s(components.maker))])])]), _c('td', {
                  staticClass: "text-center"
                }, [components.status.length === 0 ? _c('small', [_c('b-badge', {
                  attrs: {
                    "variant": "danger d-block"
                  }
                }, [_vm._v("NOT YET INIT")])], 1) : _c('small', _vm._l(components.status, function (stats, statsIndex) {
                  return _c('b-badge', {
                    key: statsIndex,
                    staticClass: "mb-1",
                    attrs: {
                      "variant": "success d-block"
                    }
                  }, [_vm._v(_vm._s(stats))]);
                }), 1)])]);
              }), 0)])])]), _c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_vm.job_data.type_of_job === 'equipment_init' && !_vm.is_form_created ? _c('b-button', {
                attrs: {
                  "variant": "success",
                  "block": ""
                },
                on: {
                  "click": _vm.createEquipmentForm
                }
              }, [_c('i', {
                staticClass: "fa fa-plus"
              }), _vm._v(" CREATE FORM ")]) : _vm._e()], 1)], 1) : _vm._e()], 1) : _vm._e(), !_vm.componentIdSelected && _vm.job_data.type_of_job === 'equipment_init' ? _c('b-row', [_c('b-col', [_c('b-alert', {
                attrs: {
                  "show": "",
                  "variant": "warning"
                }
              }, [_vm._v("Please select component first!")])], 1)], 1) : _vm._e(), _vm.job_data.type_of_job === 'custom' || _vm.is_form_created ? _c('div', [_vm._l((_vm$job_data7 = _vm.job_data) === null || _vm$job_data7 === void 0 ? void 0 : _vm$job_data7.form_repair, function (state, index) {
                return _c('fieldset', {
                  key: "add-" + index,
                  staticClass: "form-group border p-3"
                }, [_c('legend', {
                  staticClass: "w-auto px-2 text-primary"
                }, [_vm._v("Repair #" + _vm._s(index + 1))]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Equipment Name",
                    "label-for": "equipmentName"
                  }
                }, [_c('b-form-input', {
                  attrs: {
                    "type": "text",
                    "name": "name",
                    "placeholder": "Type Text ...",
                    "required": "",
                    "disabled": _vm.job_data.type_of_job === 'equipment_init'
                  },
                  model: {
                    value: state.name,
                    callback: function callback($$v) {
                      _vm.$set(state, "name", $$v);
                    },
                    expression: "state.name"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Work Item",
                    "label-for": "repairTitle"
                  }
                }, [_c('b-form-input', {
                  attrs: {
                    "type": "text",
                    "name": "title",
                    "placeholder": "Type Text ...",
                    "required": ""
                  },
                  model: {
                    value: state.title,
                    callback: function callback($$v) {
                      _vm.$set(state, "title", $$v);
                    },
                    expression: "state.title"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Type R & M *",
                    "label-for": "type_repair"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "name": "task_type_id",
                    "options": _vm.optionsTaskType,
                    "size": "lg",
                    "required": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": null
                        }
                      }, [_vm._v("Select Type R & M")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: state.task_type_id,
                    callback: function callback($$v) {
                      _vm.$set(state, "task_type_id", $$v);
                    },
                    expression: "state.task_type_id"
                  }
                })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', {
                  attrs: {
                    "md": "2"
                  }
                }, [_c('b-form-group', {
                  attrs: {
                    "label": "Department *",
                    "label-for": "department"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "name": "department_id",
                    "options": _vm.optionsDepartment,
                    "size": "lg",
                    "required": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": null
                        }
                      }, [_vm._v("Select Department")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: state.department_id,
                    callback: function callback($$v) {
                      _vm.$set(state, "department_id", $$v);
                    },
                    expression: "state.department_id"
                  }
                })], 1)], 1), _c('b-col', {
                  attrs: {
                    "md": "2"
                  }
                }, [_c('b-form-group', {
                  attrs: {
                    "label": "Section *",
                    "label-for": "section"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "name": "section",
                    "size": "lg",
                    "required": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": ''
                        }
                      }, [_vm._v("Select Section")]), _c('b-form-select-option', {
                        attrs: {
                          "value": 'DECK'
                        }
                      }, [_vm._v("Deck")]), _c('b-form-select-option', {
                        attrs: {
                          "value": 'ENGINE'
                        }
                      }, [_vm._v("Engine")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: state.section,
                    callback: function callback($$v) {
                      _vm.$set(state, "section", $$v);
                    },
                    expression: "state.section"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Category *",
                    "label-for": "category"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "name": "category_id",
                    "options": _vm.optionsCategory,
                    "size": "lg",
                    "required": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": null
                        }
                      }, [_vm._v("Select Category")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: state.category_id,
                    callback: function callback($$v) {
                      _vm.$set(state, "category_id", $$v);
                    },
                    expression: "state.category_id"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Classification *",
                    "label-for": "classification"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "name": "classification_id",
                    "options": _vm.optionsClassification,
                    "size": "lg",
                    "required": ""
                  },
                  on: {
                    "change": function change($event) {
                      return _vm.handleCountDaysBasedClassification(state);
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": null
                        }
                      }, [_vm._v("Select Classification")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: state.classification_id,
                    callback: function callback($$v) {
                      _vm.$set(state, "classification_id", $$v);
                    },
                    expression: "state.classification_id"
                  }
                })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Cause *",
                    "label-for": "cause"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "name": "case_id",
                    "options": _vm.optionsCause,
                    "size": "lg",
                    "required": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": null
                        }
                      }, [_vm._v("Select Cause")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: state.case_id,
                    callback: function callback($$v) {
                      _vm.$set(state, "case_id", $$v);
                    },
                    expression: "state.case_id"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Kind of Task *",
                    "label-for": "kind_of_task"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "name": "kind_task_id",
                    "options": _vm.optionsKindTask,
                    "size": "lg",
                    "required": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": null
                        }
                      }, [_vm._v("Select Kind of Task")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: state.kind_task_id,
                    callback: function callback($$v) {
                      _vm.$set(state, "kind_task_id", $$v);
                    },
                    expression: "state.kind_task_id"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Berita Acara",
                    "label-for": "attactment_file"
                  }
                }, [_c('b-form-file', {
                  attrs: {
                    "plain": "",
                    "multiple": "",
                    "name": "file",
                    "id": "attactment_file",
                    "accept": "image/*,application/*"
                  },
                  model: {
                    value: state.file,
                    callback: function callback($$v) {
                      _vm.$set(state, "file", $$v);
                    },
                    expression: "state.file"
                  }
                })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Rencana Mulai *",
                    "label-for": "mulai"
                  }
                }, [_c('b-form-input', {
                  attrs: {
                    "type": "date",
                    "name": "start_at",
                    "id": "mulai",
                    "required": ""
                  },
                  on: {
                    "change": function change() {
                      _vm.calculateDays(state);
                      _vm.handleCountDaysBasedClassification(state);
                    }
                  },
                  model: {
                    value: state.start_at,
                    callback: function callback($$v) {
                      _vm.$set(state, "start_at", $$v);
                    },
                    expression: "state.start_at"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Rencana Selesai *",
                    "label-for": "selesai"
                  }
                }, [_c('b-form-input', {
                  attrs: {
                    "type": "date",
                    "name": "",
                    "id": "selesai",
                    "required": ""
                  },
                  on: {
                    "change": function change($event) {
                      return _vm.calculateDays(state);
                    }
                  },
                  model: {
                    value: state.estimated_finished_at,
                    callback: function callback($$v) {
                      _vm.$set(state, "estimated_finished_at", $$v);
                    },
                    expression: "state.estimated_finished_at"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Estimasi Hari",
                    "label-for": "estimasi_hari"
                  }
                }, [_c('b-button', {
                  staticClass: "mb-1",
                  attrs: {
                    "variant": "outline-primary"
                  }
                }, [_vm._v(" Perkiraan Pekerjaan "), _c('b-badge', {
                  staticClass: "ml-2",
                  attrs: {
                    "variant": "primary"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.estimate_total_days) + " Hari")])], 1)], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Description",
                    "label-for": "description"
                  }
                }, [_c('b-form-textarea', {
                  attrs: {
                    "id": "description",
                    "rows": "3",
                    "name": "",
                    "placeholder": "Type Text ..."
                  },
                  model: {
                    value: state.description,
                    callback: function callback($$v) {
                      _vm.$set(state, "description", $$v);
                    },
                    expression: "state.description"
                  }
                })], 1)], 1)], 1), _c('b-row', {
                  staticClass: "mt-2"
                }, [_c('b-col', {
                  attrs: {
                    "md": "12"
                  }
                }, [_c('b-button', {
                  staticClass: "pull-right",
                  attrs: {
                    "variant": "danger",
                    "size": "sm"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.removeRepairJob(_vm.job_data.form_repair, index);
                    }
                  }
                }, [_c('i', {
                  staticClass: "fa fa-trash"
                }), _vm._v(" Delete Repair Job ")])], 1)], 1)], 1);
              }), _c('b-button', {
                attrs: {
                  "variant": "success",
                  "block": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.addRepairJob(_vm.job_data.form_repair);
                  }
                }
              }, [_c('i', {
                staticClass: "fa fa-plus"
              }), _vm._v(" ADD JOB REPAIR & MAINTENANCE ")])], 2) : _vm._e()], 2), _vm.is_form_created || _vm.job_data.type_of_job === 'custom' ? _c('b-col', {
                staticClass: "text-right mt-2",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-card-text', [_c('small', {
                staticClass: "text-muted"
              }, [_vm._v("Sign * : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("Mandatory (Must be Input)")])], 1)]), _c('b-button', {
                attrs: {
                  "type": "submit",
                  "variant": "primary",
                  "disabled": _vm.job_loading
                }
              }, [_vm.job_loading ? _c('span', {
                staticClass: "spinner-border spinner-border-sm"
              }) : _c('span', [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" Save")])]), _c('b-button', {
                staticClass: "iq-bg-danger ml-3",
                attrs: {
                  "type": "button",
                  "variant": "none"
                },
                on: {
                  "click": _vm.resetFormAddServiceAndRepair
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" Reset Form ")])], 1) : _vm._e()], 1)], 1)], 1)], 1)])], 1)], 1)];
            }
          }], null, false, 4263264252),
          model: {
            value: _vm.isModalAddJob,
            callback: function callback($$v) {
              _vm.isModalAddJob = $$v;
            },
            expression: "isModalAddJob"
          }
        }), _c('b-sidebar', {
          attrs: {
            "id": "review_sr",
            "title": "REVIEW REPAIR & MAINTENANCE - " + ((_vm$repair_data = _vm.repair_data) === null || _vm$repair_data === void 0 ? void 0 : _vm$repair_data.fleet),
            "width": "70%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var _vm$repair_data2, _vm$repair_data3, _vm$repair_data4, _vm$repair_data5, _vm$repair_data6;
              var hide = _ref4.hide;
              return [_c('form', {
                attrs: {
                  "action": "#",
                  "method": "post"
                },
                on: {
                  "submit": _vm.onSaveReviewRepair
                }
              }, [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Detail Information")]), _c('table', {
                staticClass: "table mb-0 table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Lokasi Pekerjaan")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$repair_data2 = _vm.repair_data) === null || _vm$repair_data2 === void 0 ? void 0 : _vm$repair_data2.location))]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s(_vm.moment((_vm$repair_data3 = _vm.repair_data) === null || _vm$repair_data3 === void 0 ? void 0 : _vm$repair_data3.accident_at).format('DD MMMM YYYY')))])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Office / Site")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$repair_data4 = _vm.repair_data) === null || _vm$repair_data4 === void 0 ? void 0 : _vm$repair_data4.site))]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("PIC Repair")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$repair_data5 = _vm.repair_data) === null || _vm$repair_data5 === void 0 ? void 0 : _vm$repair_data5.pic))])])])])]), _vm._l((_vm$repair_data6 = _vm.repair_data) === null || _vm$repair_data6 === void 0 ? void 0 : _vm$repair_data6.form_repair, function (state, index) {
                var _state$task_type2, _state$department2, _state$category2, _state$classification4, _state$classification5, _state$classification6, _state$case2;
                return _c('div', {
                  key: index
                }, [_c('b-card', {
                  attrs: {
                    "no-body": ""
                  }
                }, [_c('b-card-header', {
                  staticClass: "p-1",
                  staticStyle: {
                    "background-color": "white",
                    "border": "none"
                  },
                  attrs: {
                    "header-tag": "header",
                    "role": "tab"
                  }
                }, [_c('b-button', {
                  directives: [{
                    name: "b-toggle",
                    rawName: "v-b-toggle",
                    value: "accordion-" + index,
                    expression: "`accordion-` + index"
                  }],
                  staticClass: "text-left",
                  attrs: {
                    "block": "",
                    "variant": "info"
                  }
                }, [_c('i', {
                  staticClass: "fa fa-chevron-circle-down"
                }), _vm._v(" " + _vm._s(state === null || state === void 0 ? void 0 : state.name) + " - " + _vm._s(state === null || state === void 0 ? void 0 : state.title) + " ")])], 1), _c('b-collapse', {
                  attrs: {
                    "id": 'accordion-' + index,
                    "visible": false,
                    "accordion": "my-accordion",
                    "role": "tabpanel"
                  }
                }, [_c('table', {
                  staticClass: "table table-striped mt-3"
                }, [_c('tbody', [_c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Equipment Name")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Repair Title")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.title))])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Type R & M")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$task_type2 = state.task_type) === null || _state$task_type2 === void 0 ? void 0 : _state$task_type2.name))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Department")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$department2 = state.department) === null || _state$department2 === void 0 ? void 0 : _state$department2.name))])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Section")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.section))])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Category")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$category2 = state.category) === null || _state$category2 === void 0 ? void 0 : _state$category2.name))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Classification")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$classification4 = state.classification) === null || _state$classification4 === void 0 ? void 0 : _state$classification4.name) + " (" + _vm._s(state === null || state === void 0 ? void 0 : (_state$classification5 = state.classification) === null || _state$classification5 === void 0 ? void 0 : _state$classification5.interval) + " " + _vm._s(state === null || state === void 0 ? void 0 : (_state$classification6 = state.classification) === null || _state$classification6 === void 0 ? void 0 : _state$classification6.unit) + ")")])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Cause")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$case2 = state.case) === null || _state$case2 === void 0 ? void 0 : _state$case2.name))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Kind Task")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v("Repair")])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Rencana Mulai")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(_vm.moment(state === null || state === void 0 ? void 0 : state.start_at).format('DD MMMM YYYY')))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Rencana Selesai (Est)")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(_vm.moment(state === null || state === void 0 ? void 0 : state.actual_finished_at).format('DD MMMM YYYY')))])]), _c('tr', [_c('td', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_c('b', [_vm._v("Estimate Cost")])]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_c('div', {
                  staticClass: "input-group"
                }, [_c('div', {
                  staticClass: "input-group-prepend"
                }, [_c('span', {
                  staticClass: "input-group-text bg-primary",
                  attrs: {
                    "id": "inputGroupPrepend2"
                  }
                }, [_vm._v("Rp.")])]), _c('b-form-input', {
                  staticClass: "form-control",
                  attrs: {
                    "type": "number",
                    "required": "",
                    "id": "cost_estimate",
                    "name": "cost_estimate",
                    "placeholder": "Type Number ..."
                  },
                  model: {
                    value: state.cost_estimate,
                    callback: function callback($$v) {
                      _vm.$set(state, "cost_estimate", $$v);
                    },
                    expression: "state.cost_estimate"
                  }
                })], 1), _c('b-form-text', {
                  staticClass: "text-muted"
                }, [_vm._v(" " + _vm._s(_vm.formatCurrency(state.cost_estimate)) + " ")])], 1), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Estimasi Hari")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.estimate_total_days) + " Hari")])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Berita Acara")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "colspan": "4"
                  }
                }, [_c('ul', {
                  staticClass: "d-flex p-0 m-0"
                }, _vm._l(state === null || state === void 0 ? void 0 : state.file, function (file_data, fileIndex) {
                  return _c('li', {
                    key: "file-" + fileIndex,
                    staticClass: "mr-2"
                  }, [file_data.extension == 'pdf' ? _c('img', {
                    staticStyle: {
                      "width": "70px",
                      "height": "70px"
                    },
                    attrs: {
                      "src": require("../../assets/images/activity/pdf.png"),
                      "alt": "img-image"
                    },
                    on: {
                      "click": function click($event) {
                        return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(_vm.file_Data.file), "berita_acara");
                      }
                    }
                  }) : _c('img', {
                    staticStyle: {
                      "width": "100px",
                      "height": "70px"
                    },
                    attrs: {
                      "src": "".concat(_vm.apiUrl, "/").concat(file_data === null || file_data === void 0 ? void 0 : file_data.file),
                      "alt": "ing"
                    },
                    on: {
                      "click": function click($event) {
                        return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(_vm.file_Data.file), "berita_acara");
                      }
                    }
                  })]);
                }), 0)])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Description")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  staticClass: "align-top",
                  attrs: {
                    "colspan": "4"
                  }
                }, [_vm._v(" " + _vm._s(state === null || state === void 0 ? void 0 : state.description) + " ")])])])]), _c('hr'), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                  staticClass: "col-md-12"
                }, [_c('b-form-checkbox', {
                  attrs: {
                    "name": "check-button",
                    "switch": ""
                  },
                  model: {
                    value: state.is_internal,
                    callback: function callback($$v) {
                      _vm.$set(state, "is_internal", $$v);
                    },
                    expression: "state.is_internal"
                  }
                }, [_c('strong', [_vm._v("Internal Man Power")])])], 1)], 1)], 1), state !== null && state !== void 0 && state.is_internal ? _c('fieldset', {
                  staticClass: "form-group border p-3"
                }, [_c('legend', {
                  staticClass: "w-auto px-2"
                }, [_vm._v("Internal Man Power")]), _c('table', {
                  staticClass: "table table-borderless"
                }, [_c('tbody', [_c('tr', {
                  staticClass: "iq-bg-primary"
                }, [_c('th', {
                  attrs: {
                    "width": "45%"
                  }
                }, [_vm._v("NAMA LENGKAP")]), _c('th', {
                  attrs: {
                    "width": "45%"
                  }
                }, [_vm._v("JABATAN")]), _c('th', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "10%"
                  }
                }, [_vm._v("ACTION")])]), _vm._l(state === null || state === void 0 ? void 0 : state.review_internal, function (internal, internalIndex) {
                  return _c('tr', {
                    key: internalIndex
                  }, [_c('td', [_c('b-form-input', {
                    attrs: {
                      "type": "text",
                      "id": "nama_internal_man_power",
                      "size": "sm",
                      "placeholder": "Type Text ..."
                    },
                    model: {
                      value: internal.internal_name,
                      callback: function callback($$v) {
                        _vm.$set(internal, "internal_name", $$v);
                      },
                      expression: "internal.internal_name"
                    }
                  })], 1), _c('td', [_c('b-form-select', {
                    attrs: {
                      "plain": "",
                      "options": _vm.optionsJabatan,
                      "size": "sm"
                    },
                    scopedSlots: _vm._u([{
                      key: "first",
                      fn: function fn() {
                        return [_c('b-form-select-option', {
                          attrs: {
                            "value": null
                          }
                        }, [_vm._v("Select Jabatan")])];
                      },
                      proxy: true
                    }], null, true),
                    model: {
                      value: internal.internal_position_id,
                      callback: function callback($$v) {
                        _vm.$set(internal, "internal_position_id", $$v);
                      },
                      expression: "internal.internal_position_id"
                    }
                  })], 1), _c('td', {
                    staticClass: "text-center"
                  }, [_c('b-button', {
                    attrs: {
                      "variant": "danger"
                    },
                    on: {
                      "click": function click($event) {
                        return _vm.removeInternal(index, internalIndex);
                      }
                    }
                  }, [_c('i', {
                    staticClass: "fa fa-trash pr-0"
                  })])], 1)]);
                })], 2)]), _c('b-button', {
                  attrs: {
                    "variant": "success",
                    "type": "button"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.addInternal(state.id, index);
                    }
                  }
                }, [_c('i', {
                  staticClass: "fa fa-users"
                }), _vm._v(" ADD INTERNAL MAN POWER ")])], 1) : _vm._e(), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                  staticClass: "col-md-12"
                }, [_c('b-form-checkbox', {
                  attrs: {
                    "name": "check-button",
                    "switch": ""
                  },
                  model: {
                    value: state.is_subkon,
                    callback: function callback($$v) {
                      _vm.$set(state, "is_subkon", $$v);
                    },
                    expression: "state.is_subkon"
                  }
                }, [_c('strong', [_vm._v("Sub Kontraktor")])])], 1)], 1)], 1), state !== null && state !== void 0 && state.is_subkon ? _c('fieldset', {
                  staticClass: "form-group border p-3"
                }, [_c('legend', {
                  staticClass: "w-auto px-2"
                }, [_vm._v("Sub Kontraktor")]), _vm._l(state === null || state === void 0 ? void 0 : state.review_subkon, function (subkon, subkonIndex) {
                  return _c('div', {
                    key: subkonIndex
                  }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
                    attrs: {
                      "label": "Nama Kontraktor",
                      "label-for": "nama_kontraktor"
                    }
                  }, [_c('b-form-input', {
                    attrs: {
                      "type": "text",
                      "name": "subkon_name",
                      "id": "subkon_name",
                      "placeholder": "Type Text ..."
                    },
                    model: {
                      value: subkon.subkon_name,
                      callback: function callback($$v) {
                        _vm.$set(subkon, "subkon_name", $$v);
                      },
                      expression: "subkon.subkon_name"
                    }
                  })], 1)], 1), _c('b-col', [_c('b-form-group', {
                    attrs: {
                      "label": "PIC / Executive Engineer",
                      "label-for": "executive_engineer"
                    }
                  }, [_c('b-form-input', {
                    attrs: {
                      "type": "text",
                      "name": "subkon_pic",
                      "id": "subkon_pic",
                      "placeholder": "Type Text ..."
                    },
                    model: {
                      value: subkon.subkon_pic,
                      callback: function callback($$v) {
                        _vm.$set(subkon, "subkon_pic", $$v);
                      },
                      expression: "subkon.subkon_pic"
                    }
                  })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                    attrs: {
                      "label": "PIC Email",
                      "label-for": "nama_kontraktor"
                    }
                  }, [_c('b-form-input', {
                    attrs: {
                      "type": "text",
                      "name": "subkon_pic_email",
                      "id": "subkon_pic_email",
                      "placeholder": "Type Text ..."
                    },
                    model: {
                      value: subkon.subkon_pic_email,
                      callback: function callback($$v) {
                        _vm.$set(subkon, "subkon_pic_email", $$v);
                      },
                      expression: "subkon.subkon_pic_email"
                    }
                  })], 1)], 1), _c('b-col', [_c('b-form-group', {
                    attrs: {
                      "label": "PIC Phone",
                      "label-for": "executive_engineer"
                    }
                  }, [_c('b-form-input', {
                    attrs: {
                      "type": "number",
                      "name": "subkon_pic_phone",
                      "id": "subkon_pic_phone",
                      "placeholder": "Type Text ..."
                    },
                    model: {
                      value: subkon.subkon_pic_phone,
                      callback: function callback($$v) {
                        _vm.$set(subkon, "subkon_pic_phone", $$v);
                      },
                      expression: "subkon.subkon_pic_phone"
                    }
                  })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                    attrs: {
                      "label": "Penawaran",
                      "label-for": "penawaran"
                    }
                  }, [_c('div', {
                    staticClass: "input-group"
                  }, [_c('div', {
                    staticClass: "input-group-prepend"
                  }, [_c('span', {
                    staticClass: "input-group-text bg-primary",
                    attrs: {
                      "id": "inputGroupPrepend2"
                    }
                  }, [_vm._v("Rp.")])]), _c('input', {
                    directives: [{
                      name: "model",
                      rawName: "v-model",
                      value: subkon.subkon_price_offering,
                      expression: "subkon.subkon_price_offering"
                    }],
                    staticClass: "form-control",
                    attrs: {
                      "type": "number",
                      "name": "subkon_price_offering",
                      "id": "subkon_price_offering",
                      "placeholder": "Type Number ..."
                    },
                    domProps: {
                      "value": subkon.subkon_price_offering
                    },
                    on: {
                      "input": function input($event) {
                        if ($event.target.composing) return;
                        _vm.$set(subkon, "subkon_price_offering", $event.target.value);
                      }
                    }
                  })]), _c('b-form-text', {
                    staticClass: "text-muted"
                  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(Number(subkon.subkon_price_offering))) + " ")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                    attrs: {
                      "label": "Penawaran Nego",
                      "label-for": "penawaran_nego"
                    }
                  }, [_c('div', {
                    staticClass: "input-group"
                  }, [_c('div', {
                    staticClass: "input-group-prepend"
                  }, [_c('span', {
                    staticClass: "input-group-text bg-primary",
                    attrs: {
                      "id": "inputGroupPrepend2"
                    }
                  }, [_vm._v("Rp.")])]), _c('input', {
                    directives: [{
                      name: "model",
                      rawName: "v-model",
                      value: subkon.subkon_price_nego,
                      expression: "subkon.subkon_price_nego"
                    }],
                    staticClass: "form-control",
                    attrs: {
                      "type": "number",
                      "name": "subkon_price_nego",
                      "id": "subkon_price_nego",
                      "placeholder": "Type Number ..."
                    },
                    domProps: {
                      "value": subkon.subkon_price_nego
                    },
                    on: {
                      "input": function input($event) {
                        if ($event.target.composing) return;
                        _vm.$set(subkon, "subkon_price_nego", $event.target.value);
                      }
                    }
                  })]), _c('b-form-text', {
                    staticClass: "text-muted"
                  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(Number(subkon.subkon_price_nego))) + " ")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                    attrs: {
                      "label": "Deal Harga",
                      "label-for": "subkon_price_deal"
                    }
                  }, [_c('div', {
                    staticClass: "input-group"
                  }, [_c('div', {
                    staticClass: "input-group-prepend"
                  }, [_c('span', {
                    staticClass: "input-group-text bg-primary",
                    attrs: {
                      "id": "inputGroupPrepend2"
                    }
                  }, [_vm._v("Rp.")])]), _c('input', {
                    directives: [{
                      name: "model",
                      rawName: "v-model",
                      value: subkon.subkon_price_deal,
                      expression: "subkon.subkon_price_deal"
                    }],
                    staticClass: "form-control",
                    attrs: {
                      "type": "number",
                      "name": "subkon_price_deal",
                      "id": "subkon_price_deal",
                      "placeholder": "Type Number ..."
                    },
                    domProps: {
                      "value": subkon.subkon_price_deal
                    },
                    on: {
                      "input": function input($event) {
                        if ($event.target.composing) return;
                        _vm.$set(subkon, "subkon_price_deal", $event.target.value);
                      }
                    }
                  })]), _c('b-form-text', {
                    staticClass: "text-muted"
                  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(Number(subkon.subkon_price_deal))) + " ")])], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                    attrs: {
                      "label": "Notes",
                      "label-for": "notes"
                    }
                  }, [_c('b-form-textarea', {
                    attrs: {
                      "name": "subkon_notes",
                      "id": "subkon_notes",
                      "rows": "2",
                      "placeholder": "Type Text ..."
                    },
                    model: {
                      value: subkon.subkon_notes,
                      callback: function callback($$v) {
                        _vm.$set(subkon, "subkon_notes", $$v);
                      },
                      expression: "subkon.subkon_notes"
                    }
                  })], 1)], 1), _c('b-col', [_c('b-form-group', {
                    attrs: {
                      "label": "Berita Acara",
                      "label-for": "subkon_file"
                    }
                  }, [_c('b-form-file', {
                    attrs: {
                      "plain": "",
                      "multiple": "",
                      "name": "subkon_file",
                      "id": "subkon_file"
                    },
                    model: {
                      value: subkon.subkon_file,
                      callback: function callback($$v) {
                        _vm.$set(subkon, "subkon_file", $$v);
                      },
                      expression: "subkon.subkon_file"
                    }
                  }), _c('small', {
                    staticClass: "text-muted"
                  }, [_vm._v(" File Type * : "), _c('b-badge', {
                    attrs: {
                      "variant": "info"
                    }
                  }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                    attrs: {
                      "variant": "info"
                    }
                  }, [_vm._v("3 MB / File")])], 1)], 1)], 1)], 1), _c('div', {
                    staticClass: "d-flex w-100 align-items-end justify-content-end"
                  }, [_c('b-button', {
                    attrs: {
                      "variant": "danger"
                    },
                    on: {
                      "click": function click($event) {
                        return _vm.removeSubkon(index, subkonIndex);
                      }
                    }
                  }, [_c('i', {
                    staticClass: "fa fa-trash pr-0"
                  })])], 1)], 1);
                }), _c('hr'), _c('b-button', {
                  attrs: {
                    "variant": "success",
                    "type": "button"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.addSubkon(state.id, index);
                    }
                  }
                }, [_c('i', {
                  staticClass: "fa fa-users"
                }), _vm._v(" ADD SUB KONTRAKTOR ")])], 2) : _vm._e()], 1)], 1)], 1);
              })], 2), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                staticClass: "mt-2 pull-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_vm.review_loading ? _c('span', {
                staticClass: "spinner-border spinner-border-sm"
              }) : _c('span', [_vm._v("REVIEW & SUBMIT")])])], 1)], 1)], 1)];
            }
          }], null, false, 3471312480)
        }), _c('b-sidebar', {
          attrs: {
            "id": "approve_sr",
            "title": "APPROVE REPAIR & MAINTENANCE - " + ((_vm$approve_sr_data = _vm.approve_sr_data) === null || _vm$approve_sr_data === void 0 ? void 0 : _vm$approve_sr_data.fleet),
            "width": "70%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var _vm$approve_sr_data2, _vm$approve_sr_data3, _vm$approve_sr_data4, _vm$approve_sr_data5, _vm$approve_sr_data6;
              var hide = _ref5.hide;
              return [_c('form', {
                attrs: {
                  "action": "#",
                  "method": "put"
                },
                on: {
                  "submit": _vm.onApproveServiceAndRepair
                }
              }, [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Detail Information")]), _c('table', {
                staticClass: "table mb-0 table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Lokasi Pekerjaan")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$approve_sr_data2 = _vm.approve_sr_data) === null || _vm$approve_sr_data2 === void 0 ? void 0 : _vm$approve_sr_data2.location))]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s(_vm.moment((_vm$approve_sr_data3 = _vm.approve_sr_data) === null || _vm$approve_sr_data3 === void 0 ? void 0 : _vm$approve_sr_data3.accident_at).format('DD MMMM YYYY')))])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Office / Site")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$approve_sr_data4 = _vm.approve_sr_data) === null || _vm$approve_sr_data4 === void 0 ? void 0 : _vm$approve_sr_data4.site))]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("PIC Repair")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$approve_sr_data5 = _vm.approve_sr_data) === null || _vm$approve_sr_data5 === void 0 ? void 0 : _vm$approve_sr_data5.pic))])])])])]), _vm._l((_vm$approve_sr_data6 = _vm.approve_sr_data) === null || _vm$approve_sr_data6 === void 0 ? void 0 : _vm$approve_sr_data6.form_repair, function (state, index) {
                var _state$task_type3, _state$department3, _state$category3, _state$classification7, _state$classification8, _state$classification9, _state$case3, _state$kind_task;
                return _c('div', {
                  key: index
                }, [_c('b-card', {
                  attrs: {
                    "no-body": ""
                  }
                }, [_c('b-card-header', {
                  staticClass: "p-1",
                  staticStyle: {
                    "background-color": "white",
                    "border": "none"
                  },
                  attrs: {
                    "header-tag": "header",
                    "role": "tab"
                  }
                }, [_c('b-button', {
                  directives: [{
                    name: "b-toggle",
                    rawName: "v-b-toggle",
                    value: "accordion-" + index,
                    expression: "`accordion-` + index"
                  }],
                  staticClass: "text-left",
                  attrs: {
                    "block": "",
                    "variant": "info"
                  }
                }, [_c('i', {
                  staticClass: "fa fa-chevron-circle-down"
                }), _vm._v(" " + _vm._s(state === null || state === void 0 ? void 0 : state.name) + " - " + _vm._s(state === null || state === void 0 ? void 0 : state.title) + " ")])], 1), _c('b-collapse', {
                  attrs: {
                    "id": 'accordion-' + index,
                    "visible": false,
                    "accordion": "my-accordion",
                    "role": "tabpanel"
                  }
                }, [_c('table', {
                  staticClass: "table table-striped mt-3"
                }, [_c('tbody', [_c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Equipment Name")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Repair Title")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.title))])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Type R & M")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$task_type3 = state.task_type) === null || _state$task_type3 === void 0 ? void 0 : _state$task_type3.name))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Department")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$department3 = state.department) === null || _state$department3 === void 0 ? void 0 : _state$department3.name))])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Section")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.section))])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Category")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$category3 = state.category) === null || _state$category3 === void 0 ? void 0 : _state$category3.name))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Classification")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$classification7 = state.classification) === null || _state$classification7 === void 0 ? void 0 : _state$classification7.name) + " (" + _vm._s(state === null || state === void 0 ? void 0 : (_state$classification8 = state.classification) === null || _state$classification8 === void 0 ? void 0 : _state$classification8.interval) + " " + _vm._s(state === null || state === void 0 ? void 0 : (_state$classification9 = state.classification) === null || _state$classification9 === void 0 ? void 0 : _state$classification9.unit) + ")")])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Cause")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$case3 = state.case) === null || _state$case3 === void 0 ? void 0 : _state$case3.name))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Kind Task")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$kind_task = state.kind_task) === null || _state$kind_task === void 0 ? void 0 : _state$kind_task.name))])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Rencana Mulai")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(_vm.moment(state === null || state === void 0 ? void 0 : state.start_at).format('YYYY MMM DD')))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Rencana Selesai (Est)")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(_vm.moment(new Date(state === null || state === void 0 ? void 0 : state.estimated_finished_at)).format('YYYY MMM DD')))])]), _c('tr', [_c('td', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_c('b', [_vm._v("Estimate Cost")])]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(_vm.formatCurrency(state === null || state === void 0 ? void 0 : state.cost_estimate)))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Estimasi Hari")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.estimate_total_days) + " Hari")])]), _c('tr', [_c('td', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_c('b', [_vm._v("Cost Real")])]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_c('div', {
                  staticClass: "input-group"
                }, [_c('div', {
                  staticClass: "input-group-prepend"
                }, [_c('span', {
                  staticClass: "input-group-text bg-primary",
                  attrs: {
                    "id": "inputGroupPrepend2"
                  }
                }, [_vm._v("Rp.")])]), _c('b-form-input', {
                  staticClass: "form-control",
                  attrs: {
                    "type": "number",
                    "required": "",
                    "id": "cost_real",
                    "name": "cost_real",
                    "placeholder": "Type Number ..."
                  },
                  model: {
                    value: state.cost_real,
                    callback: function callback($$v) {
                      _vm.$set(state, "cost_real", $$v);
                    },
                    expression: "state.cost_real"
                  }
                })], 1), _c('b-form-text', {
                  staticClass: "text-muted"
                }, [_vm._v(" " + _vm._s(_vm.formatCurrency(state.cost_real)) + " ")])], 1), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                })]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Berita Acara")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "colspan": "4"
                  }
                }, [_c('ul', {
                  staticClass: "d-flex p-0 m-0"
                }, _vm._l(state === null || state === void 0 ? void 0 : state.file, function (file_data, fileIndex) {
                  return _c('li', {
                    key: "file-" + fileIndex,
                    staticClass: "mr-2"
                  }, [file_data.extension == 'pdf' ? _c('img', {
                    staticStyle: {
                      "width": "70px",
                      "height": "70px"
                    },
                    attrs: {
                      "src": require("../../assets/images/activity/pdf.png"),
                      "alt": "img-image"
                    },
                    on: {
                      "click": function click($event) {
                        return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(_vm.file_Data.file), "berita_acara");
                      }
                    }
                  }) : _c('img', {
                    staticStyle: {
                      "width": "100px",
                      "height": "70px"
                    },
                    attrs: {
                      "src": "".concat(_vm.apiUrl, "/").concat(file_data === null || file_data === void 0 ? void 0 : file_data.file),
                      "alt": "img"
                    },
                    on: {
                      "click": function click($event) {
                        return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(_vm.file_Data.file), "berita_acara");
                      }
                    }
                  })]);
                }), 0)])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Description")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  staticClass: "align-top",
                  attrs: {
                    "colspan": "4"
                  }
                }, [_vm._v(" " + _vm._s(state === null || state === void 0 ? void 0 : state.description) + " ")])])])]), _c('hr'), state !== null && state !== void 0 && state.is_internal ? _c('fieldset', {
                  staticClass: "form-group border p-3"
                }, [_c('legend', {
                  staticClass: "w-auto px-2"
                }, [_vm._v("Internal Man Power")]), _c('table', {
                  staticClass: "table table-striped table-sm"
                }, [_c('tbody', [_c('tr', [_c('th', {
                  staticClass: "text-left"
                }, [_vm._v("NAMA")]), _c('th', {
                  staticClass: "text-left"
                }, [_vm._v("JABATAN")])]), _vm._l(state === null || state === void 0 ? void 0 : state.entity_internal, function (entity, entityIndex) {
                  return _c('tr', {
                    key: entityIndex
                  }, [_c('td', {
                    staticClass: "text-left"
                  }, [_vm._v(_vm._s(entity === null || entity === void 0 ? void 0 : entity.internal_name))]), _c('td', {
                    staticClass: "text-left"
                  }, [_vm._v(_vm._s(_vm.positionName(entity === null || entity === void 0 ? void 0 : entity.internal_position_id)))])]);
                })], 2)])]) : _vm._e(), state !== null && state !== void 0 && state.is_subkon ? _c('fieldset', {
                  staticClass: "form-group border p-3"
                }, [_c('legend', {
                  staticClass: "w-auto px-2"
                }, [_vm._v("Sub Kontraktor")]), _c('table', {
                  staticClass: "table table-striped table-sm"
                }, [_c('tbody', [_c('tr', [_c('th', {
                  staticClass: "pl-3"
                }, [_vm._v("NAMA KONTRAKTOR")]), _c('th', [_vm._v("PIC/EXECUTIVE ENGINEER")]), _c('th', {
                  staticClass: "text-center"
                }, [_vm._v("OFFERING")]), _c('th', {
                  staticClass: "text-center"
                }, [_vm._v("NEGOTIATION")]), _c('th', {
                  staticClass: "text-center"
                }, [_vm._v("FINAL NEGOTIATION")]), _c('th', {
                  staticClass: "text-center"
                }, [_vm._v("FILE")])]), _vm._l(state === null || state === void 0 ? void 0 : state.entity_subkon, function (entity, entityIndex) {
                  var _entity$subkon_file2;
                  return _c('tr', {
                    key: entityIndex
                  }, [_c('td', {
                    staticClass: "pl-3"
                  }, [_vm._v(_vm._s(entity === null || entity === void 0 ? void 0 : entity.subkon_name))]), _c('td', [_vm._v(_vm._s(entity === null || entity === void 0 ? void 0 : entity.subkon_pic))]), _c('td', {
                    staticClass: "text-center"
                  }, [_vm._v(_vm._s(_vm.formatCurrency(entity === null || entity === void 0 ? void 0 : entity.subkon_price_offering)))]), _c('td', {
                    staticClass: "text-center"
                  }, [_vm._v(_vm._s(_vm.formatCurrency(entity === null || entity === void 0 ? void 0 : entity.subkon_price_nego)))]), _c('td', {
                    staticClass: "text-center"
                  }, [_vm._v(_vm._s(_vm.formatCurrency(entity === null || entity === void 0 ? void 0 : entity.subkon_price_deal)))]), (entity === null || entity === void 0 ? void 0 : (_entity$subkon_file2 = entity.subkon_file) === null || _entity$subkon_file2 === void 0 ? void 0 : _entity$subkon_file2.length) > 0 ? _c('td', {
                    staticClass: "text-center text-primary"
                  }, _vm._l(entity === null || entity === void 0 ? void 0 : entity.subkon_file, function (fileSubkon, subkonIndexFile) {
                    return _c('div', {
                      key: subkonIndexFile,
                      staticClass: "pb-2"
                    }, [fileSubkon.extension === 'pdf' ? _c('i', {
                      staticClass: "fa fa-file-pdf text-primary h4",
                      staticStyle: {
                        "cursor": "pointer"
                      },
                      on: {
                        "click": function click($event) {
                          return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(fileSubkon.url), "".concat(entity === null || entity === void 0 ? void 0 : entity.subkon_name));
                        }
                      }
                    }) : _c('img', {
                      staticClass: "img-fluid",
                      staticStyle: {
                        "width": "70px",
                        "height": "70px"
                      },
                      attrs: {
                        "src": "".concat(_vm.apiUrl, "/").concat(fileSubkon === null || fileSubkon === void 0 ? void 0 : fileSubkon.url),
                        "alt": ""
                      },
                      on: {
                        "click": function click($event) {
                          return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(fileSubkon.url), "".concat(entity === null || entity === void 0 ? void 0 : entity.subkon_name));
                        }
                      }
                    })]);
                  }), 0) : _vm._e()]);
                })], 2)])]) : _vm._e(), (state === null || state === void 0 ? void 0 : state.status_repair) === true || (state === null || state === void 0 ? void 0 : state.status_repair) === null ? _c('b-alert', {
                  attrs: {
                    "show": true,
                    "variant": "success"
                  }
                }, [_c('div', {
                  staticClass: "iq-alert-icon"
                }, [_c('i', {
                  staticClass: "fa fa-thumbs-up"
                })]), _c('div', {
                  staticClass: "iq-alert-text"
                }, [_c('b', [_vm._v("APPROVED")])])]) : _vm._e(), (state === null || state === void 0 ? void 0 : state.status_repair) === false || (state === null || state === void 0 ? void 0 : state.status_repair) === null ? _c('b-alert', {
                  attrs: {
                    "show": true,
                    "variant": "danger"
                  }
                }, [_c('div', {
                  staticClass: "iq-alert-icon"
                }, [_c('i', {
                  staticClass: "fa fa-close"
                })]), _c('div', {
                  staticClass: "iq-alert-text"
                }, [_c('b', [_vm._v("REJECT")])])]) : _vm._e(), _c('b-button', {
                  staticClass: "iq-bg-danger float-right mb-3 ml-1",
                  attrs: {
                    "type": "button",
                    "variant": "none"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.changeStatusRepair(state, false);
                    }
                  }
                }, [_c('i', {
                  staticClass: "fa fa-close"
                }), _vm._v(" REJECT")]), _c('b-button', {
                  staticClass: "float-right",
                  attrs: {
                    "type": "button",
                    "variant": "success"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.changeStatusRepair(state, true);
                    }
                  }
                }, [_c('i', {
                  staticClass: "fa fa-thumbs-up"
                }), _vm._v(" APPROVE JOB")])], 1)], 1)], 1);
              })], 2), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                staticClass: "mt-2 pull-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_vm.approve_sr_loading ? _c('span', {
                staticClass: "spinner-border spinner-border-sm"
              }) : _c('span', [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" APPROVE ALL & SUBMIT")])])], 1)], 1)], 1)];
            }
          }], null, false, 2114006362)
        }), _c('b-sidebar', {
          attrs: {
            "id": "detail_sr",
            "title": "DETAIL REPAIR & MAINTENANCE - " + ((_vm$detail_sr_data = _vm.detail_sr_data) === null || _vm$detail_sr_data === void 0 ? void 0 : _vm$detail_sr_data.fleet),
            "width": "70%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var _vm$detail_sr_data2, _vm$detail_sr_data3, _vm$detail_sr_data4, _vm$detail_sr_data5, _vm$detail_sr_data6;
              var hide = _ref6.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Detail Information")]), _c('table', {
                staticClass: "table mb-0 table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Lokasi Pekerjaan")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$detail_sr_data2 = _vm.detail_sr_data) === null || _vm$detail_sr_data2 === void 0 ? void 0 : _vm$detail_sr_data2.location))]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s(_vm.moment((_vm$detail_sr_data3 = _vm.detail_sr_data) === null || _vm$detail_sr_data3 === void 0 ? void 0 : _vm$detail_sr_data3.accident_at).format('DD MMMM YYYY')))])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Office / Site")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$detail_sr_data4 = _vm.detail_sr_data) === null || _vm$detail_sr_data4 === void 0 ? void 0 : _vm$detail_sr_data4.site))]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("PIC Repair")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$detail_sr_data5 = _vm.detail_sr_data) === null || _vm$detail_sr_data5 === void 0 ? void 0 : _vm$detail_sr_data5.pic))])])])])]), _vm._l((_vm$detail_sr_data6 = _vm.detail_sr_data) === null || _vm$detail_sr_data6 === void 0 ? void 0 : _vm$detail_sr_data6.form_repair, function (state, index) {
                var _state$status6, _state$status7, _state$status8, _state$status9, _state$status10, _state$task_type4, _state$department4, _state$category4, _state$classification10, _state$classification11, _state$classification12, _state$case4, _state$kind_task2, _state$form_repair_ac2;
                return _c('div', {
                  key: index
                }, [_c('b-card', {
                  attrs: {
                    "no-body": ""
                  }
                }, [_c('b-card-header', {
                  staticClass: "p-1",
                  staticStyle: {
                    "background-color": "white",
                    "border": "none"
                  },
                  attrs: {
                    "header-tag": "header",
                    "role": "tab"
                  }
                }, [_c('b-button', {
                  directives: [{
                    name: "b-toggle",
                    rawName: "v-b-toggle",
                    value: "accordion-" + index,
                    expression: "`accordion-` + index"
                  }],
                  staticClass: "text-left",
                  attrs: {
                    "block": "",
                    "variant": (state === null || state === void 0 ? void 0 : (_state$status6 = state.status) === null || _state$status6 === void 0 ? void 0 : _state$status6.alias) === 'new' ? 'info' : (state === null || state === void 0 ? void 0 : (_state$status7 = state.status) === null || _state$status7 === void 0 ? void 0 : _state$status7.alias) === 'progress' ? 'warning' : (state === null || state === void 0 ? void 0 : (_state$status8 = state.status) === null || _state$status8 === void 0 ? void 0 : _state$status8.alias) === 'finish' ? 'success' : (state === null || state === void 0 ? void 0 : (_state$status9 = state.status) === null || _state$status9 === void 0 ? void 0 : _state$status9.alias) === 'review' ? 'primary' : 'danger'
                  }
                }, [_c('i', {
                  staticClass: "fa fa-chevron-circle-down"
                }), _vm._v(" " + _vm._s(state === null || state === void 0 ? void 0 : state.name) + " - " + _vm._s(state === null || state === void 0 ? void 0 : state.title) + " "), _c('strong', [_vm._v("(" + _vm._s(state === null || state === void 0 ? void 0 : (_state$status10 = state.status) === null || _state$status10 === void 0 ? void 0 : _state$status10.name) + ")")])])], 1), _c('b-collapse', {
                  attrs: {
                    "id": 'accordion-' + index,
                    "visible": false,
                    "accordion": "my-accordion",
                    "role": "tabpanel"
                  }
                }, [_c('div', {
                  staticClass: "d-flex w-100 align-items-center justify-content-end text-danger mt-2"
                }, [_c('b-button', {
                  attrs: {
                    "variant": "danger"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.onDeleteRepair(state);
                    }
                  }
                }, [_vm._v(" Delete repair "), _c('i', {
                  staticClass: "fa fa-trash ml-2"
                })])], 1), _c('table', {
                  staticClass: "table table-striped mt-3"
                }, [_c('tbody', [_c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Equipment Name")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Repair Title")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.title))])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Type R & M")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$task_type4 = state.task_type) === null || _state$task_type4 === void 0 ? void 0 : _state$task_type4.name))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Department")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$department4 = state.department) === null || _state$department4 === void 0 ? void 0 : _state$department4.name))])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Section")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.section))])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Category")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$category4 = state.category) === null || _state$category4 === void 0 ? void 0 : _state$category4.name))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Classification")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$classification10 = state.classification) === null || _state$classification10 === void 0 ? void 0 : _state$classification10.name) + " (" + _vm._s(state === null || state === void 0 ? void 0 : (_state$classification11 = state.classification) === null || _state$classification11 === void 0 ? void 0 : _state$classification11.interval) + " " + _vm._s(state === null || state === void 0 ? void 0 : (_state$classification12 = state.classification) === null || _state$classification12 === void 0 ? void 0 : _state$classification12.unit) + ")")])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Cause")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$case4 = state.case) === null || _state$case4 === void 0 ? void 0 : _state$case4.name))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Kind Task")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$kind_task2 = state.kind_task) === null || _state$kind_task2 === void 0 ? void 0 : _state$kind_task2.name))])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Rencana Mulai")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(_vm.moment(state === null || state === void 0 ? void 0 : state.start_at).format('YYYY MMM DD')))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Rencana Selesai (Est)")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(_vm.moment(new Date(state === null || state === void 0 ? void 0 : state.estimated_finished_at)).format('YYYY MMM DD')))])]), _c('tr', [_c('td', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_c('b', [_vm._v("Estimate Cost")])]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(_vm.formatCurrency(state === null || state === void 0 ? void 0 : state.cost_estimate)))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Estimasi Hari")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.estimate_total_days) + " Hari")])]), _c('tr', [_c('td', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_c('b', [_vm._v("Cost Real")])]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(_vm.formatCurrency(state === null || state === void 0 ? void 0 : state.cost_real)))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                })]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Berita Acara")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "colspan": "4"
                  }
                }, [_c('ul', {
                  staticClass: "d-flex p-0 m-0"
                }, _vm._l(state === null || state === void 0 ? void 0 : state.file, function (file_data, fileIndex) {
                  return _c('li', {
                    key: "file-" + fileIndex,
                    staticClass: "mr-2"
                  }, [file_data.extension == 'pdf' ? _c('img', {
                    staticStyle: {
                      "width": "70px",
                      "height": "70px"
                    },
                    attrs: {
                      "src": require("../../assets/images/activity/pdf.png"),
                      "alt": "img-image"
                    },
                    on: {
                      "click": function click($event) {
                        return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(file_data.file), "berita_acara");
                      }
                    }
                  }) : _c('img', {
                    staticStyle: {
                      "width": "100px",
                      "height": "70px"
                    },
                    attrs: {
                      "src": "".concat(_vm.apiUrl, "/").concat(file_data === null || file_data === void 0 ? void 0 : file_data.file),
                      "alt": "ing"
                    },
                    on: {
                      "click": function click($event) {
                        return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(file_data.file), "berita_acara");
                      }
                    }
                  })]);
                }), 0)])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Description")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  staticClass: "align-top",
                  attrs: {
                    "colspan": "4"
                  }
                }, [_vm._v(" " + _vm._s(state === null || state === void 0 ? void 0 : state.description) + " ")])])])]), _c('br'), state !== null && state !== void 0 && state.is_internal ? _c('fieldset', {
                  staticClass: "form-group border p-3"
                }, [_c('legend', {
                  staticClass: "w-auto px-2"
                }, [_vm._v("Internal Man Power")]), _c('table', {
                  staticClass: "table table-striped table-sm"
                }, [_c('tbody', [_c('tr', [_c('th', {
                  staticClass: "text-left"
                }, [_vm._v("NAMA")]), _c('th', {
                  staticClass: "text-left"
                }, [_vm._v("JABATAN")])]), _vm._l(state === null || state === void 0 ? void 0 : state.entity_internal, function (entity, entityIndex) {
                  return _c('tr', {
                    key: entityIndex
                  }, [_c('td', {
                    staticClass: "text-left"
                  }, [_vm._v(_vm._s(entity === null || entity === void 0 ? void 0 : entity.internal_name))]), _c('td', {
                    staticClass: "text-left"
                  }, [_vm._v(_vm._s(_vm.positionName(entity === null || entity === void 0 ? void 0 : entity.internal_position_id)))])]);
                })], 2)])]) : _vm._e(), state !== null && state !== void 0 && state.is_subkon ? _c('fieldset', {
                  staticClass: "form-group border p-3"
                }, [_c('legend', {
                  staticClass: "w-auto px-2"
                }, [_vm._v("Sub Kontraktor")]), _c('table', {
                  staticClass: "table table-striped table-lg"
                }, [_c('tbody', [_c('tr', [_c('th', {
                  staticClass: "pl-3"
                }, [_vm._v("NAMA KONTRAKTOR")]), _c('th', [_vm._v("PIC/EXECUTIVE ENGINEER")]), _c('th', {
                  staticClass: "text-center"
                }, [_vm._v("OFFERING")]), _c('th', {
                  staticClass: "text-center"
                }, [_vm._v("NEGOTIATION")]), _c('th', {
                  staticClass: "text-center"
                }, [_vm._v("FINAL NEGOTIATION")]), _c('th', {
                  staticClass: "text-center"
                }, [_vm._v("FILE")])]), _vm._l(state === null || state === void 0 ? void 0 : state.entity_subkon, function (entity, entityIndex) {
                  var _entity$subkon_file3;
                  return _c('tr', {
                    key: entityIndex
                  }, [_c('td', {
                    staticClass: "pl-3"
                  }, [_vm._v(_vm._s(entity === null || entity === void 0 ? void 0 : entity.subkon_name))]), _c('td', [_vm._v(_vm._s(entity === null || entity === void 0 ? void 0 : entity.subkon_pic))]), _c('td', {
                    staticClass: "text-center"
                  }, [_vm._v(_vm._s(_vm.formatCurrency(entity === null || entity === void 0 ? void 0 : entity.subkon_price_offering)))]), _c('td', {
                    staticClass: "text-center"
                  }, [_vm._v(_vm._s(_vm.formatCurrency(entity === null || entity === void 0 ? void 0 : entity.subkon_price_nego)))]), _c('td', {
                    staticClass: "text-center"
                  }, [_vm._v(_vm._s(_vm.formatCurrency(entity === null || entity === void 0 ? void 0 : entity.subkon_price_deal)))]), (entity === null || entity === void 0 ? void 0 : (_entity$subkon_file3 = entity.subkon_file) === null || _entity$subkon_file3 === void 0 ? void 0 : _entity$subkon_file3.length) > 0 ? _c('td', {
                    staticClass: "text-center text-primary"
                  }, _vm._l(entity === null || entity === void 0 ? void 0 : entity.subkon_file, function (fileSubkon, subkonIndexFile) {
                    return _c('div', {
                      key: subkonIndexFile,
                      staticClass: "pb-2"
                    }, [fileSubkon.extension === 'pdf' ? _c('i', {
                      staticClass: "fa fa-file-pdf text-primary h4",
                      staticStyle: {
                        "cursor": "pointer"
                      },
                      on: {
                        "click": function click($event) {
                          return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(fileSubkon.url), "".concat(entity === null || entity === void 0 ? void 0 : entity.subkon_name));
                        }
                      }
                    }) : _c('img', {
                      staticStyle: {
                        "width": "70px",
                        "height": "70px"
                      },
                      attrs: {
                        "src": "".concat(_vm.apiUrl, "/").concat(fileSubkon === null || fileSubkon === void 0 ? void 0 : fileSubkon.url),
                        "alt": ""
                      },
                      on: {
                        "click": function click($event) {
                          return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(fileSubkon.url), "".concat(entity === null || entity === void 0 ? void 0 : entity.subkon_name));
                        }
                      }
                    })]);
                  }), 0) : _vm._e()]);
                })], 2)])]) : _vm._e(), _c('b-row', [_c('b-col', {
                  attrs: {
                    "md": "12"
                  }
                }, [_c('ul', {
                  staticClass: "iq-timeline",
                  staticStyle: {
                    "height": "100%"
                  }
                }, [_vm._l(state === null || state === void 0 ? void 0 : state.form_repair_activity, function (activity, aIndex) {
                  var _activity$activity4, _activity$activity5, _activity$activity$na2, _activity$activity6, _activity$parsed_file2;
                  return _c('li', {
                    key: aIndex
                  }, [_c('div', {
                    staticClass: "timeline-dots border-success"
                  }), _c('h6', {
                    staticClass: "float-left mb-1"
                  }, [_vm._v(_vm._s(activity === null || activity === void 0 ? void 0 : activity.user_name))]), _c('br'), _c('small', [_vm._v(_vm._s(_vm.moment(activity === null || activity === void 0 ? void 0 : activity.date_of_activity).format('DD MMMM YYYY hh:mm')))]), _c('div', {
                    staticClass: "d-inline-block w-100"
                  }, [_c('b-badge', {
                    attrs: {
                      "variant": (activity === null || activity === void 0 ? void 0 : (_activity$activity4 = activity.activity) === null || _activity$activity4 === void 0 ? void 0 : _activity$activity4.activity_flag) === 'running' ? 'primary' : (activity === null || activity === void 0 ? void 0 : (_activity$activity5 = activity.activity) === null || _activity$activity5 === void 0 ? void 0 : _activity$activity5.activity_flag) === 'finish' ? 'success' : 'danger'
                    }
                  }, [_vm._v(_vm._s((_activity$activity$na2 = activity === null || activity === void 0 ? void 0 : (_activity$activity6 = activity.activity) === null || _activity$activity6 === void 0 ? void 0 : _activity$activity6.name) !== null && _activity$activity$na2 !== void 0 ? _activity$activity$na2 : '-'))]), _vm._v(" "), _c('br'), _vm._v(" "), _c('p', [_c('strong', {
                    staticClass: "text-muted"
                  }, [_vm._v("Notes:")]), _vm._v(" " + _vm._s(activity === null || activity === void 0 ? void 0 : activity.notes))])], 1), (activity === null || activity === void 0 ? void 0 : (_activity$parsed_file2 = activity.parsed_file) === null || _activity$parsed_file2 === void 0 ? void 0 : _activity$parsed_file2.length) > 0 ? _c('div', {
                    staticClass: "d-inline-block w-100"
                  }, [_c('ul', {
                    staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
                  }, _vm._l(activity === null || activity === void 0 ? void 0 : activity.parsed_file, function (file, fileIndex) {
                    return _c('li', {
                      key: fileIndex,
                      staticClass: "pb-2"
                    }, [file.extension == 'pdf' ? _c('img', {
                      staticStyle: {
                        "width": "70px",
                        "height": "70px"
                      },
                      attrs: {
                        "src": require("../../assets/images/activity/pdf.png"),
                        "alt": "gallary-image"
                      },
                      on: {
                        "click": function click($event) {
                          return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(file.url), "".concat(activity === null || activity === void 0 ? void 0 : activity.user_name, "_activity"));
                        }
                      }
                    }) : _c('img', {
                      staticStyle: {
                        "width": "70px",
                        "height": "70px"
                      },
                      attrs: {
                        "src": "".concat(_vm.apiUrl, "/").concat(file.url),
                        "alt": "image"
                      },
                      on: {
                        "click": function click($event) {
                          return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(file.url), "".concat(activity === null || activity === void 0 ? void 0 : activity.user_name, "_activity"));
                        }
                      }
                    })]);
                  }), 0)]) : _vm._e()]);
                }), _c('li', [(state === null || state === void 0 ? void 0 : (_state$form_repair_ac2 = state.form_repair_activity) === null || _state$form_repair_ac2 === void 0 ? void 0 : _state$form_repair_ac2.length) > 0 ? _c('b-button', {
                  attrs: {
                    "type": "button",
                    "variant": "primary"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.getSingleServiceAndRepair(state.form_id, 10);
                    }
                  }
                }, [_vm._v(" Load More ")]) : _vm._e()], 1)], 2)])], 1)], 1)], 1)], 1);
              })], 2)], 1)];
            }
          }], null, false, 2529798137)
        }), _c('b-sidebar', {
          attrs: {
            "id": "update_sr",
            "title": "UPDATE REPAIR & MAINTENANCE - " + ((_vm$update_jobs_data = _vm.update_jobs_data) === null || _vm$update_jobs_data === void 0 ? void 0 : _vm$update_jobs_data.fleet),
            "width": "70%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var _vm$update_jobs_data2;
              var hide = _ref7.hide;
              return [_c('form', {
                attrs: {
                  "action": "#",
                  "method": "post"
                },
                on: {
                  "submit": _vm.updateJobsService
                }
              }, [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Detail Information")]), _c('table', {
                staticClass: "table mb-0 table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Lokasi Pekerjaan")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "name": "location",
                  "type": "text",
                  "placeholder": "Type Text ..."
                },
                model: {
                  value: _vm.update_jobs_data.location,
                  callback: function callback($$v) {
                    _vm.$set(_vm.update_jobs_data, "location", $$v);
                  },
                  expression: "update_jobs_data.location"
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "name": "accident_at",
                  "type": "date"
                },
                model: {
                  value: _vm.update_jobs_data.accident_at,
                  callback: function callback($$v) {
                    _vm.$set(_vm.update_jobs_data, "accident_at", $$v);
                  },
                  expression: "update_jobs_data.accident_at"
                }
              })], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Office / Site")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "name": "site",
                  "type": "text",
                  "placeholder": "Type Text ..."
                },
                model: {
                  value: _vm.update_jobs_data.site,
                  callback: function callback($$v) {
                    _vm.$set(_vm.update_jobs_data, "site", $$v);
                  },
                  expression: "update_jobs_data.site"
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("PIC Repair")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "name": "pic",
                  "type": "text",
                  "placeholder": "Type Text ..."
                },
                model: {
                  value: _vm.update_jobs_data.pic,
                  callback: function callback($$v) {
                    _vm.$set(_vm.update_jobs_data, "pic", $$v);
                  },
                  expression: "update_jobs_data.pic"
                }
              })], 1)])])])]), _vm._l((_vm$update_jobs_data2 = _vm.update_jobs_data) === null || _vm$update_jobs_data2 === void 0 ? void 0 : _vm$update_jobs_data2.form_repair, function (state, index) {
                var _state$file_existed;
                return _c('div', {
                  key: index
                }, [_c('b-card', {
                  attrs: {
                    "no-body": ""
                  }
                }, [_c('b-card-header', {
                  staticClass: "p-1",
                  staticStyle: {
                    "background-color": "white",
                    "border": "none"
                  },
                  attrs: {
                    "header-tag": "header",
                    "role": "tab"
                  }
                }, [_c('b-button', {
                  directives: [{
                    name: "b-toggle",
                    rawName: "v-b-toggle",
                    value: "accordion-" + index,
                    expression: "`accordion-` + index"
                  }],
                  staticClass: "text-left",
                  attrs: {
                    "block": "",
                    "variant": "info"
                  }
                }, [_c('i', {
                  staticClass: "fa fa-chevron-circle-down"
                }), _vm._v(" " + _vm._s(state === null || state === void 0 ? void 0 : state.name) + " - " + _vm._s(state === null || state === void 0 ? void 0 : state.title) + " ")])], 1), _c('b-collapse', {
                  attrs: {
                    "id": 'accordion-' + index,
                    "visible": false,
                    "accordion": "my-accordion",
                    "role": "tabpanel"
                  }
                }, [_c('fieldset', {
                  staticClass: "form-group border p-3"
                }, [_c('legend', {
                  staticClass: "w-auto px-2 text-primary"
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name) + " - " + _vm._s(state === null || state === void 0 ? void 0 : state.title) + " ")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Equipment Name",
                    "label-for": "equipmentName"
                  }
                }, [_c('b-form-input', {
                  attrs: {
                    "disabled": "",
                    "type": "text",
                    "name": "name",
                    "placeholder": "Type Text ...",
                    "required": ""
                  },
                  model: {
                    value: state.name,
                    callback: function callback($$v) {
                      _vm.$set(state, "name", $$v);
                    },
                    expression: "state.name"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Work Item",
                    "label-for": "repairTitle"
                  }
                }, [_c('b-form-input', {
                  attrs: {
                    "type": "text",
                    "name": "title",
                    "placeholder": "Type Text ...",
                    "required": ""
                  },
                  model: {
                    value: state.title,
                    callback: function callback($$v) {
                      _vm.$set(state, "title", $$v);
                    },
                    expression: "state.title"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Type R & M *",
                    "label-for": "type_repair"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "name": "task_type_id",
                    "options": _vm.optionsTaskType,
                    "size": "lg",
                    "required": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": null
                        }
                      }, [_vm._v("Select Type R & M")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: state.task_type_id,
                    callback: function callback($$v) {
                      _vm.$set(state, "task_type_id", $$v);
                    },
                    expression: "state.task_type_id"
                  }
                })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', {
                  attrs: {
                    "md": "2"
                  }
                }, [_c('b-form-group', {
                  attrs: {
                    "label": "Department *",
                    "label-for": "department"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "name": "department_id",
                    "options": _vm.optionsDepartment,
                    "size": "lg",
                    "required": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": null
                        }
                      }, [_vm._v("Select Department")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: state.department_id,
                    callback: function callback($$v) {
                      _vm.$set(state, "department_id", $$v);
                    },
                    expression: "state.department_id"
                  }
                })], 1)], 1), _c('b-col', {
                  attrs: {
                    "md": "2"
                  }
                }, [_c('b-form-group', {
                  attrs: {
                    "label": "Section *",
                    "label-for": "section"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "name": "section",
                    "size": "lg",
                    "required": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": ''
                        }
                      }, [_vm._v("Select Section")]), _c('b-form-select-option', {
                        attrs: {
                          "value": 'DECK'
                        }
                      }, [_vm._v("Deck")]), _c('b-form-select-option', {
                        attrs: {
                          "value": 'ENGINE'
                        }
                      }, [_vm._v("Engine")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: state.section,
                    callback: function callback($$v) {
                      _vm.$set(state, "section", $$v);
                    },
                    expression: "state.section"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Category *",
                    "label-for": "category"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "name": "category_id",
                    "options": _vm.optionsCategory,
                    "size": "lg",
                    "required": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": null
                        }
                      }, [_vm._v("Select Category")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: state.category_id,
                    callback: function callback($$v) {
                      _vm.$set(state, "category_id", $$v);
                    },
                    expression: "state.category_id"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Classification *",
                    "label-for": "classification"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "name": "classification_id",
                    "options": _vm.optionsClassification,
                    "size": "lg",
                    "required": ""
                  },
                  on: {
                    "change": function change($event) {
                      return _vm.handleCountDaysBasedClassification(state);
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": null
                        }
                      }, [_vm._v("Select Classification")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: state.classification_id,
                    callback: function callback($$v) {
                      _vm.$set(state, "classification_id", $$v);
                    },
                    expression: "state.classification_id"
                  }
                })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Cause *",
                    "label-for": "cause"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "name": "case_id",
                    "options": _vm.optionsCause,
                    "size": "lg",
                    "required": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": null
                        }
                      }, [_vm._v("Select Cause")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: state.case_id,
                    callback: function callback($$v) {
                      _vm.$set(state, "case_id", $$v);
                    },
                    expression: "state.case_id"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Kind of Task *",
                    "label-for": "kind_of_task"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "name": "kind_task_id",
                    "options": _vm.optionsKindTask,
                    "size": "lg",
                    "required": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": null
                        }
                      }, [_vm._v("Select Kind of Task")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: state.kind_task_id,
                    callback: function callback($$v) {
                      _vm.$set(state, "kind_task_id", $$v);
                    },
                    expression: "state.kind_task_id"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Berita Acara",
                    "label-for": "attactment_file"
                  }
                }, [_c('b-form-file', {
                  attrs: {
                    "plain": "",
                    "multiple": "",
                    "name": "file",
                    "id": "attactment_file"
                  },
                  model: {
                    value: state.file,
                    callback: function callback($$v) {
                      _vm.$set(state, "file", $$v);
                    },
                    expression: "state.file"
                  }
                }), (state === null || state === void 0 ? void 0 : (_state$file_existed = state.file_existed) === null || _state$file_existed === void 0 ? void 0 : _state$file_existed.length) > 0 ? _c('b-form-text', {
                  staticClass: "text-primary",
                  staticStyle: {
                    "cursor": "pointer"
                  }
                }, [_c('strong', [_vm._v("View Existed File")])]) : _vm._e()], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Rencana Mulai *",
                    "label-for": "mulai"
                  }
                }, [_c('b-form-input', {
                  attrs: {
                    "type": "date",
                    "name": "start_at",
                    "id": "mulai",
                    "required": ""
                  },
                  on: {
                    "change": function change() {
                      _vm.calculateDays(state);
                      _vm.handleCountDaysBasedClassification(state);
                    }
                  },
                  model: {
                    value: state.start_at,
                    callback: function callback($$v) {
                      _vm.$set(state, "start_at", $$v);
                    },
                    expression: "state.start_at"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Rencana Selesai *",
                    "label-for": "selesai"
                  }
                }, [_c('b-form-input', {
                  attrs: {
                    "type": "date",
                    "name": "",
                    "id": "selesai",
                    "required": ""
                  },
                  on: {
                    "change": function change($event) {
                      return _vm.calculateDays(state);
                    }
                  },
                  model: {
                    value: state.estimated_finished_at,
                    callback: function callback($$v) {
                      _vm.$set(state, "estimated_finished_at", $$v);
                    },
                    expression: "state.estimated_finished_at"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Estimasi Hari",
                    "label-for": "estimasi_hari"
                  }
                }, [_c('b-button', {
                  staticClass: "mb-1",
                  attrs: {
                    "variant": "outline-primary"
                  }
                }, [_vm._v(" Perkiraan Pekerjaan "), _c('b-badge', {
                  staticClass: "ml-2",
                  attrs: {
                    "variant": "primary"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.estimate_total_days) + " Hari")])], 1)], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Description",
                    "label-for": "description"
                  }
                }, [_c('b-form-textarea', {
                  attrs: {
                    "id": "description",
                    "rows": "3",
                    "name": "",
                    "placeholder": "Type Text ..."
                  },
                  model: {
                    value: state.description,
                    callback: function callback($$v) {
                      _vm.$set(state, "description", $$v);
                    },
                    expression: "state.description"
                  }
                })], 1)], 1)], 1)], 1)])], 1)], 1);
              })], 2), _c('b-col', {
                staticClass: "text-right",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-card-text', [_c('small', {
                staticClass: "text-muted"
              }, [_vm._v("Sign * : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("Mandatory (Must be Input)")])], 1)]), _c('b-button', {
                attrs: {
                  "type": "submit",
                  "variant": "primary",
                  "disabled": _vm.update_jobs_loading
                }
              }, [_vm.update_jobs_loading ? _c('span', {
                staticClass: "spinner-border spinner-border-sm"
              }) : _c('span', [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" Save")])]), _c('b-button', {
                staticClass: "iq-bg-danger ml-3",
                attrs: {
                  "type": "button",
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" Reset Form ")])], 1)], 1)], 1)];
            }
          }], null, false, 2387068714)
        })];
      },
      proxy: true
    }], null, false, 3575784715)
  }) : _vm._e(), !_vm.request_loading ? _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("PROGRESS REPAIR & MAINTENANCE")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$list_table_progre, _vm$list_table_progre2, _vm$list_table_progre3;
        return [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("FLEET NAME")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("LOCATION")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TANGGAL")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL R & M")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("CLASSIFICATION")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("OFFICE/PIC")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', _vm._l((_vm$list_table_progre = _vm.list_table_progress) === null || _vm$list_table_progre === void 0 ? void 0 : _vm$list_table_progre.data, function (state, index) {
          return _c('tr', {
            key: "progress-" + index
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(index + 1))]), _c('td', {
            staticClass: "text-left"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.fleet))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.location))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(_vm.moment(state === null || state === void 0 ? void 0 : state.accident_at).format('DD MMMM YYYY')))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.total_repair))]), _c('td', {
            staticClass: "text-center"
          }, _vm._l(state.classification, function (classification, classIndex) {
            return _c('span', {
              key: classIndex
            }, [_c('b-badge', {
              staticClass: "mb-1",
              attrs: {
                "variant": "".concat(classification.name === 'Minor' ? 'success' : classification.name === 'Medium' ? 'info' : 'danger', " d-block")
              }
            }, [_vm._v(" " + _vm._s(classification.name) + " : " + _vm._s(classification.total) + " Repair ")])], 1);
          }), 0), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.site)), _c('br'), _c('small', [_vm._v("PIC : "), _c('strong', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.pic))])])]), _c('td', {
            staticClass: "text-center"
          }, [_c('b-dropdown', {
            attrs: {
              "size": "lg",
              "variant": "link iq-bg-primary iq-border-radius-10",
              "toggle-class": "text-decoration-none",
              "no-caret": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function fn() {
                return [_c('span', {
                  staticClass: "dropdown-toggle p-0",
                  attrs: {
                    "id": "dropdownMenuButton5",
                    "data-toggle": "dropdown"
                  }
                }, [_c('i', {
                  staticClass: "fa fa-pencil-square-o m-0 text-primary"
                })])];
              },
              proxy: true
            }], null, true)
          }, [!_vm.readOnly ? _c('b-dropdown-item', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle.add-activity",
              modifiers: {
                "add-activity": true
              }
            }],
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                return _vm.getListRepairService(state.id, state === null || state === void 0 ? void 0 : state.fleet);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-comments mr-2"
          }), _vm._v("Update Activity ")]) : _vm._e(), !_vm.readOnly ? _c('b-dropdown-item', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle.add-job",
              modifiers: {
                "add-job": true
              }
            }],
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click() {
                _vm.getSingleServiceAndRepair(state.id);
                _vm.is_job_data_equipment = true;
                _vm.checkedFleets = state.fleet_id;
                _vm.getComponentStruct();
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-plus mr-2"
          }), _vm._v("Add Job ")]) : _vm._e(), _c('b-dropdown-item', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle.detail_sr",
              modifiers: {
                "detail_sr": true
              }
            }],
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click() {
                return _vm.getSingleServiceAndRepair(state.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-info-circle mr-2"
          }), _vm._v("Detail ")]), _c('b-dropdown-divider'), !_vm.readOnly ? _c('b-dropdown-item', {
            attrs: {
              "href": "#",
              "variant": "danger"
            },
            on: {
              "click": function click() {
                return _vm.deleteServiceRepair(state);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash mr-2"
          }), _vm._v("Delete R & M ")]) : _vm._e()], 1)], 1)]);
        }), 0)]), ((_vm$list_table_progre2 = _vm.list_table_progress) === null || _vm$list_table_progre2 === void 0 ? void 0 : (_vm$list_table_progre3 = _vm$list_table_progre2.data) === null || _vm$list_table_progre3 === void 0 ? void 0 : _vm$list_table_progre3.length) > 0 ? _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.list_table_progress.meta.total_item,
            "per-page": _vm.list_table_progress.meta.per_page,
            "aria-controls": "my-table",
            "align": "center",
            "first-number": ""
          },
          on: {
            "change": _vm.getListProgressTableServiceAndRepair
          },
          model: {
            value: _vm.list_table_progress.meta.page,
            callback: function callback($$v) {
              _vm.$set(_vm.list_table_progress.meta, "page", $$v);
            },
            expression: "list_table_progress.meta.page"
          }
        })], 1) : _vm._e(), _c('b-sidebar', {
          attrs: {
            "id": "add-activity",
            "title": "UPDATE REPAIR & MAINTENANCE TIME LINE (PROGESS & DELAY FACTOR) - ".concat(_vm.fleetLabelName),
            "width": "100%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref8) {
              var _vm$activity_repair_d, _vm$activity_repair_d2, _vm$activity_repair_d3, _vm$activity_repair_d4, _vm$activity_repair_d5, _vm$activity_repair_d6, _vm$state, _vm$activity_repair_d7, _vm$activity_repair_d8, _vm$activity_repair_d9, _vm$activity_repair_d10, _vm$activity_repair_d11, _vm$activity_repair_d12, _vm$activity_repair_d13, _vm$activity_repair_d14, _vm$activity_repair_d15, _vm$activity_repair_d16, _vm$activity_repair_d17, _vm$activity_repair_d18, _vm$activity_repair_d19, _vm$activity_repair_d20, _vm$activity_repair_d21, _vm$activity_repair_d22, _vm$activity_repair_d23, _vm$activity_repair_d24, _vm$activity_repair_d25, _vm$activity_repair_d26, _vm$activity_repair_d27, _vm$activity_repair_d28, _vm$activity_repair_d29, _vm$activity_repair_d30, _vm$activity_repair_d31, _vm$activity_repair_d32, _vm$activity_repair_d33, _vm$activity_repair_d34, _vm$activity_repair_d35, _vm$activity_repair_d36;
              var hide = _ref8.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "7"
                }
              }, [_vm.activity_repair_data ? _c('table', {
                staticClass: "table table-striped mt-3"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Equipment Name")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$activity_repair_d = _vm.activity_repair_data) === null || _vm$activity_repair_d === void 0 ? void 0 : _vm$activity_repair_d.name))]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Repair Title")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$activity_repair_d2 = _vm.activity_repair_data) === null || _vm$activity_repair_d2 === void 0 ? void 0 : _vm$activity_repair_d2.title))])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Type R & M")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$activity_repair_d3 = _vm.activity_repair_data) === null || _vm$activity_repair_d3 === void 0 ? void 0 : (_vm$activity_repair_d4 = _vm$activity_repair_d3.task_type) === null || _vm$activity_repair_d4 === void 0 ? void 0 : _vm$activity_repair_d4.name))]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Department")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$activity_repair_d5 = _vm.activity_repair_data) === null || _vm$activity_repair_d5 === void 0 ? void 0 : (_vm$activity_repair_d6 = _vm$activity_repair_d5.department) === null || _vm$activity_repair_d6 === void 0 ? void 0 : _vm$activity_repair_d6.name))])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Section")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$state = _vm.state) === null || _vm$state === void 0 ? void 0 : _vm$state.section))])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Category")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$activity_repair_d7 = _vm.activity_repair_data) === null || _vm$activity_repair_d7 === void 0 ? void 0 : (_vm$activity_repair_d8 = _vm$activity_repair_d7.category) === null || _vm$activity_repair_d8 === void 0 ? void 0 : _vm$activity_repair_d8.name))]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Classification")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$activity_repair_d9 = _vm.activity_repair_data) === null || _vm$activity_repair_d9 === void 0 ? void 0 : (_vm$activity_repair_d10 = _vm$activity_repair_d9.classification) === null || _vm$activity_repair_d10 === void 0 ? void 0 : _vm$activity_repair_d10.name) + " (" + _vm._s((_vm$activity_repair_d11 = _vm.activity_repair_data) === null || _vm$activity_repair_d11 === void 0 ? void 0 : (_vm$activity_repair_d12 = _vm$activity_repair_d11.classification) === null || _vm$activity_repair_d12 === void 0 ? void 0 : _vm$activity_repair_d12.interval) + " " + _vm._s((_vm$activity_repair_d13 = _vm.activity_repair_data) === null || _vm$activity_repair_d13 === void 0 ? void 0 : (_vm$activity_repair_d14 = _vm$activity_repair_d13.classification) === null || _vm$activity_repair_d14 === void 0 ? void 0 : _vm$activity_repair_d14.unit) + ")")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Cause")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$activity_repair_d15 = _vm.activity_repair_data) === null || _vm$activity_repair_d15 === void 0 ? void 0 : (_vm$activity_repair_d16 = _vm$activity_repair_d15.case) === null || _vm$activity_repair_d16 === void 0 ? void 0 : _vm$activity_repair_d16.name))]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Kind Task")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$activity_repair_d17 = _vm.activity_repair_data) === null || _vm$activity_repair_d17 === void 0 ? void 0 : (_vm$activity_repair_d18 = _vm$activity_repair_d17.kind_task) === null || _vm$activity_repair_d18 === void 0 ? void 0 : _vm$activity_repair_d18.name))])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Mulai")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s(_vm.moment((_vm$activity_repair_d19 = _vm.activity_repair_data) === null || _vm$activity_repair_d19 === void 0 ? void 0 : _vm$activity_repair_d19.start_at).format('DD MMMM YYYY')))]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Selesai (Est)")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s(_vm.moment(new Date((_vm$activity_repair_d20 = _vm.activity_repair_data) === null || _vm$activity_repair_d20 === void 0 ? void 0 : _vm$activity_repair_d20.estimated_finished_at)).format('DD MMMM YYYY')))])]), _c('tr', [_c('td', {
                attrs: {
                  "width": "20%"
                }
              }, [_c('b', [_vm._v("Estimate Cost")])]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s(_vm.formatCurrency((_vm$activity_repair_d21 = _vm.activity_repair_data) === null || _vm$activity_repair_d21 === void 0 ? void 0 : _vm$activity_repair_d21.cost_estimate)))]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Estimasi Hari")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$activity_repair_d22 = _vm.activity_repair_data) === null || _vm$activity_repair_d22 === void 0 ? void 0 : _vm$activity_repair_d22.estimate_total_days) + " Hari")])]), _c('tr', [_c('td', {
                attrs: {
                  "width": "20%"
                }
              }, [_c('b', [_vm._v("Cost Real")])]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s(_vm.formatCurrency((_vm$activity_repair_d23 = _vm.activity_repair_data) === null || _vm$activity_repair_d23 === void 0 ? void 0 : _vm$activity_repair_d23.cost_real)))]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }), _c('td', {
                attrs: {
                  "width": "25%"
                }
              })]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Berita Acara")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "d-flex p-0 m-0"
              }, _vm._l((_vm$activity_repair_d24 = _vm.activity_repair_data) === null || _vm$activity_repair_d24 === void 0 ? void 0 : _vm$activity_repair_d24.file, function (file_data, fileIndex) {
                return _c('li', {
                  key: "file-" + fileIndex,
                  staticClass: "mr-2"
                }, [file_data.extension == 'pdf' ? _c('img', {
                  staticStyle: {
                    "width": "70px",
                    "height": "70px"
                  },
                  attrs: {
                    "src": require("../../assets/images/activity/pdf.png"),
                    "alt": "img-image"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(file_data.file), "berita_acara");
                    }
                  }
                }) : _c('img', {
                  staticStyle: {
                    "width": "100px",
                    "height": "70px"
                  },
                  attrs: {
                    "src": "".concat(_vm.apiUrl, "/").concat(file_data === null || file_data === void 0 ? void 0 : file_data.file),
                    "alt": "ing"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(file_data.file), "berita_acara");
                    }
                  }
                })]);
              }), 0)])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Description")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "align-top",
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" " + _vm._s((_vm$activity_repair_d25 = _vm.activity_repair_data) === null || _vm$activity_repair_d25 === void 0 ? void 0 : _vm$activity_repair_d25.description) + " ")])])])]) : _vm._e(), !_vm.activity_repair_data ? _c('div', {
                staticClass: "d-flex w-100 align-items-center justify-content-center"
              }, [_vm.activity_repair_loading ? _c('strong', {
                staticClass: "text-muted"
              }, [_vm._v("LOADING...")]) : _c('strong', {
                staticClass: "text-muted"
              }, [_vm._v("SELECT REPAIR & MAINTENANCE TERLEBIH DAHULU")])]) : _vm._e(), (_vm$activity_repair_d26 = _vm.activity_repair_data) !== null && _vm$activity_repair_d26 !== void 0 && _vm$activity_repair_d26.is_internal && _vm.activity_form.form_repair_id ? _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "text-left"
              }, [_vm._v("NAMA")]), _c('th', {
                staticClass: "text-left"
              }, [_vm._v("JABATAN")])]), _vm._l((_vm$activity_repair_d27 = _vm.activity_repair_data) === null || _vm$activity_repair_d27 === void 0 ? void 0 : _vm$activity_repair_d27.entity_internal, function (entity, entityIndex) {
                return _c('tr', {
                  key: entityIndex
                }, [_c('td', {
                  staticClass: "text-left"
                }, [_vm._v(_vm._s(entity === null || entity === void 0 ? void 0 : entity.internal_name))]), _c('td', {
                  staticClass: "text-left"
                }, [_vm._v(_vm._s(_vm.positionName(entity === null || entity === void 0 ? void 0 : entity.internal_position_id)))])]);
              })], 2)])]) : _vm._e(), (_vm$activity_repair_d28 = _vm.activity_repair_data) !== null && _vm$activity_repair_d28 !== void 0 && _vm$activity_repair_d28.is_subkon && _vm.activity_form.form_repair_id ? _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor")]), _c('table', {
                staticClass: "table table-striped table-lg"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3"
              }, [_vm._v("NAMA KONTRAKTOR")]), _c('th', [_vm._v("PIC/EXECUTIVE ENGINEER")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("OFFERING")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("NEGOTIATION")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("FINAL NEGOTIATION")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("FILE")])]), _vm._l((_vm$activity_repair_d29 = _vm.activity_repair_data) === null || _vm$activity_repair_d29 === void 0 ? void 0 : _vm$activity_repair_d29.entity_subkon, function (entity, entityIndex) {
                var _entity$subkon_file4;
                return _c('tr', {
                  key: entityIndex
                }, [_c('td', {
                  staticClass: "pl-3"
                }, [_vm._v(_vm._s(entity === null || entity === void 0 ? void 0 : entity.subkon_name))]), _c('td', [_vm._v(_vm._s(entity === null || entity === void 0 ? void 0 : entity.subkon_pic))]), _c('td', {
                  staticClass: "text-center"
                }, [_vm._v(_vm._s(_vm.formatCurrency(entity === null || entity === void 0 ? void 0 : entity.subkon_price_offering)))]), _c('td', {
                  staticClass: "text-center"
                }, [_vm._v(_vm._s(_vm.formatCurrency(entity === null || entity === void 0 ? void 0 : entity.subkon_price_nego)))]), _c('td', {
                  staticClass: "text-center"
                }, [_vm._v(_vm._s(_vm.formatCurrency(entity === null || entity === void 0 ? void 0 : entity.subkon_price_deal)))]), (entity === null || entity === void 0 ? void 0 : (_entity$subkon_file4 = entity.subkon_file) === null || _entity$subkon_file4 === void 0 ? void 0 : _entity$subkon_file4.length) > 0 ? _c('td', {
                  staticClass: "text-center text-primary"
                }, _vm._l(entity === null || entity === void 0 ? void 0 : entity.subkon_file, function (fileSubkon, subkonIndexFile) {
                  return _c('div', {
                    key: subkonIndexFile,
                    staticClass: "pb-2"
                  }, [fileSubkon.extension === 'pdf' ? _c('i', {
                    staticClass: "fa fa-file-pdf text-primary h4",
                    staticStyle: {
                      "cursor": "pointer"
                    },
                    on: {
                      "click": function click($event) {
                        return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(fileSubkon.url), "".concat(entity === null || entity === void 0 ? void 0 : entity.subkon_name));
                      }
                    }
                  }) : _c('img', {
                    staticStyle: {
                      "width": "70px",
                      "height": "70px"
                    },
                    attrs: {
                      "src": "".concat(_vm.apiUrl, "/").concat(fileSubkon === null || fileSubkon === void 0 ? void 0 : fileSubkon.url),
                      "alt": "img"
                    },
                    on: {
                      "click": function click($event) {
                        return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(fileSubkon.url), "".concat(entity === null || entity === void 0 ? void 0 : entity.subkon_name));
                      }
                    }
                  })]);
                }), 0) : _vm._e()]);
              })], 2)])]) : _vm._e()]), _c('b-col', {
                attrs: {
                  "md": "5"
                }
              }, [_c('tab-content-item', {
                attrs: {
                  "active": true,
                  "id": "power"
                }
              }, [_c('tab-nav', {
                attrs: {
                  "tabs": true
                }
              }, [_c('tab-nav-items', {
                attrs: {
                  "active": true,
                  "href": "#activity",
                  "title": "Activity"
                }
              }), _c('tab-nav-items', {
                attrs: {
                  "active": false,
                  "href": "#logs",
                  "title": "Logs"
                }
              })], 1), _c('div', {
                staticClass: "tab-content",
                attrs: {
                  "id": "myTabContent"
                }
              }, [_c('tab-content-item', {
                attrs: {
                  "active": true,
                  "id": "activity"
                }
              }, [_c('form', {
                attrs: {
                  "action": "#",
                  "method": "post"
                },
                on: {
                  "submit": _vm.onSaveActivity
                }
              }, [_c('b-row', [_c('b-col', {
                staticClass: "mb-5",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Select Repair & Maintenance *",
                  "label-for": "repair_maintenance"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.list_repair_service,
                  "required": ""
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Repair & Maintenance")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.activity_form.form_repair_id,
                  callback: function callback($$v) {
                    _vm.$set(_vm.activity_form, "form_repair_id", $$v);
                  },
                  expression: "activity_form.form_repair_id"
                }
              })], 1)], 1), _c('b-col', [_vm.activity_form.form_repair_id ? _c('b-form-group', {
                attrs: {
                  "label": "Select Activity *",
                  "label-for": "activity"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "name": "activity_id",
                  "options": _vm.optionsActivity,
                  "required": ""
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Activity")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.activity_form.activity_id,
                  callback: function callback($$v) {
                    _vm.$set(_vm.activity_form, "activity_id", $$v);
                  },
                  expression: "activity_form.activity_id"
                }
              })], 1) : _vm._e()], 1)], 1), _c('b-form-row', [_c('b-col', [_vm.activity_form.form_repair_id ? _c('b-form-group', {
                attrs: {
                  "label": "Tanggal *",
                  "label-for": "date"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "name": "date_of_activity",
                  "type": "date",
                  "id": "start",
                  "min": _vm.minDate,
                  "required": ""
                },
                model: {
                  value: _vm.activity_form.date_of_activity,
                  callback: function callback($$v) {
                    _vm.$set(_vm.activity_form, "date_of_activity", $$v);
                  },
                  expression: "activity_form.date_of_activity"
                }
              })], 1) : _vm._e()], 1), _c('b-col', [_vm.activity_form.form_repair_id ? _c('b-form-group', {
                attrs: {
                  "label": "Berita Acara",
                  "label-for": "attactment_file"
                }
              }, [_c('b-form-file', {
                attrs: {
                  "plain": "",
                  "multiple": "",
                  "name": "file",
                  "id": "attactment_file"
                },
                model: {
                  value: _vm.activity_form.file,
                  callback: function callback($$v) {
                    _vm.$set(_vm.activity_form, "file", $$v);
                  },
                  expression: "activity_form.file"
                }
              })], 1) : _vm._e()], 1)], 1), _c('b-form-row', [_c('b-col', [_vm.activity_form.form_repair_id ? _c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "name": "notes",
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Type Text ..."
                },
                model: {
                  value: _vm.activity_form.notes,
                  callback: function callback($$v) {
                    _vm.$set(_vm.activity_form, "notes", $$v);
                  },
                  expression: "activity_form.notes"
                }
              })], 1) : _vm._e()], 1)], 1), _c('b-form-row', [_c('b-col', [_vm.activity_form.form_repair_id ? _c('b-form-group', {
                attrs: {
                  "label": "Is Finish Job ?",
                  "label-for": "internal_man_power"
                }
              }, [_c('b-form-checkbox', {
                attrs: {
                  "name": "check-button",
                  "switch": ""
                },
                model: {
                  value: _vm.activity_form.is_finished,
                  callback: function callback($$v) {
                    _vm.$set(_vm.activity_form, "is_finished", $$v);
                  },
                  expression: "activity_form.is_finished"
                }
              })], 1) : _vm._e()], 1), _vm.activity_form.form_repair_id ? _c('b-col', [((_vm$activity_repair_d30 = _vm.activity_repair_data) === null || _vm$activity_repair_d30 === void 0 ? void 0 : (_vm$activity_repair_d31 = _vm$activity_repair_d30.status) === null || _vm$activity_repair_d31 === void 0 ? void 0 : _vm$activity_repair_d31.alias) !== 'finish' && ((_vm$activity_repair_d32 = _vm.activity_repair_data) === null || _vm$activity_repair_d32 === void 0 ? void 0 : (_vm$activity_repair_d33 = _vm$activity_repair_d32.status) === null || _vm$activity_repair_d33 === void 0 ? void 0 : _vm$activity_repair_d33.alias) !== 'reject' ? _c('b-button', {
                staticClass: "pull-right",
                attrs: {
                  "type": "submit",
                  "variant": "success",
                  "disabled": !_vm.activity_form.form_repair_id || _vm.activity_repair_loading
                }
              }, [_vm.activity_repair_loading ? _c('span', {
                staticClass: "spinner-border spinner-border-sm"
              }) : _c('span', [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v("Submit Data")])]) : _vm._e()], 1) : _vm._e()], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('ul', {
                staticClass: "iq-timeline",
                staticStyle: {
                  "height": "100%"
                }
              }, [_vm._l((_vm$activity_repair_d34 = _vm.activity_repair_data) === null || _vm$activity_repair_d34 === void 0 ? void 0 : _vm$activity_repair_d34.activity, function (activity, aIndex) {
                var _activity$activity7, _activity$activity8, _activity$activity$na3, _activity$activity9, _activity$parsed_file3;
                return _c('li', {
                  key: aIndex
                }, [_c('div', {
                  staticClass: "timeline-dots border-success"
                }), _c('h6', {
                  staticClass: "float-left mb-1"
                }, [_vm._v(_vm._s(activity === null || activity === void 0 ? void 0 : activity.user_name))]), _c('br'), _c('small', [_vm._v(_vm._s(_vm.moment(activity === null || activity === void 0 ? void 0 : activity.date_of_activity).format('DD MMMM YYYY hh:mm A')))]), _c('div', {
                  staticClass: "d-inline-block w-100"
                }, [_c('b-badge', {
                  attrs: {
                    "variant": (activity === null || activity === void 0 ? void 0 : (_activity$activity7 = activity.activity) === null || _activity$activity7 === void 0 ? void 0 : _activity$activity7.activity_flag) === 'running' ? 'primary' : (activity === null || activity === void 0 ? void 0 : (_activity$activity8 = activity.activity) === null || _activity$activity8 === void 0 ? void 0 : _activity$activity8.activity_flag) === 'finish' ? 'success' : 'danger'
                  }
                }, [_vm._v(_vm._s((_activity$activity$na3 = activity === null || activity === void 0 ? void 0 : (_activity$activity9 = activity.activity) === null || _activity$activity9 === void 0 ? void 0 : _activity$activity9.name) !== null && _activity$activity$na3 !== void 0 ? _activity$activity$na3 : '-'))]), _vm._v(" "), _c('br'), _vm._v(" "), _c('p', [_c('strong', {
                  staticClass: "text-muted"
                }, [_vm._v("Notes:")]), _vm._v(" " + _vm._s(activity === null || activity === void 0 ? void 0 : activity.notes))])], 1), (activity === null || activity === void 0 ? void 0 : (_activity$parsed_file3 = activity.parsed_file) === null || _activity$parsed_file3 === void 0 ? void 0 : _activity$parsed_file3.length) > 0 ? _c('div', {
                  staticClass: "d-inline-block w-100"
                }, [_c('ul', {
                  staticClass: "d-flex p-0 m-0"
                }, _vm._l(activity === null || activity === void 0 ? void 0 : activity.parsed_file, function (file, fileIndex) {
                  return _c('li', {
                    key: fileIndex,
                    staticClass: "pb-2"
                  }, [file.extension == 'pdf' ? _c('img', {
                    staticStyle: {
                      "width": "70px",
                      "height": "70px"
                    },
                    attrs: {
                      "src": require("../../assets/images/activity/pdf.png"),
                      "alt": "img-image"
                    },
                    on: {
                      "click": function click($event) {
                        return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(file.url), "".concat(activity === null || activity === void 0 ? void 0 : activity.user_name, "_activity"));
                      }
                    }
                  }) : _c('img', {
                    staticStyle: {
                      "width": "70px",
                      "height": "70px"
                    },
                    attrs: {
                      "src": "".concat(_vm.apiUrl, "/").concat(file.url),
                      "alt": "image"
                    },
                    on: {
                      "click": function click($event) {
                        return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(file.url), "".concat(activity === null || activity === void 0 ? void 0 : activity.user_name, "_activity"));
                      }
                    }
                  })]);
                }), 0)]) : _vm._e()]);
              }), ((_vm$activity_repair_d35 = _vm.activity_repair_data) === null || _vm$activity_repair_d35 === void 0 ? void 0 : (_vm$activity_repair_d36 = _vm$activity_repair_d35.activity) === null || _vm$activity_repair_d36 === void 0 ? void 0 : _vm$activity_repair_d36.length) > 0 && _vm.activity_form.form_repair_id ? _c('b-button', {
                staticClass: "ml-2",
                attrs: {
                  "type": "button",
                  "variant": "primary"
                },
                on: {
                  "click": function click($event) {
                    return _vm.getSingleRepairData(10);
                  }
                }
              }, [_vm._v(" Load More ")]) : _vm._e()], 2)])], 1)], 1)]), _c('tab-content-item', {
                attrs: {
                  "active": false,
                  "id": "logs"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('ul', {
                staticClass: "iq-timeline",
                staticStyle: {
                  "height": "100%"
                }
              }, _vm._l(_vm.logs, function (log, index) {
                return _c('li', {
                  key: index
                }, [_c('div', {
                  staticClass: "timeline-dots border-success"
                }), _c('h6', {
                  staticClass: "float-left mb-1"
                }, [_vm._v(_vm._s(log === null || log === void 0 ? void 0 : log.name))]), _c('br'), _c('small', [_vm._v(_vm._s(_vm.moment(log === null || log === void 0 ? void 0 : log.date_log).format('DD MMMM YYYY h:mm')))]), _c('div', {
                  staticClass: "d-inline-block w-100"
                }, [_c('p', [_vm._v(_vm._s(log === null || log === void 0 ? void 0 : log.log_description))])])]);
              }), 0)])], 1)], 1)], 1)], 1)], 1)], 1)];
            }
          }], null, false, 3106386056)
        })];
      },
      proxy: true
    }], null, false, 1294899021)
  }) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-modal', {
    attrs: {
      "id": "reject-modal",
      "size": "l",
      "title": "REJECT REPAIR & MAINTENANCE",
      "cancel-title": "Close",
      "hide-footer": ""
    },
    model: {
      value: _vm.showRejectModal,
      callback: function callback($$v) {
        _vm.showRejectModal = $$v;
      },
      expression: "showRejectModal"
    }
  }, [_c('form', {
    attrs: {
      "action": "#",
      "method": "post"
    },
    on: {
      "submit": _vm.onRejectService
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Select Reason Reject",
      "label-for": "reject_reason"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "required": "",
      "name": "activity_id",
      "options": _vm.optionsReject
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select Reason")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form_reject.reject_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form_reject, "reject_id", $$v);
      },
      expression: "form_reject.reject_id"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Reason",
      "label-for": "reason"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "reason",
      "rows": "2",
      "placeholder": "Type Text ..."
    },
    model: {
      value: _vm.form_reject.remarks,
      callback: function callback($$v) {
        _vm.$set(_vm.form_reject, "remarks", $$v);
      },
      expression: "form_reject.remarks"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-button', {
    attrs: {
      "type": "button",
      "variant": "secondary",
      "block": ""
    },
    on: {
      "click": _vm.resetReject
    }
  }, [_vm._v(" Cancel ")])], 1), _c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('b-button', {
    attrs: {
      "type": "submit",
      "variant": "primary",
      "block": "",
      "disabled": _vm.reject_loading
    }
  }, [_vm.reject_loading ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _c('span', [_c('i', {
    staticClass: "fa fa-save"
  }), _vm._v(" Submit")])])], 1)], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }