var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "shadow border mt-4 p-4 rounded-lg"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('h5', {
    staticClass: "text-primary"
  }, [_c('strong', [_vm._v("Download and Complete The Template")])]), _c('span', [_vm._v("Download template below and write your data")])]), _c('b-col', {
    staticClass: "my-4",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex p-2 px-4 rounded-lg bg-primary align-items-center justify-content-between",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": _vm.doExport
    }
  }, [_c('span', [_vm._v("template_bulk_data.xlsx")]), _c('i', {
    staticClass: "fa fa-download"
  })]), _vm.loading_export ? _c('p', {
    staticClass: "mt-2 text-primary"
  }, [_vm._v("Please wait, downloading file is in process...")]) : _vm._e()]), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "12"
    }
  }, [_c('strong', {
    staticClass: "text-black"
  }, [_vm._v("Few Tips")]), _c('ul', {
    staticClass: "tips-list"
  }, [_c('li', [_vm._v("Make sure your data is correct")]), _c('li', [_vm._v("Make sure your data format follow the rule of template")]), _c('li', [_vm._v("Check sheet instruction on spreadsheet to read instruction")])])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Upload Document *",
      "label-for": "fleet_name"
    }
  }, [_c('b-form-file', {
    attrs: {
      "type": "file",
      "accept": "application/*"
    },
    on: {
      "change": function change($event) {
        return _vm.uploadFile($event, true);
      }
    },
    model: {
      value: _vm.file,
      callback: function callback($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "iq-bg-danger mr-3",
    attrs: {
      "disabled": _vm.saving,
      "variant": "none"
    },
    on: {
      "click": function click($event) {
        return _vm.closeSidebar('import-operational');
      }
    }
  }, [_vm._v("Cancel ")]), _c('b-button', {
    attrs: {
      "type": "button",
      "disabled": _vm.saving,
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_vm._v("Submit")])], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_vm.loading_upload_file ? _c('p', {
    staticClass: "mt-2 text-primary"
  }, [_vm._v("Please wait, uploading file is in process...")]) : _vm._e()])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }